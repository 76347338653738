export function calculateAverage(numbers: number[], decimalNumber?: number) {
  // Check if the array is not empty
  if (numbers.length === 0) {
    return 0; // or handle it in a way that makes sense for your application
  }
  const filteredNumbers = numbers.filter((n) => n != undefined);

  // Calculate the sum of the numbers
  const sum = filteredNumbers.reduce(
    (accumulator, currentValue) => accumulator + currentValue,
    0
  );

  // Calculate the average
  const average = sum / filteredNumbers.length;

  return Number(average.toFixed(decimalNumber || 4));
}

export function calculateStandardDeviation(
  originalValues: number[],
  decimalNumber?: number
) {
  const values = originalValues.filter((v) => v != undefined);
  // Step 1: Calculate mean
  const mean = values.reduce((sum, value) => sum + value, 0) / values.length;

  // Step 2: Calculate squared differences
  const squaredDifferences = values.map((value) => Math.pow(value - mean, 2));

  // Step 3: Calculate mean of squared differences
  const meanOfSquaredDifferences =
    squaredDifferences.reduce((sum, value) => sum + value, 0) / values.length;

  // Step 4: Take the square root to get the standard deviation
  const standardDeviation = Math.sqrt(meanOfSquaredDifferences);
  return Number(standardDeviation.toFixed(decimalNumber || 4));
}
