/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as React from "react";
import GeneralPopoverWrapper from "../Common/GeneralPopoverWrapper";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import IconButton from "@mui/material/IconButton";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import Box from "@mui/material/Box";
import SimpleSearchInputWithDebounce from "../Common/SimpleSearchInputWithDebounce";
import { ISampleRecordGroup } from "../../../types/sample";
import SampleGroupListItem from "./SampleGroupListItem";
import sampleGroupService from "../../../service/sampleGroup.service";

export default function SampleGroupPopover({
  onSelectGroup,
  triggerElement,
  selectTooltipTitle,
  selectIcon,
}: {
  onSelectGroup?: (group: ISampleRecordGroup) => void;
  triggerElement?: JSX.Element;
  selectTooltipTitle?: string;
  selectIcon?: JSX.Element;
}) {
  const [sampleGroups, setSampleGroups] = React.useState<ISampleRecordGroup[]>(
    []
  );
  const [page, setPage] = React.useState(0);
  const [pageSize, setPageSize] = React.useState(25);
  const [searchTerm, setSearchTerm] = React.useState("");
  const [totalCount, setTotalCount] = React.useState(0);
  const fetchExportLogs = async () => {
    const { data, count } = await sampleGroupService.fetchSampleGroups({
      page,
      pageSize,
      searchTerm,
    });
    setSampleGroups(data);
    setTotalCount(count);
  };

  React.useEffect(() => {
    fetchExportLogs();
  }, [page, pageSize, searchTerm]);

  return (
    <GeneralPopoverWrapper
      anchorHorizontal="right"
      transformHorizontal="right"
      triggerElement={
        triggerElement || (
          <IconButton size="small">
            <AccessTimeIcon />
          </IconButton>
        )
      }
      popoverContent={
        <Box sx={{ width: "380px" }}>
          <Box
            px={2}
            py={1}
            sx={{
              borderBottom: "solid 1px #ddd",
              fontWeight: "500",
            }}
          >
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <div style={{ flex: 1 }}>
                <SimpleSearchInputWithDebounce
                  placeholder="Search sample groups..."
                  searchTerm={searchTerm}
                  onChangeSearchTerm={(changed) => setSearchTerm(changed)}
                  sx={{
                    "& input": { padding: "5px 10px 5px 0px" },
                    mr: 1,
                    my: "4px",
                  }}
                />
              </div>
              <Box>
                <IconButton
                  onClick={() => setPage(page - 1)}
                  size="small"
                  disabled={page == 0}
                >
                  <ArrowBackIcon />
                </IconButton>
                <IconButton
                  onClick={() => setPage(page + 1)}
                  size="small"
                  disabled={(page + 1) * pageSize > totalCount}
                >
                  <ArrowForwardIcon />
                </IconButton>
              </Box>
            </Box>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginTop: "5px",
              }}
            >
              {totalCount
                ? new Array(Math.ceil(totalCount / pageSize))
                    .fill(undefined)
                    .map((el, i) => (
                      <Box
                        key={i}
                        style={{
                          cursor: "pointer",
                          flex: 1,
                          height: "4px",
                          borderRadius: "100px",
                          margin: "0px 2px",
                          background: i == page ? "#9cc6f2" : "#ededed",
                        }}
                        onClick={() => setPage(i)}
                      />
                    ))
                : null}
            </div>
          </Box>
          <Box sx={{ maxHeight: "400px", overflow: "auto" }}>
            {sampleGroups.map((group) => (
              <Box key={group._id}>
                <SampleGroupListItem
                  sampleGroup={group}
                  // type={
                  //   currentTab == 0
                  //     ? SampleGroupType.DownloadLog
                  //     : SampleGroupType.UploadLog
                  // }
                  onSelectItem={onSelectGroup}
                  selectIcon={selectIcon}
                  selectTooltipTitle={selectTooltipTitle}
                />
              </Box>
            ))}
          </Box>
        </Box>
      }
      tooltipTitle="See Sample Groups"
    />
  );
}
