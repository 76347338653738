/* eslint-disable @typescript-eslint/no-explicit-any */
const createImage = (url: any) =>
  new Promise((resolve, reject) => {
    const image = new Image();
    image.addEventListener("load", () => resolve(image));
    image.addEventListener("error", (error) => reject(error));
    image.setAttribute("crossOrigin", "anonymous");
    image.src = url;
  });

function getRadianAngle(degreeValue: any) {
  return (degreeValue * Math.PI) / 180;
}

function rotateSize(width: any, height: any, rotation: any) {
  const rotRad = getRadianAngle(rotation);

  return {
    width: Math.abs(Math.cos(rotRad) * width) + Math.abs(Math.sin(rotRad) * height),
    height: Math.abs(Math.sin(rotRad) * width) + Math.abs(Math.cos(rotRad) * height),
  };
}

export const cropImage = async (
  imageSrc: any,
  pixelCrop: any,
  realScale = 1,
  limitedScale = { originalWidth: 0, originalHeight: 0, maxWidth: 200, maxHeight: 200 },
  rotation = 0,
  flip = { horizontal: false, vertical: false }
) => {
  let x = pixelCrop.x * realScale;
  let y = pixelCrop.y * realScale;
  let width = pixelCrop.width * realScale;
  let height = pixelCrop.height * realScale;
  let reducedScale = 1;

  if (width > limitedScale.maxWidth || height > limitedScale.maxHeight) {
    reducedScale = Math.min(limitedScale.maxWidth / width, limitedScale.maxHeight / height);
    x = x * reducedScale;
    y = y * reducedScale;
    width = width * reducedScale;
    height = height * reducedScale;
  }

  const image: any = await createImage(imageSrc);
  const canvas = document.createElement("canvas");
  const ctx = canvas.getContext("2d");

  if (!ctx) {
    return null;
  }

  const rotRad = getRadianAngle(rotation);
  const { width: bBoxWidth, height: bBoxHeight } = rotateSize(image.width, image.height, rotation);
  canvas.width = bBoxWidth;
  canvas.height = bBoxHeight;

  ctx.translate(bBoxWidth / 2, bBoxHeight / 2);
  ctx.rotate(rotRad);
  ctx.scale(flip.horizontal ? -1 : 1, flip.vertical ? -1 : 1);
  ctx.translate(-image.width / 2, -image.height / 2);
  if (reducedScale < 1) {
    ctx.drawImage(image, 0, 0, image.width * reducedScale, image.height * reducedScale);
  } else {
    ctx.drawImage(image, 0, 0);
  }

  const data = ctx.getImageData(x, y, width, height);

  canvas.width = width;
  canvas.height = height;

  ctx.putImageData(data, 0, 0);

  return new Promise((resolve) => {
    canvas.toBlob((file) => {
      resolve(file);
    }, "image/png");
  });
};
