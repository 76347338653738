import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import WarningIcon from "@mui/icons-material/Warning";
import CloseIcon from "@mui/icons-material/Close";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import { ISampleRecordCreationPayload } from "../../../types/sample";
import { DataGridPro } from "@mui/x-data-grid-pro";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";

interface TypeMismatchWarningDialogProps {
  onClose: () => void;
  records: ISampleRecordCreationPayload[];
  onExclude: () => void;
  onIgnore: () => void;
  isAll?: boolean;
}

const TypeMismatchWarningDialog: React.FC<TypeMismatchWarningDialogProps> = ({
  onClose,
  records,
  onExclude,
  onIgnore,
  isAll,
}) => {
  return (
    <Dialog open={true} onClose={onClose} scroll="paper" maxWidth="sm">
      <DialogTitle
        id="type-mismatch-warning-dialog-title"
        sx={{
          display: "flex",
          alignItems: "center",
        }}
      >
        <WarningIcon color="warning" sx={{ mr: 1 }} />
        Type Mismatch Warning
        <div style={{ flexGrow: 1 }} />
        <IconButton onClick={onClose} size="small">
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Typography color="warning.main" mb={2}>
          {`It is suspected that ${
            isAll ? "all" : "these"
          } samples belong to different crop types.`}
        </Typography>
        <div
          style={{
            height: 400,
            width: "100%",
            border: "solid 1px #ddd",
            borderRadius: "5px",
            boxSizing: "border-box",
          }}
        >
          <DataGridPro
            columnHeaderHeight={36}
            rowHeight={36}
            getRowId={(row) => row.sampleNumber || ""}
            rows={records}
            columns={[
              {
                field: "sampleNumber",
                headerName: "Sample Number",
                width: 150,
              },
              {
                field: "type",
                headerName: "Uploaded As",
                width: 150,
                sortable: false,
              },
              {
                field: "predictedType",
                headerName: "Predicted Type",
                width: 150,
                sortable: false,
              },
            ]}
            hideFooter
            disableRowSelectionOnClick
          />
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={onExclude} color="primary" variant="outlined">
          {isAll ? "Cancel Uploading" : "Exclude these samples"}
        </Button>
        <Button onClick={onIgnore} color="error" variant="outlined">
          Ignore this warning and continue
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default TypeMismatchWarningDialog;
