/* eslint-disable @typescript-eslint/no-explicit-any */
import backendApi from "./backendApi";
import { showSnackbar } from "./snackbar.service";
import { CustomGridViewState } from "../types/gridViewState";

const routePrefix = "datagrid";

export default {
  async get(tableId: string): Promise<CustomGridViewState | null> {
    try {
      const result = await backendApi()?.get(`${routePrefix}/${tableId}`);
      if (result?.status != 200) {
        showSnackbar(
          "danger",
          "Error",
          result?.data.message || "API error response"
        );
        return null;
      }
      return result.data;
    } catch (error: any) {
      if (error?.response?.status) {
        showSnackbar(
          "danger",
          "Error",
          error?.response?.data?.message || "API error response"
        );
      }
      return null;
    }
  },
  async save(tableId: string, gridState: CustomGridViewState): Promise<any> {
    if (!tableId || !gridState) return;
    try {
      const result = await backendApi()?.post(`${routePrefix}/save`, {
        tableId,
        gridState,
      });
      if (result?.status != 200) {
        showSnackbar(
          "danger",
          "Error",
          result?.data.message || "API error response"
        );
        return null;
      }
      showSnackbar(
        "success",
        "Success",
        result?.data.message || "Grid View is successfully saved"
      );
      return result;
    } catch (error: any) {
      if (error?.response?.status) {
        showSnackbar(
          "danger",
          "Error",
          error?.response?.data?.message || "API error response"
        );
        return null;
      }
    }
  },
};
