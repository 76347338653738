/* eslint-disable @typescript-eslint/no-explicit-any */
import { IGasAnalysis } from "../types/gasAnalysis";
import backendApi from "./backendApi";
import { showSnackbar } from "./snackbar.service";

const routePrefix = "datalake/gas-analysis";

export default {
  async get({
    page,
    pageSize,
    searchTerm,
  }: {
    page: number;
    pageSize: number;
    searchTerm?: string;
  }): Promise<IGasAnalysis[]> {
    try {
      const result = await backendApi()?.post(`${routePrefix}/get`, {
        page,
        pageSize,
        searchTerm,
      });
      if (result?.status != 200) {
        showSnackbar(
          "danger",
          "Error",
          result?.data.message || "API error response"
        );
        return [];
      }
      return result.data;
    } catch (error: any) {
      if (error?.response?.status) {
        showSnackbar(
          "danger",
          "Error",
          error?.response?.data?.message || "API error response"
        );
      }
      return [];
    }
  },
  async update({
    id,
    analysis,
  }: {
    id: string;
    analysis: IGasAnalysis;
  }): Promise<any> {
    try {
      const result = await backendApi()?.post(`${routePrefix}/update`, {
        id,
        analysis,
      });
      if (result?.status != 200) {
        showSnackbar(
          "danger",
          "Error",
          result?.data.message || "API error response"
        );
        return null;
      }
      return result;
    } catch (error: any) {
      if (error?.response?.status) {
        showSnackbar(
          "danger",
          "Error",
          error?.response?.data?.message || "API error response"
        );
      }
      return null;
    }
  },
  async addNewGasAnalysis(analysis: IGasAnalysis): Promise<any> {
    try {
      const result = await backendApi()?.post(`${routePrefix}/create`, {
        analysis,
      });
      if (result?.status != 200) {
        showSnackbar(
          "danger",
          "Error",
          result?.data.message || "API error response"
        );
        return null;
      }
      showSnackbar(
        "success",
        "Success",
        result?.data.message || "New Analysis successfully created"
      );
      return result;
    } catch (error: any) {
      if (error?.response?.status) {
        showSnackbar(
          "danger",
          "Error",
          error?.response?.data?.message || "API error response"
        );
        return null;
      }
    }
  },
};
