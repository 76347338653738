/* eslint-disable @typescript-eslint/no-explicit-any */
// import Box from "@mui/material/Box";
import * as React from "react";
import { DataGridPro } from "@mui/x-data-grid-pro";
import WhitePanel from "../../components/Common/WhitePanel";
import Box from "@mui/material/Box";
import SimpleSearchInput from "../../components/Common/SimpleSearchInput";
import { ISampleSource } from "../../../types/sampleSource";
import sampleSourceService from "../../../service/sampleSource.service";

export default function Index() {
  const [selectedIds, setSelectedIds] = React.useState<string[]>([]);
  const [companies, setSampleSources] = React.useState<ISampleSource[]>([]);
  const [companiesProcessing, setSampleSourcesProcessing] =
    React.useState(false);
  const [searchTerm, setSearchTerm] = React.useState("");

  const columns = [
    {
      field: "name",
      headerName: "Lab Name",
      width: 150,
      editable: true,
    },
    {
      field: "address",
      headerName: "Address",
      width: 250,
      editable: true,
    },
    {
      field: "email",
      headerName: "Email",
      sortable: false,
      width: 160,
    },
    {
      field: "phone",
      headerName: "Phone",
      sortable: false,
      width: 160,
    },
  ];

  const getFilteredSampleSources = () => {
    return companies.filter(
      (c) =>
        !searchTerm ||
        c.name?.toLowerCase()?.includes(searchTerm.toLowerCase()) ||
        c.email?.toLowerCase()?.includes(searchTerm.toLowerCase())
    );
  };

  const fetchSampleSources = async () => {
    setSampleSourcesProcessing(true);
    const result = await sampleSourceService.fetchAllSampleSources();
    if (result) {
      setSampleSources(result);
    }
    setSampleSourcesProcessing(false);
  };

  React.useEffect(() => {
    fetchSampleSources();
  }, []);
  return (
    <>
      <Box mb={1}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            fontSize: "24px",
            fontWeight: "500",
            opacity: 0.8,
          }}
        >
          Sample Sources
          <Box
            sx={{ ml: 1, fontSize: "24px", fontWeight: "500", opacity: "0.6" }}
          >{`(${companies.length})`}</Box>
        </Box>
      </Box>
      <WhitePanel>
        <Box
          pb={2}
          sx={{ display: "flex", alignItems: "center", flexWrap: "wrap" }}
        >
          <Box>
            <SimpleSearchInput
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              size="small"
              placeholder="Search SampleSource"
            />
          </Box>

          {/* {selectedIds?.length ? (
            <Button
              startIcon={<DeleteIcon />}
              disableElevation
              variant="contained"
              sx={{ ml: 2 }}
              color="error"
            >
              Delete
            </Button>
          ) : null} */}
          <Box sx={{ flex: 1 }} />
        </Box>
        <DataGridPro
          getRowId={(row) => row._id as string}
          loading={companiesProcessing}
          autoHeight
          rowSelectionModel={selectedIds}
          onRowSelectionModelChange={(changedIds: any) => {
            setSelectedIds(changedIds);
          }}
          rows={getFilteredSampleSources()}
          columns={columns}
          // checkboxSelection
          disableRowSelectionOnClick
        />
      </WhitePanel>
    </>
  );
}
