/* eslint-disable @typescript-eslint/no-unused-vars */
import Box from "@mui/material/Box";
import React from "react";
import Dialog from "@mui/material/Dialog";
import CloseIcon from "@mui/icons-material/Close";
import AddIcon from "@mui/icons-material/Add";
import Typography from "@mui/material/Typography";
import TableViewIcon from "@mui/icons-material/TableView";
import { styled } from "@mui/material/styles";
import IconButton from "@mui/material/IconButton";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { IGasAnalysis } from "../../../types/gasAnalysis";
import { useAppContext } from "../../../contexts/useStorageContext";
import gasAnalysisService from "../../../service/gasAnalysisService";
import SampleIdMultipleAutocomplete from "../Common/SampleIdMultipleAutocomplete";
import Stack from "@mui/material/Stack";
import AttachedFileItem from "../Common/AttachedFileItem";
import AnyAssetFileUploader from "../Common/AnyAssetFileUploader";
import dayjs from "dayjs";
import ProgressButton from "../Common/ProgressButton";
import { Button, CircularProgress } from "@mui/material";
import { ISampleRecordPopulated } from "../../../types/sample";
import PrintableSamplesMetadataTable from "./PrintableSamplesMetadataTable";
import sampleRecordService from "../../../service/sampleRecord.service";

const FieldLabel = styled(Box)(() => ({
  fontSize: "13px",
  marginBottom: "5px",
  marginLeft: "3px",
}));

const AddFileBox = styled(Box)(() => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  background: "#eee",
  border: "dashed 2px #bbb",
  borderRadius: "5px",
  height: "50px",
  cursor: "pointer",
  "&:hover": {
    background: "#ddd",
  },
}));

export default function GasAnalysisFormDialog({
  open,
  onClose,
  gasAnalysis,
  onCreated,
  onUpdated,
}: {
  open: boolean;
  gasAnalysis?: IGasAnalysis;
  onClose: () => void;
  onCreated?: (created: IGasAnalysis) => void;
  onUpdated?: (updated: IGasAnalysis) => void;
}): React.ReactElement {
  const { state } = useAppContext();
  const { myCompanyProfile } = state;
  const [editingGasAnalysis, setEditingGasAnalysis] =
    React.useState<IGasAnalysis>(gasAnalysis || {});
  const [processing, setProcessing] = React.useState(false);
  const [openSourceFileUploadDialog, setOpenSourceFileUploadDialog] =
    React.useState(false);
  const [openResultFilesUploadDialog, setOpenResultFilesUploadDialog] =
    React.useState(false);
  const [openSamplesTable, setOpenSamplesTable] = React.useState(false);
  const [populatingSamples, setPopulatingSamples] = React.useState(false);

  const handleCreate = async () => {
    if (!editingGasAnalysis.sourceFile || !editingGasAnalysis.samples?.length)
      return;
    setProcessing(true);
    const result = await gasAnalysisService.addNewGasAnalysis(
      editingGasAnalysis
    );
    if (result?.data) {
      onCreated && onCreated(result.data);
    }
    setProcessing(false);
    onClose();
  };

  const handleUpdate = async () => {
    if (
      !editingGasAnalysis._id ||
      !editingGasAnalysis.sourceFile ||
      !editingGasAnalysis.samples?.length
    )
      return;
    setProcessing(true);
    const result = await gasAnalysisService.update({
      id: editingGasAnalysis._id,
      analysis: editingGasAnalysis,
    });
    if (result?.data) {
      onUpdated && onUpdated(editingGasAnalysis);
    }
    setProcessing(false);
    onClose();
  };

  const populateSampleRecords = async (sampleRecordIds: string[]) => {
    if (!myCompanyProfile?._id || !sampleRecordIds.length) return;
    try {
      setPopulatingSamples(true);
      const result = await sampleRecordService.getSampleRecordsByIds(
        myCompanyProfile?._id,
        sampleRecordIds
      );
      setEditingGasAnalysis({ ...editingGasAnalysis, samples: result });
      setPopulatingSamples(false);
    } catch (error) {
      setPopulatingSamples(false);
    }
  };

  React.useEffect(() => {
    if (gasAnalysis && !gasAnalysis.samples?.[0]?.sampleNumber) {
      populateSampleRecords((gasAnalysis.samples as unknown as string[]) || []);
    }
  }, [gasAnalysis]);

  return (
    <>
      <Dialog open={open} maxWidth="xs" onClose={onClose}>
        <Box sx={{ background: "white", width: "1000px", maxWidth: "100%" }}>
          <Box p={2} pb={0} sx={{ display: "flex", alignItems: "center" }}>
            <Typography variant="h6">
              {gasAnalysis ? "Gas Analysis Information" : "New GasAnalysis"}
            </Typography>
            {editingGasAnalysis?.samples?.length ? (
              <IconButton
                onClick={() => setOpenSamplesTable(true)}
                sx={{ ml: 2 }}
              >
                <TableViewIcon />
              </IconButton>
            ) : null}

            <div style={{ flex: 1 }} />
            <IconButton onClick={onClose}>
              <CloseIcon />
            </IconButton>
          </Box>
          <Box p={2}>
            <Stack spacing={2}>
              {populatingSamples ? (
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <CircularProgress size={18} sx={{ mr: 2 }} />
                  Populating Samples...
                </Box>
              ) : (
                <SampleIdMultipleAutocomplete
                  label="Sample IDs *"
                  onSelectValues={(selected) => {
                    setEditingGasAnalysis({
                      ...editingGasAnalysis,
                      samples: selected,
                    });
                  }}
                  value={editingGasAnalysis.samples || []}
                  companyId={myCompanyProfile?._id || ""}
                />
              )}
              <Box>
                <FieldLabel>Source File *</FieldLabel>
                {editingGasAnalysis.sourceFile ? (
                  <AttachedFileItem
                    url={editingGasAnalysis.sourceFile}
                    onRemove={() =>
                      setEditingGasAnalysis({
                        ...editingGasAnalysis,
                        sourceFile: undefined,
                      })
                    }
                  />
                ) : (
                  <AddFileBox
                    onClick={() => setOpenSourceFileUploadDialog(true)}
                  >
                    <AddIcon />
                  </AddFileBox>
                )}
              </Box>
              <Box>
                <FieldLabel>Result Files</FieldLabel>
                {editingGasAnalysis.resultFiles?.map((f) => (
                  <AttachedFileItem
                    url={f}
                    onRemove={() =>
                      setEditingGasAnalysis({
                        ...editingGasAnalysis,
                        resultFiles: (
                          editingGasAnalysis.resultFiles || []
                        ).filter((rf) => rf != f),
                      })
                    }
                    mb={8}
                  />
                ))}
                <AddFileBox
                  onClick={() => setOpenResultFilesUploadDialog(true)}
                >
                  <AddIcon />
                </AddFileBox>
              </Box>

              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <Stack direction="row" spacing={1}>
                  <DatePicker
                    label="Processed Date"
                    value={dayjs(editingGasAnalysis.processedDate || null)}
                    onChange={(newValue) =>
                      setEditingGasAnalysis({
                        ...editingGasAnalysis,
                        processedDate: newValue?.toDate(),
                      })
                    }
                    slotProps={{
                      textField: { size: "small", fullWidth: true },
                    }}
                  />
                  <DatePicker
                    label="Analysis Date"
                    value={dayjs(editingGasAnalysis.analysisDate || null)}
                    onChange={(newValue) =>
                      setEditingGasAnalysis({
                        ...editingGasAnalysis,
                        analysisDate: newValue?.toDate(),
                      })
                    }
                    slotProps={{
                      textField: { size: "small", fullWidth: true },
                    }}
                  />
                </Stack>
              </LocalizationProvider>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <div style={{ flex: 1 }} />
                <Button variant="outlined" sx={{ mr: 1 }}>
                  Cancel
                </Button>
                {gasAnalysis ? (
                  <ProgressButton
                    onClick={() => handleUpdate()}
                    inProgress={processing}
                    disabled={
                      !editingGasAnalysis.samples?.length ||
                      !editingGasAnalysis.sourceFile ||
                      JSON.stringify(gasAnalysis) ==
                        JSON.stringify(editingGasAnalysis)
                    }
                    title="Save"
                  />
                ) : (
                  <ProgressButton
                    onClick={() => handleCreate()}
                    inProgress={processing}
                    disabled={
                      !editingGasAnalysis.samples?.length ||
                      !editingGasAnalysis.sourceFile
                    }
                    title="Create"
                  />
                )}
              </Box>
            </Stack>
          </Box>
        </Box>
      </Dialog>
      {openSourceFileUploadDialog && (
        <AnyAssetFileUploader
          open
          onClose={() => setOpenSourceFileUploadDialog(false)}
          onSave={(urls) => {
            setEditingGasAnalysis({
              ...editingGasAnalysis,
              sourceFile: urls?.[0],
            });
            setOpenSourceFileUploadDialog(false);
          }}
          title="Upload Source File"
        />
      )}
      {openResultFilesUploadDialog && (
        <AnyAssetFileUploader
          open
          onClose={() => setOpenResultFilesUploadDialog(false)}
          onSave={(urls) => {
            setEditingGasAnalysis({
              ...editingGasAnalysis,
              resultFiles: [...(editingGasAnalysis.resultFiles || []), ...urls],
            });
            setOpenResultFilesUploadDialog(false);
          }}
          title="Upload Result Files"
          multiple
        />
      )}
      {openSamplesTable && (
        <Dialog
          open={open}
          maxWidth={false}
          onClose={() => setOpenSamplesTable(false)}
        >
          <Box p={2}>
            <Box sx={{ border: "solid 1px #ddd", borderRadius: "5px" }}>
              <PrintableSamplesMetadataTable
                samples={editingGasAnalysis?.samples || []}
              />
            </Box>
          </Box>
        </Dialog>
      )}
    </>
  );
}
