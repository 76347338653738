import Box from "@mui/material/Box";
import parseHtml from "html-react-parser";
import { IOnboardingGuide } from "../../../types/onboardingGuide";
import Typography from "@mui/material/Typography";

export default function OnboardingGuideDisplayPanel({
  onboardingGuide,
}: {
  onboardingGuide: IOnboardingGuide;
}) {
  return (
    <Box>
      <Box>
        <Typography variant="h5" mb={3} color="primary">
          {onboardingGuide.title}
        </Typography>
        <>{onboardingGuide.iframe ? parseHtml(onboardingGuide.iframe) : null}</>
      </Box>
    </Box>
  );
}
