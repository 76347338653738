import { useState, useEffect } from "react";
const useWindowWidth = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
      // Optional: Call your function here
      console.log("Window width changed to: " + window.innerWidth + "px");
      // yourFunction();
    };

    window.addEventListener("resize", handleResize);

    // Call the function initially to set up the state with the current width
    handleResize();

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return windowWidth;
};

export default useWindowWidth;
