import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import ClearIcon from "@mui/icons-material/Clear";
import CopyToClipboardIcon from "../../Common/CopyToClipboardIcon";

export interface IVersionsObject {
  BUILD?: string;
  COMMIT_TAG?: string;
  PRODUCT?: string;
  PRODUCT_VERSION?: string;
  GRPC_VERSION?: string;
}

interface IVersionsDialogProps {
  open: boolean;
  onClose: () => void;
  frontendVersions: IVersionsObject;
  backendVersions: IVersionsObject;
}

export default function VersionsDialog({
  open,
  onClose,
  frontendVersions,
  backendVersions,
}: IVersionsDialogProps) {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth="md"
    >
      <DialogTitle id="alert-dialog-title">
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Typography variant="h6">Versions</Typography>
          <IconButton onClick={onClose}>
            <ClearIcon />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent sx={{ width: "600px", maxWidth: "100%" }}>
        <DialogContentText id="alert-dialog-description">
          <Grid container>
            <Grid item xs={12} sm={6} mb={5}>
              <Typography fontWeight={500} color="primary" mb={1}>
                Frontend
              </Typography>
              <Box>
                <b>{frontendVersions.PRODUCT}</b>{" "}
                {` v${frontendVersions.PRODUCT_VERSION}`}
              </Box>
              <Box>
                <b>grpc:</b> {` v${frontendVersions.GRPC_VERSION}`}
              </Box>
              <Box>
                <b>Built at:</b>
                {` ${frontendVersions.BUILD}`}
              </Box>
              <Box>
                <b>Commit tag:</b>
                {` ${frontendVersions.COMMIT_TAG}`}
                <CopyToClipboardIcon
                  text={frontendVersions.COMMIT_TAG}
                  sx={{ fontSize: "17px", ml: 1 }}
                />
              </Box>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography fontWeight={500} color="primary" mb={1}>
                Backend
              </Typography>
              <Box>
                <b>{backendVersions.PRODUCT}</b>{" "}
                {` v${backendVersions.PRODUCT_VERSION}`}
              </Box>
              <Box>
                <b>grpc:</b> {` v${backendVersions.GRPC_VERSION}`}
              </Box>
              <Box>
                <b>Built at:</b>
                {` ${backendVersions.BUILD}`}
              </Box>
              <Box>
                <b>Commit tag:</b>
                {` ${backendVersions.COMMIT_TAG}`}
                <CopyToClipboardIcon
                  text={frontendVersions.COMMIT_TAG}
                  sx={{ fontSize: "17px", ml: 1 }}
                />
              </Box>
            </Grid>
          </Grid>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} autoFocus>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}
