/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import Box from "@mui/material/Box";
import Dialog from "@mui/material/Dialog";
import { styled } from "@mui/material/styles";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import * as React from "react";
import AttachedFileItem from "../Common/AttachedFileItem";
import SpectraDeviceSelect, {
  SpectraDevice,
} from "../Common/SpectraDeviceSelect";
import {
  autoFillChemValues,
  ChemicalFields,
  CropTypeCodeToName,
  CropTypes,
  findDbFieldNames,
  NumberRoundDisabledDbFieldNames,
} from "../../../types/chemicals";
import SampleMaterialTypeSelect from "../Common/SampleMaterialTypeSelect";
import ProgressButton from "../Common/ProgressButton";
import sampleRecordService from "../../../service/sampleRecord.service";
import { showSnackbar } from "../../../service/snackbar.service";
import { ISampleRecordCreationPayload } from "../../../types/sample";
import { ISpectraItem } from "../../../types/spectra";

const FieldSection = styled(Box)(() => ({
  marginBottom: "24px",
}));

const Label = styled(Box)(() => ({
  fontWeight: "500",
  marginBottom: "8px",
  opacity: 0.5,
}));

export default function PredictionFetchFormDialog({
  rawSpectraFileUrl,
  onClose,
  onSuccess,
}: {
  rawSpectraFileUrl: string;
  onClose: () => void;
  onSuccess: (parsed: ISampleRecordCreationPayload[]) => void;
}) {
  const [spectraDevice, setSpectraDevice] = React.useState<
    SpectraDevice | undefined
  >(SpectraDevice.BlueSun);
  const [materialType, setMaterialType] = React.useState<CropTypes | undefined>(
    CropTypes.TMR
  );
  const [predictionProcessing, setPredictionProcessing] = React.useState(false);

  const getPrediction = async () => {
    if (!materialType) return;
    setPredictionProcessing(true);
    try {
      const fieldNames: string[] = [];
      ChemicalFields.filter((chem) => chem.types?.includes(materialType)).map(
        (c) => {
          fieldNames.push(c.fieldName);
          fieldNames.push(...(c.aliases || []));
        }
      );
      const result = await sampleRecordService.getPrediction({
        url: rawSpectraFileUrl,
        task: "RRL Prediction",
        device: spectraDevice,
        material: materialType,
        constituents: fieldNames,
      });
      const sampleNumbers = result?.labnum || [];
      if (!sampleNumbers.length) {
        showSnackbar(
          "danger",
          "Error",
          "Couldn't extract sample numbers from prediction data"
        );
        return;
      }
      const predictions = result?.predictions;
      const classPredictions = result?.class || [];
      if (!predictions) {
        showSnackbar("danger", "Error", "Couldn't extract prediction data");
        return;
      }
      const spectraData = result?.spectra;
      const wavelengths = result?.wavelength;
      const parsedSampleRecords: ISampleRecordCreationPayload[] = [];
      sampleNumbers.map((sampleNumber: string, i: number) => {
        const chemData: { [key: string]: number } = {};
        Object.keys(predictions).map((key) => {
          if (!predictions[key]?.some((val: number) => val)) return;
          const chemValue = predictions[key]?.[i];
          const dbFieldNames = findDbFieldNames(key);
          dbFieldNames.map((dbFieldName) => {
            chemData[dbFieldName] = NumberRoundDisabledDbFieldNames.includes(
              dbFieldName
            )
              ? Number(chemValue)
              : Number(Number(chemValue).toFixed(2));
          });
        });
        const spectraItems: ISpectraItem[] = [];
        const spectraValues = spectraData?.[sampleNumber] || [];
        spectraValues.map((specVal: number, i: number) => {
          spectraItems.push({
            wavelength: wavelengths?.[i],
            value: specVal,
          });
        });
        parsedSampleRecords.push({
          sampleNumber,
          type: materialType,
          chemicals: autoFillChemValues(chemData),
          spectraItems,
          predictionAiModel: result?.predictionAiModel,
          predictedType: classPredictions?.[i]
            ? (CropTypeCodeToName as any)[
                Number(classPredictions[i]).toString()
              ]
            : undefined,
        });
      });
      onSuccess(parsedSampleRecords);
      setPredictionProcessing(false);
    } catch (error) {
      console.error(error);
      showSnackbar("danger", "Error", "Error fetching prediction");
      setPredictionProcessing(false);
    }
  };

  return (
    <Dialog open maxWidth="xs">
      <Box sx={{ background: "white", width: "1000px", maxWidth: "100%" }}>
        <Box
          p={2}
          pb={0}
          sx={{ display: "flex", justifyContent: "space-between" }}
        >
          <Typography variant="h6">Get Prediction</Typography>
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Box>
        <Box p={2}>
          <FieldSection>
            <Label>Raw Spectra File</Label>
            <AttachedFileItem url={rawSpectraFileUrl} />
          </FieldSection>
          <FieldSection>
            <Label>Spectra Device</Label>
            <>
              <SpectraDeviceSelect
                value={spectraDevice}
                onChange={(newVal) => setSpectraDevice(newVal)}
              />
            </>
          </FieldSection>
          <FieldSection>
            <Label>Material Type</Label>
            <>
              <SampleMaterialTypeSelect
                value={materialType}
                onChange={(newVal) => setMaterialType(newVal)}
              />
            </>
          </FieldSection>
          <Box>
            <ProgressButton
              inProgress={predictionProcessing}
              title="Get Prediction"
              fullWidth
              onClick={() => getPrediction()}
            />
          </Box>
        </Box>
      </Box>
    </Dialog>
  );
}
