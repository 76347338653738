import Avatar from "@mui/material/Avatar";
import { SxProps, Theme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import * as React from "react";
import { IUserPublicProfile } from "../../../types/user";
import { nameToHslColor } from "../../../helpers/userAvatar";
import { fullNameInitials } from "../../../helpers/utils";

export default function UserAvatar({
  user,
  sx,
}: {
  user: IUserPublicProfile;
  sx?: SxProps<Theme>;
}) {
  return (
    <Avatar
      alt={`${user.fullname || "?"}`}
      src={user.avatar}
      sx={{
        ...(sx ? sx : {}),
        backgroundColor: user?.fullname
          ? nameToHslColor(user?.fullname, 50, 60)
          : "",
      }}
    >
      <Typography fontSize={15}>
        {fullNameInitials(
          user?.fullname?.split(" ")?.[0] || "",
          user?.fullname?.split(" ")?.[1] || ""
        ) || "?"}
      </Typography>
    </Avatar>
  );
}
