/* eslint-disable @typescript-eslint/no-explicit-any */
import { Box, Typography } from "@mui/material";
import { ISampleRecordPopulated } from "../../../types/sample";
import {
  DataGridPro,
  GridToolbarContainer,
  GridToolbarExport,
} from "@mui/x-data-grid-pro";

export default function PrintableSamplesMetadataTable({
  samples,
}: {
  samples: ISampleRecordPopulated[];
}) {
  const columns = [
    {
      field: "uid",
      headerName: "UUID",
      width: 90,
      sortable: true,
    },
    {
      field: "companyUid",
      headerName: "Lab SSID",
      width: 90,
      sortable: true,
    },
    {
      field: "sampleNumber",
      headerName: "Sample ID",
      width: 90,
      sortable: true,
    },
    {
      field: "company",
      headerName: "Lab",
      sortable: false,
      width: 200,
      valueGetter: (params: any) => params.row?.company?.name,
    },
    {
      field: "sampleClient",
      headerName: "Client",
      sortable: false,
      width: 200,
    },
    {
      field: "sampleSource",
      headerName: "Farm",
      sortable: false,
      width: 200,
    },
    {
      field: "type",
      headerName: "Type",
      sortable: false,
      width: 120,
    },
    {
      field: "bagWeight",
      headerName: "Bag Weight (g)",
      sortable: false,
      width: 120,
    },
    {
      field: "predictionAiModel",
      headerName: "Prediction Model",
      width: 150,
      valueGetter: (params: any) => {
        const row: ISampleRecordPopulated = params.row;
        return row.predictionAiModel?.title || row.predictionAiModel?.date;
      },
    },
  ];

  function CustomToolbar() {
    return (
      <GridToolbarContainer
        sx={{
          px: "12px",
          py: 1,
          borderBottom: "solid 1px #ddd",
          "& button": { height: "30px" },
        }}
      >
        <Typography variant="h6" mr={2}>
          Analyzed Samples
        </Typography>
        <div style={{ flex: 1 }} />
        <GridToolbarExport />
      </GridToolbarContainer>
    );
  }

  return (
    <Box>
      <DataGridPro
        rowHeight={40}
        columnHeaderHeight={40}
        getRowId={(row) => row._id as string}
        autoHeight
        rows={samples}
        columns={columns}
        disableRowSelectionOnClick
        slots={{ toolbar: CustomToolbar }}
      />
    </Box>
  );
}
