/* eslint-disable @typescript-eslint/no-explicit-any */
import backendApi from "./backendApi";
import { showSnackbar } from "./snackbar.service";

const routePrefix = "assets";

export default {
  async checkFileExistence({
    files,
    location,
  }: {
    files: { fileName: string; size: number }[];
    location: string;
  }): Promise<any> {
    try {
      const result = await backendApi()?.post(
        `${routePrefix}/check-existence`,
        {
          files,
          location,
        }
      );
      return result?.data || [];
    } catch (error: any) {
      if (error?.response?.status) {
        showSnackbar(
          "danger",
          "Error",
          error?.response?.data?.message || "API error response"
        );
        return null;
      }
      return [];
    }
  },
  async uploadFile({
    blob,
    fileName,
    location,
    directory,
  }: {
    blob: Blob;
    fileName?: string;
    location?: string;
    directory?: string;
  }): Promise<any> {
    try {
      const formData = new FormData();
      formData.append("image", blob);

      const result = await backendApi()?.post(
        `${routePrefix}/upload/${fileName}?location=${location}&directory=${directory}`,
        formData
      );
      if (result?.status != 200) {
        showSnackbar(
          "danger",
          "Error",
          result?.data.message || "API error response"
        );
        return null;
      }
      return result?.data?.s3Url || "";
    } catch (error: any) {
      if (error?.response?.status) {
        showSnackbar(
          "danger",
          "Error",
          error?.response?.data?.message || "API error response"
        );
        return null;
      }
    }
  },
  async downloadFile({
    fileName,
    location,
  }: {
    fileName: string;
    location?: string;
  }) {
    try {
      const response = await backendApi()?.get(
        `${routePrefix}/download/${encodeURIComponent(fileName)}${
          location ? `?location=${location}` : ""
        }`,
        {
          responseType: "blob",
        }
      );
      if (response.data) {
        const url = window.URL.createObjectURL(response.data);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          fileName.split("/")[fileName.split("/").length - 1]
        );
        document.body.appendChild(link);
        link.click();
        window.URL.revokeObjectURL(url);
        link.remove();
      }
    } catch (error: any) {
      showSnackbar(
        "danger",
        "Error",
        error?.response?.data?.message || "API error response"
      );
      return null;
    }
  },
};
