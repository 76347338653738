import { Box, MenuItem, Tooltip } from "@mui/material";
import * as React from "react";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import MessageIcon from "@mui/icons-material/Message";
import {
  DocumentationSubjectToColor,
  DocumentationSubjectToString,
  IDocumentation,
} from "../../../types/documentation";
import { InfoTag } from "../Common/ListItemInfoTag";
import { fDateTime, fToNow } from "../../../helpers/formatTime";
import { highLightWords } from "../../../helpers/utils";
import { DocumentationsPageContext } from "../../pages/documentation/DocumentationsPageContext";

export default function DocumentationListItem({
  searchTerms,
  documentation,
  onClick,
  selected,
}: {
  searchTerms?: string[];
  documentation: IDocumentation;
  onClick?: () => void;
  selected?: boolean;
}) {
  const documentationsPageContext = React.useContext(DocumentationsPageContext);
  const unseenCommentsCount = documentation.comments?.filter((d) =>
    documentationsPageContext?.unseenCommentIds?.includes(d)
  )?.length;

  return (
    <MenuItem
      onClick={onClick}
      sx={{
        p: 0,
        width: "100%",
        borderRight: selected ? "solid 3px #39bfb8" : undefined,
      }}
      selected={selected}
    >
      <Box
        py={1}
        px={1}
        sx={{ borderTop: "solid 1px #e2e2e2", position: "relative", flex: 1 }}
      >
        {documentation.subject ? (
          <Box
            sx={{
              display: "inline-flex",
              background: DocumentationSubjectToColor[documentation.subject],
              fontSize: "13px",
              padding: "0px 5px",
              borderRadius: "3px",
              marginBottom: "5px",
            }}
          >
            {DocumentationSubjectToString[documentation.subject]}
          </Box>
        ) : null}
        <Box sx={{ fontWeight: "500", color: "#007ad0", marginBottom: "3px" }}>
          {highLightWords(documentation.title, searchTerms || [])}
        </Box>
        <Box
          className="ellipsis-line-2"
          sx={{
            fontSize: "14px",
            opacity: "0.6",
            marginBottom: "6px",
          }}
        >
          {documentation.abstract}
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            fontSize: "12px",
            opacity: "0.8",
          }}
        >
          <InfoTag>
            <PersonOutlineIcon sx={{ fontSize: "14px", mr: "2px" }} />
            {documentation.actionBy?.fullname}
          </InfoTag>

          {documentation.createdAt ? (
            <InfoTag
              sx={{
                "&:hover .from-now": { display: "none !important" },
                "&:hover .exact-date": { display: "inline !important" },
              }}
            >
              <AccessTimeIcon sx={{ fontSize: "14px", mr: "2px" }} />
              <div className="from-now">{fToNow(documentation.createdAt)}</div>
              <div className="exact-date" style={{ display: "none" }}>
                {fDateTime(documentation.createdAt)}
              </div>
            </InfoTag>
          ) : null}
          {documentation.version ? (
            <InfoTag>{`v${documentation.version}`}</InfoTag>
          ) : null}

          {
            <Tooltip
              title={
                unseenCommentsCount
                  ? `${unseenCommentsCount} New Comment${
                      unseenCommentsCount > 1 ? "s" : ""
                    }`
                  : ""
              }
              placement="top"
              arrow
            >
              <Box
                sx={{
                  ml: 1,
                  fontSize: "15px",
                  display: "flex",
                  alignItems: "center",
                  color: !documentation.comments?.length
                    ? "#ddd"
                    : unseenCommentsCount
                    ? "warning.main"
                    : "#6d6d6d",
                }}
              >
                <MessageIcon sx={{ mr: "4px", fontSize: "20px" }} />
                {`${documentation.comments?.length || 0}`}
              </Box>
            </Tooltip>
          }
        </Box>
      </Box>
    </MenuItem>
  );
}
