/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import * as React from "react";
import { Box, Button } from "@mui/material";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ChatBubbleIcon from "@mui/icons-material/ChatBubble";
import ChatBubbleOutlineIcon from "@mui/icons-material/ChatBubbleOutline";
import { AutoResizeTextField } from "../AutoResizeTextField";
import { IComment } from "../../../../types/comment";
import UserAvatar from "../../User/UserAvatar";
import { fToNow } from "../../../../helpers/formatTime";
import CommentEditForm from "./CommentEditForm";
import { useAppContext } from "../../../../contexts/useStorageContext";
import { DocumentationsPageContext } from "../../../pages/documentation/DocumentationsPageContext";
import documentationService from "../../../../service/documentationService";

export default function CommentItem({
  comment,
  allComments,
  onAddReply,
  onUpdateComment,
}: {
  comment: IComment;
  allComments: IComment[];
  onAddReply?: (newComment: IComment) => Promise<void>;
  onUpdateComment?: (updatedComment: IComment) => Promise<void>;
}) {
  const replyButtonRef = React.useRef(null);
  const documentationsPageContext = React.useContext(DocumentationsPageContext);
  const { state } = useAppContext();
  const { loggedInUserInfo } = state;
  const [openReplies, setOpenReplies] = React.useState(false);
  const [openReplyForm, setOpenReplyForm] = React.useState(false);
  const [openEditForm, setOpenEditForm] = React.useState(false);
  const [seenApiSent, setSeenApiSent] = React.useState(false);
  const replies = allComments.filter((c) => c.parentCommentId == comment._id);
  const allChildrenReplyIds: string[] = [];
  const extractAllReplyIds = (replyId: string) => {
    const childrenIds = allComments
      .filter((c) => c.parentCommentId == replyId)
      .map((c) => c._id) as string[];
    allChildrenReplyIds.push(replyId, ...childrenIds);
    if (childrenIds.length) {
      childrenIds.map(extractAllReplyIds);
    }
  };
  replies.map((r) => extractAllReplyIds(r._id || ""));
  const handleAddReply = async (newReply: IComment) => {
    if (!onAddReply) return;
    try {
      await onAddReply(newReply);
      setOpenReplyForm(false);
      setOpenReplies(true);
    } catch (error) {}
  };

  React.useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            !seenApiSent &&
              documentationsPageContext.unseenCommentIds?.includes(
                comment._id || ""
              ) &&
              documentationService.markCommentSeen(comment._id || "");
            setSeenApiSent(true);
          }
        });
      },
      { threshold: 0.3 }
    );
    if (
      replyButtonRef.current &&
      !seenApiSent &&
      documentationsPageContext.unseenCommentIds?.includes(comment._id || "")
    ) {
      observer.observe(replyButtonRef.current);
    }

    return () => {
      console.log("cancel observation");
      if (replyButtonRef.current) {
        observer.unobserve(replyButtonRef.current);
      }
    };
  }, [seenApiSent, documentationsPageContext.unseenCommentIds]);

  return (
    <Box
      id={
        documentationsPageContext?.unseenCommentIds?.includes(comment._id || "")
          ? comment._id
          : undefined
      }
      sx={{
        backgroundColor: documentationsPageContext?.unseenCommentIds?.includes(
          comment._id || ""
        )
          ? "#fbf6ec"
          : "",
      }}
      onClick={(e) => {
        e.stopPropagation();
        documentationsPageContext.markCommentSeen &&
          documentationsPageContext.markCommentSeen(comment._id || "");
      }}
    >
      <Box sx={{ borderBottom: "solid 1px #eee" }}>
        <Box sx={{ display: "flex", alignItems: "center", py: 1 }}>
          <UserAvatar
            user={comment.actionBy}
            sx={{ width: "35px", height: "35px" }}
          />
          <Box ml={1}>
            <div
              style={{
                fontSize: "13px",
                fontWeight: "500",
                marginBottom: "3px",
              }}
            >{`${comment.actionBy?.fullname}`}</div>
            <div
              className="from-now"
              style={{ fontSize: "13px", opacity: 0.7 }}
            >
              {comment.updatedAt || comment.createdAt
                ? fToNow(comment.updatedAt || comment.createdAt || new Date())
                : ""}
            </div>
          </Box>
        </Box>
        {openEditForm ? (
          <CommentEditForm
            comment={comment}
            onUpdate={async (updatedComment) => {
              onUpdateComment && (await onUpdateComment(updatedComment));
              setOpenEditForm(false);
            }}
            onCancel={() => setOpenEditForm(false)}
          />
        ) : (
          <AutoResizeTextField
            key={comment._id}
            value={comment.content}
            disabled
            sx={{
              fontSize: "15px",
              fontWeight: "400",
              background: "#9a9a9a1f",
            }}
          />
        )}

        <Box
          sx={{ display: "flex", alignItems: "center", pt: "4px", pb: "4px" }}
        >
          <Button
            color={
              allChildrenReplyIds?.some((r) =>
                documentationsPageContext?.unseenCommentIds?.includes(r)
              )
                ? "warning"
                : "primary"
            }
            disabled={!replies.length}
            onClick={() => setOpenReplies(!openReplies)}
            size="small"
            startIcon={
              replies.length ? (
                <ChatBubbleIcon sx={{ fontSize: "20px" }} />
              ) : (
                <ChatBubbleOutlineIcon sx={{ fontSize: "20px" }} />
              )
            }
            endIcon={openReplies ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          >
            {`${replies.length} Repl${(replies.length || 0) > 1 ? "ies" : "y"}`}
          </Button>

          <div style={{ flex: 1 }} />
          {comment.actionBy?._id == loggedInUserInfo?._id ? (
            <Button onClick={() => setOpenEditForm(!openEditForm)} size="small">
              Edit
            </Button>
          ) : null}

          <Button
            ref={replyButtonRef}
            onClick={() => setOpenReplyForm(true)}
            size="small"
          >
            Reply
          </Button>
        </Box>
      </Box>
      {openReplyForm && loggedInUserInfo ? (
        <Box
          sx={{
            mt: 1,
            mx: "4px",
            maxWidth: "600px",
            background: "white",
            border: "solid 1px #ddd",
            borderRadius: "3px",
            boxShadow:
              "rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;",
          }}
        >
          <CommentEditForm
            comment={{
              content: "",
              actionBy: loggedInUserInfo,
              resourceId: comment.resourceId,
              parentCommentId: comment._id,
            }}
            onCancel={() => setOpenReplyForm(false)}
            onCreate={(newComment) => handleAddReply(newComment)}
          />
        </Box>
      ) : null}
      {openReplies && replies.length ? (
        <Box sx={{ pl: "12px", borderLeft: "solid 2px #bebebe" }}>
          {replies.map((reply) => (
            <CommentItem
              key={reply._id}
              comment={reply}
              allComments={allComments}
              onAddReply={handleAddReply}
              onUpdateComment={onUpdateComment}
            />
          ))}
        </Box>
      ) : null}
      <Box></Box>
    </Box>
  );
}
