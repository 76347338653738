import { PaletteMode } from "@mui/material";
import Styles from "./styles";

const ThemeOptions = (mode: PaletteMode) => ({
  palette: {
    mode,
    ...(mode === "light" ? Styles.light : Styles.dark),
  },
  typography: {
    button: {
      textTransform: "none" as const,
    },
  },
});

export default ThemeOptions;
