import TextField from "@mui/material/TextField";
import * as React from "react";
import { debounce } from "lodash";
import CloseIcon from "@mui/icons-material/Close";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import { Tooltip } from "@mui/material";

export default function DebouncedRangeInput({
  width,
  minTitle,
  maxTitle,
  min,
  max,
  onChangeMin,
  onChangeMax,
  minInvalid,
  maxInvalid,
}: {
  width?: number;
  minTitle: string;
  maxTitle: string;
  min?: string;
  max?: string;
  onChangeMin: (changed?: string) => void;
  onChangeMax: (changed?: string) => void;
  minInvalid?: boolean;
  maxInvalid?: boolean;
}) {
  const [minInputKey, setMinInputKey] = React.useState(0);
  const [maxInputKey, setMaxInputKey] = React.useState(100000);

  const debouncedSearchByMin = debounce(onChangeMin, 500);
  const debouncedSearchByMax = debounce(onChangeMax, 500);

  return (
    <>
      <Tooltip title={minInvalid ? "Invalid" : ""} arrow open={minInvalid}>
        <TextField
          error={minInvalid}
          key={minInputKey}
          value={undefined}
          onChange={(e) => debouncedSearchByMin(e.target.value || undefined)}
          focused={!!min}
          placeholder={minTitle}
          sx={{
            width: `${width || 120}px`,
            "& input": { padding: "5px 10px 5px 10px" },
            "& .MuiInputBase-root": {
              borderBottomRightRadius: "0px !important",
              borderTopRightRadius: "0px !important",
            },
            "& fieldset": {
              borderBottomRightRadius: "0px !important",
              borderTopRightRadius: "0px",
            },
          }}
          InputProps={{
            startAdornment: min ? (
              <InputAdornment
                position="start"
                sx={{ ml: "-14px", mr: "-11px" }}
              >
                {min ? (
                  <IconButton
                    size="small"
                    onClick={() => {
                      onChangeMin(undefined);
                      setMinInputKey(minInputKey + 1);
                    }}
                  >
                    <CloseIcon />
                  </IconButton>
                ) : null}
              </InputAdornment>
            ) : undefined,
          }}
        />
      </Tooltip>
      <Tooltip title={maxInvalid ? "Invalid" : ""} arrow open={maxInvalid}>
        <TextField
          error={maxInvalid}
          key={maxInputKey}
          value={undefined}
          focused={!!max}
          onChange={(e) => debouncedSearchByMax(e.target.value || undefined)}
          placeholder={maxTitle}
          sx={{
            width: `${width || 120}px`,
            "& input": { padding: "5px 10px 5px 10px" },
            "& .MuiInputBase-root": {
              borderBottomLeftRadius: "0px !important",
              borderTopLeftRadius: "0px !important",
            },
            "& fieldset": {
              borderBottomLeftRadius: "0px !important",
              borderTopLeftRadius: "0px",
            },
          }}
          InputProps={{
            endAdornment: max ? (
              <InputAdornment position="end" sx={{ mr: "-14px", ml: "-11px" }}>
                {max ? (
                  <IconButton
                    size="small"
                    onClick={() => {
                      onChangeMax(undefined);
                      setMaxInputKey(maxInputKey + 1);
                    }}
                  >
                    <CloseIcon />
                  </IconButton>
                ) : null}
              </InputAdornment>
            ) : undefined,
          }}
        />
      </Tooltip>
    </>
  );
}
