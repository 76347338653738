import { IUserPopulated } from "./auth";

export enum PredictionModelProduct {
  TMR = "TMR",
  CS = "CS",
}

export interface IPredictionAiModel {
  _id: string;
  date: string;
  product: string;
  title: string;
  description?: string;
  link?: string;
  actionBy: IUserPopulated;
  files?: string[];
}
