import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";
import { ISampleRecordPopulated } from "../../../../types/sample";
import { getQuadrant } from "../../../../helpers/gasAnalysisReport";

export const fastSlow = ["Fast", "Slow"];
export const argmaxLogpDerivative = (a: number, b: number) => {
  return b + 1 / (2 * a);
};

export const SummerySection = styled(Box)(() => ({
  borderRadius: "8px",
  overflow: "hidden",
  backgroundColor: "#eee",
  height: "100%",
  "& .summery-title": {
    fontWeight: "600",
    padding: "8px 12px",
    background: "#1d969a",
    color: "white",
  },
  "& .summery-item": {
    display: "flex",
    marginBottom: "6px",
    fontWeight: 500,
    "& .summery-item-dot": {
      width: "5px",
      minWidth: "5px",
      maxWidth: "5px",
      height: "5px",
      background: "#333",
      marginTop: "7px",
      marginRight: "8px",
      borderRadius: "100px",
    },
  },
  "& .summery-content": {
    padding: "12px",
  },
}));

export const SliderInfo = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  fontSize: "13px",
  "& svg": { marginRight: "4px", fontSize: "18px" },
  marginTop: "12px",
  marginBottom: "-13px",
}));

export const getFastHrMaxRateHours = (sampleRecord: ISampleRecordPopulated) => {
  return sampleRecord.chemicals?.["Fast_HR"] &&
    sampleRecord.chemicals?.["LagTime"]
    ? Number(
        (
          argmaxLogpDerivative(
            sampleRecord.chemicals?.["Fast_HR"],
            sampleRecord.chemicals?.["LagTime"]
          ) / 60
        ).toFixed(2)
      )
    : 0;
};

export const getSlowHrMaxRateHours = (sampleRecord: ISampleRecordPopulated) => {
  return sampleRecord.chemicals?.["Slow_HR"] &&
    sampleRecord.chemicals?.["LagTime"]
    ? Number(
        (
          argmaxLogpDerivative(
            sampleRecord.chemicals?.["Slow_HR"],
            sampleRecord.chemicals?.["LagTime"]
          ) / 60
        ).toFixed(2)
      )
    : 0;
};

export const getQuadrants = (sampleRecord: ISampleRecordPopulated) => {
  return sampleRecord.chemicals?.["Slow_HR"] &&
    sampleRecord.chemicals?.["Fast_HR"] &&
    sampleRecord.chemicals?.["MBP"]
    ? getQuadrant(
        sampleRecord.chemicals?.["Fast_HR"],
        sampleRecord.chemicals?.["Slow_HR"],
        sampleRecord.chemicals?.["MBP"]
      )
    : [0];
};

export const getBiggestQuadrant = (sampleRecord: ISampleRecordPopulated) => {
  const quadrants = getQuadrants(sampleRecord);
  return quadrants.some((q) => q > 0)
    ? quadrants.indexOf(Math.max(...quadrants))
    : null;
};
