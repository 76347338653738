/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Stack,
  TextField,
} from "@mui/material";
import { IOnboardingGuide } from "../../../types/onboardingGuide";
import { useEffect, useState } from "react";
import ProgressButton from "../Common/ProgressButton";
import onboardingGuideService from "../../../service/onboardingGuide.service";

interface OnboardingGuideFormDialogProps {
  open: boolean;
  onClose: () => void;
  onboardingGuide?: IOnboardingGuide;
  onCreated?: (created: IOnboardingGuide) => void;
  onUpdated?: (updated: IOnboardingGuide) => void;
}

const OnboardingGuideFormDialog: React.FC<OnboardingGuideFormDialogProps> = ({
  open,
  onClose,
  onboardingGuide,
  onCreated,
  onUpdated,
}) => {
  const [editingGuide, setEditingGuide] = useState<
    IOnboardingGuide | undefined
  >(onboardingGuide);
  const [processing, setProcessing] = useState(false);

  const handleCreate = async () => {
    if (!editingGuide) return;
    try {
      setProcessing(true);
      try {
        const created = await onboardingGuideService.create(editingGuide);
        onCreated && onCreated(created);
      } catch (error) {
        console.error(error);
      }
      setProcessing(false);
    } catch (error) {
      setProcessing(false);
    }
  };

  const handleSave = async () => {
    if (!editingGuide || !onboardingGuide?._id) return;
    try {
      setProcessing(true);
      try {
        const updated = await onboardingGuideService.update(
          onboardingGuide._id,
          editingGuide
        );
        onUpdated && onUpdated(updated);
      } catch (error) {
        console.error(error);
      }
      setProcessing(false);
    } catch (error) {
      setProcessing(false);
    }
  };

  useEffect(() => {
    setEditingGuide(onboardingGuide);
  }, [onboardingGuide]);

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm">
      <DialogTitle>{`${
        onboardingGuide ? "Edit" : "Create"
      } Onboarding Guide`}</DialogTitle>
      <DialogContent sx={{ minWidth: "400px" }}>
        <Stack>
          <TextField
            label="Title"
            variant="outlined"
            fullWidth
            value={editingGuide?.title || ""}
            onChange={(e) =>
              setEditingGuide({ ...editingGuide, title: e.target.value })
            }
            margin="normal"
            size="small"
          />
          <TextField
            label="Description"
            variant="outlined"
            fullWidth
            multiline
            rows={2}
            value={editingGuide?.description || ""}
            onChange={(e) =>
              setEditingGuide({ ...editingGuide, description: e.target.value })
            }
            margin="normal"
            size="small"
          />
          <TextField
            label="Iframe URL"
            variant="outlined"
            fullWidth
            rows={3}
            multiline
            value={editingGuide?.iframe || ""}
            onChange={(e) =>
              setEditingGuide({ ...editingGuide, iframe: e.target.value })
            }
            margin="normal"
            size="small"
          />
        </Stack>
        {/* Form fields can be added here */}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        {onboardingGuide ? (
          <ProgressButton
            disabled={!editingGuide?.title || !editingGuide?.iframe}
            inProgress={processing}
            onClick={() => {
              handleSave();
            }}
            title="Save"
          />
        ) : (
          <ProgressButton
            disabled={!editingGuide?.title || !editingGuide?.iframe}
            inProgress={processing}
            onClick={() => {
              handleCreate();
            }}
            title="Create"
          />
        )}
      </DialogActions>
    </Dialog>
  );
};

export default OnboardingGuideFormDialog;
