/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import * as React from "react";
import { IComment } from "../../../../types/comment";
import { AutoResizeTextField } from "../AutoResizeTextField";
import Box from "@mui/material/Box";
import ProgressButton from "../ProgressButton";
import Button from "@mui/material/Button";

export default function CommentEditForm({
  comment,
  onCreate,
  onUpdate,
  onCancel,
}: {
  comment: IComment;
  onCreate?: (newComment: IComment) => Promise<void>;
  onUpdate?: (updatedComment: IComment) => Promise<void>;
  onCancel: () => void;
}) {
  const [content, setContent] = React.useState(comment.content || "");
  const [processing, setProcessing] = React.useState(false);

  const handleCreate = async (newComment: IComment) => {
    if (!onCreate) return;
    setProcessing(true);
    await onCreate(newComment);
    setProcessing(false);
  };

  const handleUpdate = async (updated: IComment) => {
    if (!onUpdate) return;
    setProcessing(true);
    await onUpdate(updated);
    setProcessing(false);
  };

  return (
    <Box p={2}>
      <AutoResizeTextField
        value={content}
        onChange={(e) => {
          setContent(e.target.value);
        }}
        minRows={3}
        sx={{ fontSize: "15px", fontWeight: "400" }}
        placeholder="What are  your thoughts?"
      />
      <Box sx={{ display: "flex", alignItems: "center", pt: 1 }}>
        <div style={{ flex: 1 }} />
        <Button
          onClick={() => onCancel()}
          variant="outlined"
          color="inherit"
          sx={{ mr: 1 }}
          size="small"
        >
          Cancel
        </Button>
        {comment.content ? (
          <ProgressButton
            size="small"
            disabled={!content}
            inProgress={processing}
            title="Save Comment"
            onClick={() => {
              handleUpdate({ ...comment, content });
            }}
          />
        ) : (
          <ProgressButton
            size="small"
            disabled={!content}
            onClick={() => {
              handleCreate({ ...comment, content });
            }}
            inProgress={processing}
            title={comment?.parentCommentId ? "Add Reply" : "Add Comment"}
          />
        )}
      </Box>
    </Box>
  );
}
