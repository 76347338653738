import * as React from "react";
import Box from "@mui/material/Box";
import ClosableDialog from "../Common/ClosableDialog";
import Stack from "@mui/material/Stack";
import { Button, Checkbox, FormControlLabel, TextField } from "@mui/material";

export default function ExportDatasetFormDialog({
  recordCount,
  onComplete,
  onClose,
}: {
  recordCount: number;
  onComplete: (
    fileName: string,
    description: string,
    groupName?: string,
    groupDescription?: string
  ) => void;
  onClose: () => void;
}) {
  const [fileName, setFileName] = React.useState("");
  const [description, setDescription] = React.useState("");
  const [setAsGroup, setSetAsGroup] = React.useState(false);
  const [groupName, setGroupName] = React.useState(fileName);

  return (
    <ClosableDialog
      onClose={onClose}
      open
      maxWidth="xs"
      titleElement={
        <div style={{ display: "flex", alignItems: "center" }}>
          Export Dataset
          <Box
            sx={{ ml: 1, color: "warning.main" }}
          >{`(${recordCount} samples)`}</Box>
        </div>
      }
      contents={
        <Box p={2}>
          <Stack spacing={2} width={400}>
            <TextField
              label="File Name"
              size="small"
              value={fileName}
              onChange={(e) => setFileName(e.target.value)}
            />
            <TextField
              label="Description"
              rows={2}
              maxRows={5}
              size="small"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              multiline
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={setAsGroup}
                  onChange={(e, checked) => setSetAsGroup(checked)}
                />
              }
              label="Set these samples as a group"
            />
            {setAsGroup && (
              <TextField
                label="Group Name"
                size="small"
                value={groupName}
                onChange={(e) => setGroupName(e.target.value)}
                error={setAsGroup && !groupName}
                helperText={
                  setAsGroup && !groupName ? "Group name can't be empty" : ""
                }
              />
            )}

            <Button
              fullWidth
              variant="contained"
              disabled={!fileName || !description || (setAsGroup && !groupName)}
              onClick={() => {
                onComplete(
                  fileName,
                  description,
                  setAsGroup ? groupName : undefined,
                  setAsGroup ? description : undefined
                );
                onClose();
              }}
            >
              Export
            </Button>
          </Stack>
        </Box>
      }
    />
  );
}
