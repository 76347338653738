/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState, useEffect } from "react";
import { Box, IconButton, Tooltip, Typography } from "@mui/material";
import {
  DataGridPro,
  GridColDef,
  GridColumnHeaderParams,
} from "@mui/x-data-grid-pro";
import BarChartIcon from "@mui/icons-material/BarChart";
import sampleRecordService from "../../../../service/sampleRecord.service";
import {
  IArchivedPrediction,
  ISampleRecordPopulated,
} from "../../../../types/sample";
import WhitePanel from "../../Common/WhitePanel";
import { ChemicalField, ChemicalFields } from "../../../../types/chemicals";
import useWindowWidth from "../../../../hooks/useWindowWidth";

interface SampleRecordDetailPanelProps {
  row: ISampleRecordPopulated;
}

const SampleRecordDetailPanel: React.FC<SampleRecordDetailPanelProps> = ({
  row,
}: {
  row: ISampleRecordPopulated;
}) => {
  const [archivedRecords, setArchivedRecords] = useState<IArchivedPrediction[]>(
    []
  );
  const windowWidth = useWindowWidth();
  const [loading, setLoading] = useState(true);

  const getChemicalNumberValueColumn = (
    field: ChemicalField
  ): GridColDef<ISampleRecordPopulated> => {
    return {
      field: `chemicals.${field.fieldName}`, // added chemicals. for recognizing that it's chemical column especially in sortModel
      headerName: field.fieldName,
      width: 100,
      sortable: true,
      filterable: false,
      renderCell: (params: any) => {
        const row: ISampleRecordPopulated = params.row;
        return (
          <div>
            {row.chemicals?.[
              [field.fieldName, ...(field.aliases || [])].find(
                (fName) => row.chemicals?.[fName]
              ) || "none"
            ] || ""}
          </div>
        );
      },
      renderHeader: (
        params: GridColumnHeaderParams<ISampleRecordPopulated, any, any>
      ) => {
        return (
          <Box
            sx={{
              minWidth: "80px",
              display: "flex",
              alignItems: "center",
              "&:hover .fieldName": {
                display: "none",
              },
              "&:hover .actionButton": { display: "flex" },
            }}
          >
            <div className="fieldName">{field.fieldName}</div>

            {/* <Tooltip
                title={`${field.fieldName} Distribution Graph`}
                placement="bottom"
                arrow
              >
                <IconButton
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    addFieldToDistributions(field.fieldName, "chemicals");
                  }}
                  className="actionButton"
                  size="small"
                  sx={{ display: "none", width: "27px", height: "27px" }}
                >
                  <BarChartIcon />
                </IconButton>
              </Tooltip> */}
          </Box>
        );
      },
      editable: false,
    } as GridColDef<ISampleRecordPopulated>;
  };

  const fetchArchivedRecords = async () => {
    try {
      if (!row.sampleNumber) {
        return;
      }
      const records = await sampleRecordService.getArchivedRecords(
        row.sampleNumber
      );
      setArchivedRecords(records);
    } catch (error) {
      console.error("Error fetching archived records:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchArchivedRecords();
  }, [row.sampleNumber]);

  const columns: GridColDef[] = [
    {
      field: "updateReason",
      headerName: "Override Reason",
      width: 200,
      editable: false,
      renderCell: (params: any) => {
        return (
          <Tooltip title={params.value} placement="right" arrow>
            <div>{params.value}</div>
          </Tooltip>
        );
      },
    },
    {
      field: "createdAt",
      headerName: "Created At",
      width: 200,
      valueGetter: (params) => new Date(params.value).toLocaleString(),
      editable: false,
    },
    {
      field: "predictionAiModel",
      headerName: "Prediction Model",
      width: 200,
      valueGetter: (params) => params.value?.title || params.value?.date,
      editable: false,
    },
    ...ChemicalFields.map((field) => getChemicalNumberValueColumn(field)),
  ];
  return (
    <Box sx={{ minHeight: 300, p: 2, display: "flex" }}>
      <WhitePanel
        sx={{
          maxWidth: `${(windowWidth || 1000) - 200}px`,
          overflow: "auto",
        }}
      >
        <div>
          <Typography
            variant="h6"
            gutterBottom
            component="div"
            color={"primary"}
          >
            Archived Records for Sample "{row.sampleNumber}"
          </Typography>
          <DataGridPro
            rows={archivedRecords}
            columns={columns}
            loading={loading}
            getRowId={(row) => row._id}
            disableRowSelectionOnClick
            autoHeight
          />
        </div>
      </WhitePanel>
    </Box>
  );
};

export default SampleRecordDetailPanel;
