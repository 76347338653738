/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import Box from "@mui/material/Box";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import Dialog from "@mui/material/Dialog";
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import * as React from "react";
import LoadDataFile from "../Common/LoadDataFile";
import Button from "@mui/material/Button";
import ProgressButton from "../Common/ProgressButton";
import { isNumber, uniqBy } from "lodash";
import { ISampleRecordCreationPayload } from "../../../types/sample";
import SpectraDataTable from "./SpectraDataTable";
import CompanyAutoComplete from "../Common/CompanyAutoComplete";
import { useAppContext } from "../../../contexts/useStorageContext";
import { ICompany } from "../../../types/company";
import { UserRoles } from "../../../types/auth";
import sampleRecordService from "../../../service/sampleRecord.service";
import CircularProgress from "@mui/material/CircularProgress";
import { ISampleSource } from "../../../types/sampleSource";
import SampleSourceAutoComplete from "../Common/SampleSourceAutoComplete";
import { ChemicalDataBaseFields } from "../../../types/chemicals";
import GeneralPopoverWrapper from "../Common/GeneralPopoverWrapper";
import AttachedFileItem, { AddFileBox } from "../Common/AttachedFileItem";
import AnyAssetFileUploader from "../Common/AnyAssetFileUploader";
import usePrevious from "../../../hooks/usePrevious";
import Stack from "@mui/material/Stack";

export default function UploadSpectraDataDialog({
  onClose,
}: {
  onClose: () => void;
}) {
  const { state } = useAppContext();
  const { myCompanyProfile, loggedInUserInfo, myClients } = state;
  const isAdmin =
    loggedInUserInfo?.role &&
    [UserRoles.superadmin, UserRoles.adminStaff].includes(
      loggedInUserInfo?.role as UserRoles
    );
  const [errorMessage, setErrorMessage] = React.useState("");
  const [analysisItems, setAnalysisItems] = React.useState<
    ISampleRecordCreationPayload[]
  >([]);
  const [headerFields, setHeaderFields] = React.useState<number[]>();
  const [saving, setSaving] = React.useState(false);
  const [saveSuccess, setSaveSuccess] = React.useState(false);
  const [selectedCompany, setSelectedCompany] = React.useState<
    ICompany | undefined
  >(!isAdmin ? myCompanyProfile : undefined);
  const [existingDataset, setExistingDataset] = React.useState<
    { uid: number; companyUid: number; sampleNumber: string }[]
  >([]);
  const [checkingExistence, setCheckingExistence] = React.useState(false);
  const [selectedSampleSource, setSelectedSampleSource] =
    React.useState<ISampleSource>();
  const [fileName, setFileName] = React.useState("");
  const [calibrationFiles, setCalibrationFiles] = React.useState<string[]>([]);
  const [openFileUploadDialog, setOpenFileUploadDialog] = React.useState(false);
  const prevStates = usePrevious({ analysisItems });
  const [selectedClient, setSelectedClient] = React.useState<
    ICompany | undefined
  >(
    !isAdmin && myCompanyProfile?.vendorCompanyId ? myCompanyProfile : undefined
  );

  const onParseData = (data: any, fileName?: string) => {
    setFileName(fileName || "");
    const tempHeaderFields: string[] = data?.[0];
    if (!tempHeaderFields?.length) {
      setErrorMessage("Header row is empty");
      return;
    }
    setHeaderFields(
      tempHeaderFields.filter((f) => !isNaN(Number(f))).map((f) => Number(f))
    );
    const spectraAnalysisItems: ISampleRecordCreationPayload[] = [];
    const baseFieldsIndexMapper: any = {};
    ChemicalDataBaseFields.map((f) => {
      baseFieldsIndexMapper[f.dbField] = tempHeaderFields.findIndex((hf) => {
        return (
          hf?.toLowerCase &&
          [f.fieldName, ...(f.aliases || [])].some((a) => {
            return a.toLowerCase() == hf.toLowerCase();
          })
        );
      });
    });
    for (let i = 1; i < data.length; i++) {
      const row = data[i];
      const spectraAnalysisItem: ISampleRecordCreationPayload = {
        temporaryId: i + 0.000001,
        sampleNumber: row[baseFieldsIndexMapper["sampleNumber"]]
          ?.toString()
          ?.trim(),
        spectraItems: [],
      };
      for (let j = 1; j < row.length; j++) {
        if (isNaN(Number(tempHeaderFields[j]))) continue;
        const fieldValue = row[j];
        spectraAnalysisItem.spectraItems?.push({
          wavelength: Number(tempHeaderFields[j]),
          value: fieldValue,
        });
      }
      spectraAnalysisItems.push(spectraAnalysisItem);
    }
    setAnalysisItems(spectraAnalysisItems);
  };

  const saveData = async () => {
    if (!selectedCompany?._id) return;
    setSaving(true);
    const result = await sampleRecordService.saveSampleRecords({
      clientId: selectedClient?._id,
      companyId: selectedCompany._id,
      newRecords: analysisItems,
      dataField: "spectraItems",
      fileName,
    });
    setSaving(false);
    if (result.status == 200) {
      setSaveSuccess(true);
    }
  };

  const checkSampleExistence = async () => {
    if (!selectedCompany?._id || !analysisItems?.length) return;
    setCheckingExistence(true);
    const result = await sampleRecordService.checkSampleExistence(
      selectedClient?._id || selectedCompany._id,
      analysisItems.map((a) => a.sampleNumber || ""),
      "spectraItems"
    );
    setExistingDataset(result.existingSamples || []);
    let newCompanyUid = result.maxCompanyUid;
    const companyUidAssignedItems = analysisItems.map((item) => {
      if (
        result.existingSamples?.some(
          (s: any) => s.sampleNumber == item.sampleNumber
        )
      ) {
        return item;
      }

      newCompanyUid = newCompanyUid + 1;
      return {
        ...item,
        companyUid: newCompanyUid,
      };
    });
    setAnalysisItems(companyUidAssignedItems);
    setCheckingExistence(false);
  };

  React.useEffect(() => {
    if (selectedCompany?._id && analysisItems?.length) {
      checkSampleExistence();
    }
  }, [selectedCompany, selectedClient]);

  React.useEffect(() => {
    if (
      selectedCompany?._id &&
      analysisItems?.length &&
      prevStates?.analysisItems
        ?.map((s) => s.sampleNumber)
        ?.sort()
        ?.join(",") !=
        analysisItems
          ?.map((s) => s.sampleNumber)
          ?.sort()
          ?.join(",")
    ) {
      checkSampleExistence();
    }
  }, [analysisItems]);

  React.useEffect(() => {
    if (analysisItems.length && selectedSampleSource) {
      setAnalysisItems(
        analysisItems.map((a) => ({
          ...a,
          sampleSource: selectedSampleSource.name,
        }))
      );
    }
  }, [selectedSampleSource]);

  React.useEffect(() => {
    if (analysisItems.length) {
      setAnalysisItems(
        analysisItems.map((a) => ({
          ...a,
          scannerCalibrationFiles: [...calibrationFiles],
        }))
      );
    }
  }, [calibrationFiles]);

  return (
    <Dialog open maxWidth={false} onClose={onClose}>
      <Box
        p={2}
        sx={{ background: "white", minWidth: "400px", maxWidth: "100%" }}
      >
        <Box
          px={1}
          pb={2}
          sx={{ display: "flex", justifyContent: "space-between" }}
        >
          <Typography variant="h6">Upload Spectra Data</Typography>
          <IconButton onClick={onClose} sx={{ marginTop: "-10px" }}>
            <CloseIcon />
          </IconButton>
        </Box>
        <Box>
          {errorMessage ? (
            <Box
              sx={{
                color: "error.main",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                height: "200px",
                p: 2,
              }}
            >
              {errorMessage}
              <Button
                variant="outlined"
                sx={{ mt: 2 }}
                onClick={() => setErrorMessage("")}
              >
                Try other file
              </Button>
            </Box>
          ) : analysisItems.length ? (
            <Box pb={1}>
              <SpectraDataTable
                headerFields={headerFields}
                analysisItems={analysisItems}
                existingDataset={existingDataset}
                showValidation={!!selectedCompany && !checkingExistence}
              />
              <div style={{ display: "flex", justifyContent: "center" }}>
                {checkingExistence ||
                !selectedCompany?._id ||
                !analysisItems?.length ? (
                  <div />
                ) : (
                  <div style={{ marginRight: "8px" }}>
                    <div style={{ maxWidth: "470px", marginTop: "-45px" }}>
                      {existingDataset?.length ? (
                        <Box
                          sx={{
                            color: "warning.main",
                            background: "#ffe3c0",
                            padding: "10px 9px",
                            borderRadius: "5px",
                          }}
                        >{`${existingDataset?.length} samples are already existing and will be updated.`}</Box>
                      ) : null}
                      {!existingDataset?.length ? (
                        <Box
                          sx={{
                            color: "success.main",
                            background: "#ceffe5",
                            padding: "10px 9px",
                            borderRadius: "5px",
                          }}
                        >
                          All samples are new records
                        </Box>
                      ) : null}
                    </div>
                  </div>
                )}
                <div style={{ flex: 1 }} />
                <GeneralPopoverWrapper
                  triggerElement={
                    <Button
                      variant="outlined"
                      color="inherit"
                      sx={{ mr: 1 }}
                      startIcon={
                        !calibrationFiles?.length ? (
                          <AddIcon />
                        ) : (
                          <Box
                            sx={{
                              lineHeight: "1.3",
                              fontWeight: "500",
                              color: "primary.main",
                            }}
                          >
                            {calibrationFiles.length}
                          </Box>
                        )
                      }
                    >
                      Calibration Files
                    </Button>
                  }
                  popoverContent={
                    <Box p={2}>
                      <div style={{ marginBottom: "12px" }}>
                        Scanner Calibration Files
                      </div>
                      <div>
                        {calibrationFiles.map((calFile) => (
                          <AttachedFileItem
                            key={calFile}
                            url={calFile}
                            onRemove={() =>
                              setCalibrationFiles(
                                calibrationFiles.filter((c) => c != calFile)
                              )
                            }
                            mb={8}
                          />
                        ))}
                      </div>
                      <AddFileBox onClick={() => setOpenFileUploadDialog(true)}>
                        <AddIcon />
                      </AddFileBox>
                    </Box>
                  }
                />
                <Stack
                  style={{
                    marginRight: "8px",
                    display: "flex",
                    alignItems: "center",
                  }}
                  spacing={1}
                  direction="row"
                >
                  {!isAdmin ? null : (
                    <CompanyAutoComplete
                      label={"From Which Lab"}
                      onlyDirectPartners
                      value={selectedCompany}
                      onChange={(selected) =>
                        setSelectedCompany(selected || undefined)
                      }
                    />
                  )}
                  {(isAdmin || myClients?.length) && selectedCompany ? (
                    <CompanyAutoComplete
                      enabled
                      limitedOptions={isAdmin ? undefined : myClients}
                      definedVendorId={selectedCompany?._id}
                      label={"From Which Client"}
                      value={selectedClient}
                      onChange={(selected) =>
                        setSelectedClient(selected || undefined)
                      }
                    />
                  ) : null}
                </Stack>
                {/* <div style={{ marginRight: "8px", minWidth: "250px" }}>
                  <SampleSourceAutoComplete
                    fullWidth
                    label="Sample Source (Farm)"
                    value={selectedSampleSource}
                    onChange={(selected) =>
                      setSelectedSampleSource(selected || undefined)
                    }
                  />
                </div> */}
                {saveSuccess ? (
                  <Box
                    sx={{
                      color: "success.main",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <CheckCircleIcon sx={{ mr: 1 }} />
                    Saved Successfully
                  </Box>
                ) : (
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <ProgressButton
                      onClick={() => saveData()}
                      inProgress={saving}
                      title="Save Data"
                      variant="contained"
                      disableElevation={false}
                      disabled={!selectedCompany || checkingExistence}
                    />
                  </Box>
                )}
              </div>
            </Box>
          ) : (
            <LoadDataFile onParseData={onParseData} />
          )}
        </Box>
      </Box>
      {openFileUploadDialog && (
        <AnyAssetFileUploader
          directory="spectra_calibration"
          open
          onClose={() => setOpenFileUploadDialog(false)}
          onSave={(urls) => {
            const updatedDocUrls = uniqBy(
              [...(calibrationFiles || []), ...urls],
              (v) => v
            );
            setCalibrationFiles(updatedDocUrls);
            setOpenFileUploadDialog(false);
          }}
          title="Upload Calibration File(s)"
          multiple
        />
      )}
    </Dialog>
  );
}
