import Box from "@mui/material/Box";
import * as React from "react";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import { ISampleRecordManualUpdate } from "../../../types/sample";
import { InfoTag } from "../Common/ListItemInfoTag";
import { fDateTime, fToNow } from "../../../helpers/formatTime";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";

export default function SampleRecordManualUpdateItem({
  update,
}: {
  update: ISampleRecordManualUpdate;
}) {
  return (
    <Box px={2} py={1} sx={{ position: "relative", flex: 1 }}>
      <Box
        sx={{
          fontSize: "14px",
          opacity: "0.8",
          marginBottom: "6px",
        }}
      >
        {update.reason ? (
          <Box
            sx={{ fontWeight: "500", color: "#007ad0", marginBottom: "3px" }}
          >
            {update.reason}
          </Box>
        ) : null}

        <Table size="small" aria-label="updated fields table">
          <TableBody>
            {update.updatedFields.map((field, index) => (
              <TableRow key={index}>
                <TableCell
                  component="th"
                  scope="row"
                  sx={{ fontWeight: "bold" }}
                >
                  {field.fieldName}
                </TableCell>
                <TableCell align="right">{field.oldValue || ""}</TableCell>
                <TableCell align="right">
                  <ArrowForwardIcon color="warning" />
                </TableCell>
                <TableCell align="right">{field.newValue || ""}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          fontSize: "12px",
          opacity: "0.8",
        }}
      >
        <InfoTag>
          <PersonOutlineIcon sx={{ fontSize: "14px", mr: "2px" }} />
          {update.updatedBy?.fullName}
        </InfoTag>
        <InfoTag
          sx={{
            "&:hover .from-now": { display: "none !important" },
            "&:hover .exact-date": { display: "inline !important" },
          }}
        >
          <AccessTimeIcon sx={{ fontSize: "14px", mr: "2px" }} />
          <div className="from-now">{fToNow(update.updatedAt)}</div>
          <div className="exact-date" style={{ display: "none" }}>
            {fDateTime(update.updatedAt)}
          </div>
        </InfoTag>
      </Box>
    </Box>
  );
}
