/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as React from "react";
import { Box } from "@mui/material";
import WhitePanel from "../../components/Common/WhitePanel";
import PredictionModelsTabs from "../../components/PredictionModel/PredictionModelsTabs";

export default function PredictionModelsPage() {
  return (
    <>
      <Box mb={1}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            fontSize: "24px",
            fontWeight: "500",
            opacity: 0.8,
          }}
        >
          Prediction Models
        </Box>
      </Box>
      <WhitePanel>
        <PredictionModelsTabs />
      </WhitePanel>
    </>
  );
}
