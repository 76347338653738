/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import Box from "@mui/material/Box";
import PrintIcon from "@mui/icons-material/Print";
import parseHtml from "html-react-parser";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import * as React from "react";
import IconButton from "@mui/material/IconButton";
import DialogContent from "@mui/material/DialogContent";
import { Button, Chip, DialogActions, Grid } from "@mui/material";
import { styled } from "@mui/material/styles";
import {
  DocumentationSubjectToColor,
  DocumentationSubjectToString,
  IDocumentation,
} from "../../../types/documentation";
import { AutoResizeTextField } from "../Common/AutoResizeTextField";
import AttachedFileItem from "../Common/AttachedFileItem";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";

const FieldLabel = styled(Box)(() => ({
  fontSize: "16px",
  marginBottom: "8px",
  color: "#007ad0",
  fontWeight: "500",
}));

const FieldSection = styled(Box)(() => ({
  marginBottom: "16px",
}));
const FieldContent = styled(Box)(() => ({
  fontSize: "16px",
}));

export default function DocumentationPrintDialog({
  documentation,
  onClose,
}: {
  documentation: IDocumentation;
  onClose: () => void;
}) {
  const printRef = React.useRef<HTMLElement>();
  const [printing, setPrinting] = React.useState(false);

  const handleGeneratePdf = async () => {
    const element = printRef.current;
    if (!element) return;
    setPrinting(true);
    // const canvas = await html2canvas(element);
    // const data = canvas.toDataURL("image/png");

    // const pdf = new jsPDF();
    // const imgProperties = pdf.getImageProperties(data);
    // const pdfWidth = pdf.internal.pageSize.getWidth();
    // const pdfHeight = (imgProperties.height * pdfWidth) / imgProperties.width;

    // pdf.addImage(data, "PNG", 0, 0, pdfWidth, pdfHeight);
    // pdf.save(`${documentation.title}.pdf`);

    const doc = new jsPDF("p", "pt", "a4");
    doc.html(element, {
      callback: function (generatedDoc) {
        generatedDoc.save(`${documentation.title}.pdf`);
      },
      x: 0,
      y: 0,
      width: 600, // Maximum width of content on PDF
      windowWidth: 900,
    });
    setPrinting(false);
  };

  return (
    <Dialog open maxWidth="md" scroll="paper">
      <DialogTitle sx={{ py: 1 }}>
        <Box sx={{ display: "flex", alignItems: "center", opacity: 0.8 }}>
          <PrintIcon sx={{ mr: 1 }} />
          <Typography variant="h6" sx={{ flex: 1 }}>
            Print Documentation
          </Typography>
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent dividers sx={{ p: 0 }}>
        <Box p={4} ref={printRef}>
          <div style={{ display: "flex", alignItems: "center" }}>
            <div
              style={{
                borderRadius: "5px",
                background:
                  DocumentationSubjectToColor[documentation.subject as any],
                padding: "2px 8px",
              }}
            >
              {DocumentationSubjectToString[documentation.subject as any]}
            </div>
          </div>
          <Typography
            variant="h5"
            sx={{ mt: 2, fontWeight: "600", color: "#333", mb: 3 }}
          >
            {documentation.title}
          </Typography>
          {documentation.abstract ? (
            <FieldSection>
              <FieldLabel>Abstract</FieldLabel>
              <FieldContent>
                <div
                  style={{
                    color: "#444",
                    fontSize: "16px",
                  }}
                >
                  {documentation.abstract
                    ? parseHtml(documentation.abstract.replace(/\n/g, "<br/>"))
                    : ""}
                </div>
              </FieldContent>
            </FieldSection>
          ) : null}
          {documentation.materialMethods ? (
            <FieldSection>
              <FieldLabel>Material Methods</FieldLabel>
              <FieldContent>
                <div
                  style={{
                    color: "#444",
                    fontSize: "16px",
                  }}
                >
                  {documentation.materialMethods
                    ? parseHtml(
                        documentation.materialMethods.replace(/\n/g, "<br/>")
                      )
                    : ""}
                </div>
              </FieldContent>
            </FieldSection>
          ) : null}
          {documentation.result ? (
            <FieldSection>
              <FieldLabel>Results / Conclusions</FieldLabel>
              <FieldContent>
                <div
                  style={{
                    color: "#444",
                    fontSize: "16px",
                  }}
                >
                  {documentation.result
                    ? parseHtml(documentation.result.replace(/\n/g, "<br/>"))
                    : ""}
                </div>
              </FieldContent>
            </FieldSection>
          ) : null}
          <Grid container spacing={3}>
            {documentation.docUrls?.length ? (
              <Grid item xs={6}>
                <FieldSection>
                  <FieldLabel>Result Files</FieldLabel>
                  <FieldContent>
                    {documentation.docUrls.map((url, i) => (
                      <div key={i} style={{ marginBottom: "8px" }}>
                        <a
                          href={`${url}`}
                          style={{
                            letterSpacing: "-0.8px",
                            fontSize: "13px",
                            color: "#3c64ff",
                            textDecoration: "underline",
                          }}
                        >
                          {url}
                        </a>
                      </div>
                    ))}
                  </FieldContent>
                </FieldSection>
              </Grid>
            ) : null}
            {documentation.sampleGroups?.length ? (
              <Grid item xs={6}>
                <FieldSection>
                  <FieldLabel>Related Sample Groups</FieldLabel>
                  <FieldContent>
                    {documentation.sampleGroups.map((group, i) => (
                      <div key={i} style={{ marginBottom: "8px" }}>
                        <a
                          href={`${window.env.DATA_LAKE_APP_URL}/dashboard?sample-group-id=${group._id}`}
                          style={{
                            letterSpacing: "-0.8px",
                            fontSize: "13px",
                            color: "#3c64ff",
                            textDecoration: "underline",
                          }}
                        >
                          {`${window.env.DATA_LAKE_APP_URL}/dashboard?sample-group-id=${group._id}`}
                        </a>
                      </div>
                    ))}
                  </FieldContent>
                </FieldSection>
              </Grid>
            ) : null}
          </Grid>
        </Box>
      </DialogContent>
      <DialogActions sx={{ justifyContent: "center" }}>
        <Button
          variant="contained"
          disableElevation
          onClick={() => handleGeneratePdf()}
        >
          Export As PDF
        </Button>
      </DialogActions>
    </Dialog>
  );
}
