import { BrowserRouter, Route, Routes } from "react-router-dom";

import Layout from "../views/layout";
import Home from "../views/pages/home";
import Users from "../views/pages/users";
import Login from "../views/pages/auth/Login";
import Signup from "../views/pages/auth/Signup";
import { useAppContext } from "../contexts/useStorageContext";
import React from "react";
import ForgotPassword from "../views/pages/auth/forgot";
import Companies from "../views/pages/companies";
import SampleSource from "../views/pages/sampleSource";
import GasAnalysis from "../views/pages/gasAnalysis";
import DocumentationPage from "../views/pages/documentation";
import PredictionModelsPage from "../views/pages/PredictionModel";
import OnboardingGuidesPage from "../views/pages/OnboardingGuides";
import PrivacyPolicy from "../views/pages/terms-and-policy/privacyPolicy";
import TermsAndConditions from "../views/pages/terms-and-policy/termsAndConditions";

export default function Root() {
  const { state } = useAppContext();
  return (
    <BrowserRouter>
      {state.loggedInUserInfo?.email ? (
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route path="dashboard" element={<Home />} />
            <Route path="companies" element={<Companies />} />
            <Route path="sample-sources" element={<SampleSource />} />
            <Route path="gas-analysis" element={<GasAnalysis />} />
            <Route path="prediction-model" element={<PredictionModelsPage />} />
            <Route path="documentation" element={<DocumentationPage />} />
            <Route
              path="onboarding-guides"
              element={<OnboardingGuidesPage />}
            />
            <Route path="users" element={<Users />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />}></Route>
            <Route
              path="/terms-and-conditions"
              element={<TermsAndConditions />}
            ></Route>
          </Route>
        </Routes>
      ) : (
        <Routes>
          <Route path="/forgot-password" element={<ForgotPassword />}></Route>
          <Route path="/register" element={<Signup />}></Route>
          <Route path="*" element={<Login />}></Route>
        </Routes>
      )}
    </BrowserRouter>
  );
}
