import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";
import WhitePanel from "../../components/Common/WhitePanel";

export const Section = styled(Box)(() => ({
  marginBottom: "15px",
  marginTop: "30px",
}));

export const SectionTitle = styled(Box)(() => ({
  fontWeight: "500",
  fontSize: "20px",
  marginBottom: "12px",
}));
export const Content = styled(Box)(() => ({
  fontWeight: "300",
  marginBottom: "12px",
}));

export default function PrivacyPolicy() {
  return (
    <WhitePanel sx={{ padding: "24px 48px" }}>
      <Section>
        <SectionTitle sx={{ fontSize: "25px", marginBottom: "36px" }}>
          Privacy Policy
        </SectionTitle>
        <Content>
          This Privacy Policy describes how your personal information is
          collected, used, and shared when you visit Fermentrics.com (the
          “Site”).
        </Content>
      </Section>

      <Section>
        <SectionTitle>PERSONAL INFORMATION WE COLLECT</SectionTitle>
        <Content>
          When you visit the Site, we automatically collect certain information
          about your device, including information about your web browser, IP
          address, time zone, and some of the cookies that are installed on your
          device. Additionally, as you browse the Site, we collect information
          about the individual web pages or products that you view, what
          websites or search terms referred you to the Site, and information
          about how you interact with the Site. We refer to this
          automatically-collected information as “Device Information.”
        </Content>
      </Section>
      <Section>
        <SectionTitle>
          We collect Device Information using the following technologies:{" "}
        </SectionTitle>
        <Content>
          - “Cookies” are data files that are placed on your device or computer
          and often include an anonymous unique identifier. For more information
          about cookies, and how to disable cookies, visit
          http://www.allaboutcookies.org.
        </Content>
        <Content>
          - “Log files” track actions occurring on the Site, and collect data
          including your IP address, browser type, Internet service provider,
          referring/exit pages, and date/time stamps.
        </Content>
        <Content>
          - “Web beacons,” “tags,” and “pixels” are electronic files used to
          record information about how you browse the Site.
        </Content>
      </Section>
      <Section>
        <SectionTitle>HOW DO WE USE YOUR PERSONAL INFORMATION?</SectionTitle>
        <Content>Communicate with you;</Content>
        <Content>
          When in line with the preferences you have shared with us, provide you
          with information on our products or services.
        </Content>
        <Content>
          We use the Device Information that we collect to help us screen for
          potential risk and fraud (in particular, your IP address), and more
          generally to improve and optimize our Site (for example, by generating
          analytics about how our customers browse and interact with the Site,
          and to assess the success of our marketing and advertising campaigns).
        </Content>
      </Section>
      <Section>
        <SectionTitle>SHARING YOUR PERSONAL INFORMATION</SectionTitle>
        <Content>
          We share your Personal Information with third parties to help us use
          your Personal Information, as described above.
        </Content>
        <Content>
          We use Squarespace to power our website--you can read more about how
          Squarespace uses your Personal Information here:
          https://www.squarespace.com/privacy
        </Content>
        <Content>
          We use Google Analytics to help us understand how our customers use
          the Site--you can read more about how Google uses your Personal
          Information here: https://www.google.com/intl/en/policies/privacy/.
        </Content>
        <Content>
          You can also opt-out of Google Analytics here:
          https://tools.google.com/dlpage/gaoptout.
        </Content>
        <Content>
          Finally, we may also share your Personal Information to comply with
          applicable laws and regulations, to respond to a subpoena, search
          warrant or other lawful request for information we receive, or to
          otherwise protect our rights.
        </Content>
      </Section>
      <Section>
        <SectionTitle>DO NOT TRACK</SectionTitle>
        <Content>
          Please note that we do not alter our Site’s data collection and use
          practices when we see a Do Not Track signal from your browser.
        </Content>
      </Section>
      <Section>
        <SectionTitle>CHANGES</SectionTitle>
        <Content>
          We may update this privacy policy from time to time in order to
          reflect, for example, changes to our practices or for other
          operational, legal or regulatory reasons.
        </Content>
      </Section>
      <Section>
        <SectionTitle>CONTACT US</SectionTitle>
        <Content>
          For more information about our privacy practices, if you have
          questions, or if you would like to make a complaint, please contact us
          by e-mail at fermentricsatgmail.com or by mail using the details
          provided below:
        </Content>
        <Content>
          Suite 211, Kenwood Corporate Center., Arnprior, ON, K7S3W4, Canada
        </Content>
      </Section>
    </WhitePanel>
  );
}
