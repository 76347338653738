import * as React from "react";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import LinkIcon from "@mui/icons-material/Link";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import { styled } from "@mui/material/styles";
import VisibilityIcon from "@mui/icons-material/Visibility";
import Box from "@mui/material/Box";
import { fDateTime, fToNow } from "../../../helpers/formatTime";
import { IconButton, Tooltip } from "@mui/material";
import { ISampleRecordGroup } from "../../../types/sample";
import { showSnackbar } from "../../../service/snackbar.service";

const InfoTag = styled(Box)(() => ({
  background: "#dedede",
  padding: "2px 5px",
  borderRadius: "5px",
  marginRight: "5px",
  display: "flex",
  alignItems: "center",
}));

export default function ExportLogListItem({
  sampleGroup,
  onSelectItem,
  selectIcon,
  selectTooltipTitle,
}: // type,
{
  selectIcon?: JSX.Element;
  sampleGroup: ISampleRecordGroup;
  onSelectItem?: (group: ISampleRecordGroup) => void;
  selectTooltipTitle?: string;
  // type: SampleGroupType;
}) {
  return (
    <Box
      px={2}
      py={1}
      sx={{ borderTop: "solid 1px #e2e2e2", position: "relative" }}
    >
      <Box sx={{ fontWeight: "500", color: "#007ad0", marginBottom: "3px" }}>
        {sampleGroup.name}
      </Box>
      <Box
        className="ellipsis-line-2"
        sx={{ fontSize: "14px", opacity: "0.6", marginBottom: "6px" }}
      >
        {sampleGroup.description}
      </Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          fontSize: "12px",
          opacity: "0.8",
        }}
      >
        <InfoTag>{sampleGroup.uids?.length}</InfoTag>
        <Tooltip
          title={`${sampleGroup.actionBy?.role || ""} ${
            sampleGroup.company ? `/ ${sampleGroup.company?.name}` : ""
          }`}
          arrow
        >
          <InfoTag>
            <PersonOutlineIcon sx={{ fontSize: "14px", mr: "2px" }} />
            {sampleGroup.actionBy?.fullname}
          </InfoTag>
        </Tooltip>

        {sampleGroup.createdAt ? (
          <InfoTag
            sx={{
              "&:hover .from-now": { display: "none !important" },
              "&:hover .exact-date": { display: "inline !important" },
            }}
          >
            <AccessTimeIcon sx={{ fontSize: "14px", mr: "2px" }} />
            <div className="from-now">{fToNow(sampleGroup.createdAt)}</div>
            <div className="exact-date" style={{ display: "none" }}>
              {fDateTime(sampleGroup.createdAt)}
            </div>
          </InfoTag>
        ) : null}
        <Tooltip title="Copy group link" placement="top" arrow>
          <LinkIcon
            sx={{ cursor: "pointer", ml: 1 }}
            onClick={() => {
              navigator.clipboard.writeText(
                `${window.env.DATA_LAKE_APP_URL}/dashboard?sample-group-id=${sampleGroup._id}`
              );
              showSnackbar("success", "", "Group link is copied to clipboard");
            }}
          />
        </Tooltip>
      </Box>

      {onSelectItem ? (
        <Tooltip
          title={selectTooltipTitle || "View samples"}
          placement="right"
          arrow
        >
          <IconButton
            onClick={() => onSelectItem && onSelectItem(sampleGroup)}
            sx={{ position: "absolute", right: "4px", top: "15px" }}
          >
            {selectIcon || <VisibilityIcon />}
          </IconButton>
        </Tooltip>
      ) : null}
    </Box>
  );
}
