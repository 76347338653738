import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import RefreshIcon from "@mui/icons-material/Refresh";
import { CircularProgress } from "@mui/material";

export default function HighlightableRefreshButton({
  loading,
  highlighted,
  execRefresh,
}: {
  loading?: boolean;
  highlighted?: boolean;
  execRefresh: () => void;
}) {
  return (
    <Tooltip
      title={
        highlighted
          ? "Click this to get previous search result"
          : "Refresh content"
      }
    >
      <IconButton
        onClick={() => execRefresh()}
        size="small"
        sx={{
          transition: "0.3s",
          mr: 1,
          backgroundColor: highlighted ? "primary.main" : "",
          color: highlighted ? "white" : "",
          "&:hover": {
            backgroundColor: highlighted ? "primary.dark" : "",
            color: highlighted ? "white" : "",
          },
        }}
      >
        {loading ? <CircularProgress size={24} /> : <RefreshIcon />}
      </IconButton>
    </Tooltip>
  );
}
