import { Box, IconButton, MenuItem } from "@mui/material";
import * as React from "react";
import EditIcon from "@mui/icons-material/Edit";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import { InfoTag } from "../Common/ListItemInfoTag";
import { fDateTime, fToNow } from "../../../helpers/formatTime";
import { highLightWords } from "../../../helpers/utils";
import { IOnboardingGuide } from "../../../types/onboardingGuide";

export default function OnboardingGuideListItem({
  searchTerms,
  onboardingGuide,
  onClick,
  selected,
  onEdit,
}: {
  searchTerms?: string[];
  onboardingGuide: IOnboardingGuide;
  onClick?: () => void;
  selected?: boolean;
  onEdit?: () => void;
}) {
  return (
    <MenuItem
      onClick={onClick}
      sx={{
        p: 0,
        width: "100%",
        borderRight: selected ? "solid 3px #39bfb8" : undefined,
        position: "relative",
        "&:hover .edit-btn": {
          display: "flex",
        },
      }}
      selected={selected}
    >
      <Box
        py={1}
        px={1}
        sx={{ borderTop: "solid 1px #e2e2e2", position: "relative", flex: 1 }}
      >
        <Box sx={{ fontWeight: "500", color: "#007ad0", marginBottom: "3px" }}>
          {highLightWords(onboardingGuide.title || "", searchTerms || [])}
        </Box>
        <Box
          className="ellipsis-line-2"
          sx={{
            fontSize: "14px",
            opacity: "0.6",
            marginBottom: "6px",
          }}
        >
          {onboardingGuide.description}
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            fontSize: "12px",
            opacity: "0.8",
          }}
        >
          <InfoTag>
            <PersonOutlineIcon sx={{ fontSize: "14px", mr: "2px" }} />
            {onboardingGuide.actionBy?.fullname}
          </InfoTag>

          {onboardingGuide.createdAt ? (
            <InfoTag
              sx={{
                "&:hover .from-now": { display: "none !important" },
                "&:hover .exact-date": { display: "inline !important" },
              }}
            >
              <AccessTimeIcon sx={{ fontSize: "14px", mr: "2px" }} />
              <div className="from-now">
                {fToNow(onboardingGuide.createdAt)}
              </div>
              <div className="exact-date" style={{ display: "none" }}>
                {fDateTime(onboardingGuide.createdAt)}
              </div>
            </InfoTag>
          ) : null}
        </Box>
      </Box>
      <IconButton
        className="edit-btn"
        sx={{ position: "absolute", right: 5, top: 5, display: "none" }}
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          onEdit && onEdit();
        }}
      >
        <EditIcon />
      </IconButton>
    </MenuItem>
  );
}
