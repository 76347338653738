import * as React from "react";
import AnyAssetFileUploader, {
  AwsLocation,
} from "../Common/AnyAssetFileUploader";
import PredictionFetchFormDialog from "./PredictionFetchFormDialog";
import { ISampleRecordCreationPayload } from "../../../types/sample";
import UploadChemicalAnalysisDataDialog from "../UploadChemicalAnalysisDataDialog";
import { showSnackbar } from "../../../service/snackbar.service";
import TypeMismatchWarningDialog from "./TypeMismatchWarningDialog";

export default function UploadRawSpectraFileDialog({
  onClose,
}: {
  onClose: () => void;
}) {
  const [openUploadFileDialog, setOpenUploadFileDialog] = React.useState(true);
  const [rawSpectraFileUrl, setRawSpectraFileUrl] = React.useState("");
  const [openPredictionFormDialog, setOpenPredictionFormDialog] =
    React.useState(false);
  const [openUploadChemDialog, setOpenUploadChemDialog] = React.useState(false);
  const [parsedSampleRecords, setParsedSampleRecords] = React.useState<
    ISampleRecordCreationPayload[]
  >([]);
  const [typeMismatchedRecords, setTypeMismatchedRecords] = React.useState<
    ISampleRecordCreationPayload[]
  >([]);
  const [openTypeMismatchWarningDialog, setOpenTypeMismatchWarningDialog] =
    React.useState(false);

  const onFileUpload = (spectraFileUrl: string) => {
    setOpenUploadFileDialog(false);
    setRawSpectraFileUrl(
      spectraFileUrl ? spectraFileUrl.split(".com/")?.[1] : ""
    );
    setOpenPredictionFormDialog(true);
  };

  return (
    <>
      {openUploadFileDialog ? (
        <AnyAssetFileUploader
          location={AwsLocation.OrugenDatalake}
          directory={new Date().toISOString().substring(0, 7)}
          open={openUploadFileDialog}
          onClose={() => onClose()}
          onSave={(urls) => {
            if (urls?.[0]) {
              onFileUpload(urls[0]);
            }
          }}
          title="Upload Raw Spectra File"
        />
      ) : null}
      {openPredictionFormDialog && rawSpectraFileUrl ? (
        <PredictionFetchFormDialog
          rawSpectraFileUrl={rawSpectraFileUrl}
          onClose={() => onClose()}
          onSuccess={(parsed) => {
            if (!parsed?.length) {
              showSnackbar(
                "danger",
                "No samples found in the spectra file",
                "Please check the spectra file and try again."
              );
              return;
            }
            setParsedSampleRecords(parsed);
            if (parsed.some((s) => s.type != s.predictedType)) {
              setTypeMismatchedRecords(
                parsed.filter((s) => s.type != s.predictedType)
              );
              setOpenTypeMismatchWarningDialog(true);
            } else {
              setOpenUploadChemDialog(true);
            }
          }}
        />
      ) : null}
      {openUploadChemDialog && parsedSampleRecords?.length ? (
        <UploadChemicalAnalysisDataDialog
          onClose={() => setOpenUploadChemDialog(false)}
          initialItems={parsedSampleRecords}
          initialFileName={
            rawSpectraFileUrl?.split("/")?.[
              rawSpectraFileUrl.split("/").length - 1
            ]
          }
        />
      ) : null}
      {openTypeMismatchWarningDialog && typeMismatchedRecords?.length ? (
        <TypeMismatchWarningDialog
          isAll={parsedSampleRecords.length == typeMismatchedRecords.length}
          onClose={() => setOpenTypeMismatchWarningDialog(false)}
          records={typeMismatchedRecords}
          onExclude={() => {
            setOpenTypeMismatchWarningDialog(false);
            if (parsedSampleRecords.length == typeMismatchedRecords.length)
              return;
            setParsedSampleRecords(
              parsedSampleRecords.filter(
                (r) =>
                  !typeMismatchedRecords.some(
                    (t) => t.sampleNumber == r.sampleNumber
                  )
              )
            );
            setOpenUploadChemDialog(true);
          }}
          onIgnore={() => {
            setOpenTypeMismatchWarningDialog(false);
            setOpenUploadChemDialog(true);
          }}
        />
      ) : null}
    </>
  );
}
