import Box from "@mui/material/Box";
import { AnalysisSliderSection } from "../AnalysisConstants";

export default function AnalysisSlider({
  title,
  tickLabels,
  sliderWidth,
  rightSideLabel,
  sections,
  value,
  oldValue,
  compareLabel,
  compareLabelColor,
}: {
  title: string;
  tickLabels: string[];
  sliderWidth: number;
  rightSideLabel: string;
  compareLabel?: string;
  compareLabelColor?: string;
  sections: AnalysisSliderSection[];
  value: number;
  oldValue?: number;
}) {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "flex-end",
        pl: 1,
        pb: 1,
      }}
    >
      <Box>
        <Box
          sx={{
            fontSize: "14px",
            fontWeight: "500",
            display: "flex",
            justifyContent: "center",
            marginBottom:
              !tickLabels[1] &&
              !tickLabels[2] &&
              !tickLabels[3] &&
              !tickLabels[4] &&
              !tickLabels[5] &&
              !tickLabels[6]
                ? "5px"
                : "8px",
          }}
        >
          {title}
        </Box>
        <Box
          sx={{
            position: "relative",
            width: `${sliderWidth}px`,
            height: "8px",
          }}
        >
          <div
            style={{
              position: "absolute",
              width: "100%",
              height: "100%",
              left: "0px",
              top: "0px",
              borderRadius: "30px",
              overflow: "hidden",
            }}
          >
            <div
              style={{
                position: "absolute",
                width: "100%",
                height: "100%",
                left: "0px",
                top: "0px",
                borderRadius: "30px",
                overflow: "hidden",
              }}
            >
              {sections.map((section, i) => (
                <Box
                  key={i}
                  sx={{
                    position: "absolute",
                    height: "100%",
                    width: `${section.to - section.from}%`,
                    left: `${section.from}%`,
                    backgroundColor: section.color,
                    backgroundImage: section.gradient
                      ? `linear-gradient(to right, ${
                          section.gradientStartColor || "white"
                        } , ${section.color})`
                      : undefined,
                  }}
                />
              ))}
            </div>
          </div>
          <Box
            sx={{
              position: "absolute",
              width: "100%",
              height: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            {new Array(tickLabels.length).fill(undefined).map((el, i) => (
              <Box
                key={i}
                sx={{
                  width: "3px",
                  background: i == 0 || i == 6 ? "#0000" : "#ffffff8c",
                  height: "3px",
                  position: "relative",
                  borderRadius: "10px",
                }}
              >
                <Box
                  sx={{
                    position: "absolute",
                    top: "-21px",
                    fontSize: "13px",
                    left: "0%",
                    transform: "translate(-50%, 0%)",
                    color: "#777",
                  }}
                >
                  {tickLabels[i]}
                </Box>
              </Box>
            ))}
          </Box>
          {oldValue ? (
            <Box
              sx={{
                width: "24px",
                height: "24px",
                display: oldValue >= 0 ? "flex" : "none",
                alignItems: "center",
                justifyContent: "center",
                position: "absolute",
                border: "dotted 2px #606060",
                borderRadius: "100px",
                left: `${oldValue}%`,
                transform: "translate(-50%, -50%)",
                top: "50%",
              }}
            />
          ) : null}
          <Box
            sx={{
              display: value >= 0 ? "flex" : "none",
              alignItems: "center",
              justifyContent: "center",
              position: "absolute",
              border: "solid 2px #666",
              borderRadius: "100px",
              left: `${value}%`,
              transform: "translate(-50%, -50%)",
              top: "50%",
              boxShadow:
                "rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px",
            }}
          >
            <div
              style={{
                width: "8px",
                height: "8px",
                borderRadius: "30px",
                background: "#0000",
                border: "solid 6px #fff",
              }}
            />
          </Box>
        </Box>
      </Box>
      <Box sx={{ mb: "-2px", ml: "16px", fontSize: "13px", fontWeight: "500" }}>
        {rightSideLabel}
        {compareLabel && (
          <Box sx={{ color: compareLabelColor || "#000" }}>{compareLabel}</Box>
        )}
      </Box>
    </Box>
  );
}
