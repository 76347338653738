import * as React from "react";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import LinkIcon from "@mui/icons-material/Link";
import { styled } from "@mui/material/styles";
import { IUploadDownloadLogPopulated } from "../../../types/exportLog";
import Box from "@mui/material/Box";
import { fDateTime, fToNow } from "../../../helpers/formatTime";
import { Tooltip } from "@mui/material";
import { ISampleRecordGroup, SampleGroupType } from "../../../types/sample";
import { showSnackbar } from "../../../service/snackbar.service";
import { ICompany } from "../../../types/company";

const InfoTag = styled(Box)(() => ({
  background: "#dedede",
  padding: "2px 5px",
  borderRadius: "5px",
  marginRight: "5px",
  display: "flex",
  alignItems: "center",
}));

export default function ExportLogListItem({
  exportLog,
  onClickViewSamples,
  type,
  disableBorder,
  hideDataType,
  hidePredictionModelUsed,
  clientCompany,
}: {
  exportLog: IUploadDownloadLogPopulated;
  onClickViewSamples?: (group: ISampleRecordGroup) => void;
  type: SampleGroupType;
  disableBorder?: boolean;
  hideDataType?: boolean;
  hidePredictionModelUsed?: boolean;
  clientCompany?: ICompany;
}) {
  return (
    <Box
      px={2}
      py={1}
      sx={{
        borderTop: disableBorder ? "none" : "solid 1px #e2e2e2",
        position: "relative",
      }}
    >
      <div
        style={{ display: "flex", alignItems: "center", marginBottom: "6px" }}
      >
        {!hideDataType && exportLog.includeChemicals ? (
          <Box
            sx={{
              display: "inline-flex",
              background: "#ddceee",
              fontSize: "13px",
              padding: "0px 5px",
              borderRadius: "3px",
            }}
          >
            Chem
          </Box>
        ) : !hideDataType && exportLog.includeSpectra ? (
          <Box
            sx={{
              display: "inline-flex",
              background: "#ddceee",
              fontSize: "13px",
              padding: "0px 5px",
              borderRadius: "3px",
            }}
          >
            Spectra
          </Box>
        ) : !hideDataType && exportLog.isRawSpectra ? (
          <Box
            sx={{
              display: "inline-flex",
              background: "#ddceee",
              fontSize: "13px",
              padding: "0px 5px",
              borderRadius: "3px",
            }}
          >
            Raw Spectra
          </Box>
        ) : null}
        {!hidePredictionModelUsed && exportLog.predictionModelUsed && (
          <Box
            sx={{
              display: "inline-flex",
              background: "#ebf0bd",
              fontSize: "13px",
              padding: "0px 5px",
              borderRadius: "3px",
              ml: 1,
            }}
          >
            Prediction Model Used
          </Box>
        )}
        {clientCompany ? (
          <Box
            sx={{
              display: "inline-flex",
              background: "#bdf0c2",
              fontSize: "13px",
              padding: "0px 5px",
              borderRadius: "3px",
              ml: 1,
            }}
          >
            {`For Client: ${clientCompany.name}`}
          </Box>
        ) : null}
      </div>
      <Tooltip
        title="View sample data in table"
        placement="top"
        arrow
        followCursor
      >
        <Box
          sx={{
            fontWeight: "500",
            color: "#007ad0",
            marginBottom: "3px",
            cursor: "pointer",
            "&:hover": { textDecoration: "underline" },
          }}
          onClick={() =>
            exportLog.uids?.length &&
            onClickViewSamples &&
            onClickViewSamples({
              _id: exportLog._id,
              name: exportLog.fileName,
              uids: exportLog.uids || [],
              type: type,
            })
          }
        >
          {exportLog.fileName}
        </Box>
      </Tooltip>
      <Box
        className="ellipsis-line-2"
        sx={{ fontSize: "14px", opacity: "0.6", marginBottom: "6px" }}
      >
        {exportLog.description}
      </Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          fontSize: "12px",
          opacity: "0.8",
        }}
      >
        <InfoTag>{`${exportLog.startSSID} ~ ${exportLog.endSSID}`}</InfoTag>
        <InfoTag>{exportLog.numberOfRecords} samples</InfoTag>
        <Tooltip
          title={`${exportLog.actionBy?.role || ""} ${
            exportLog.actionBy?.company
              ? `/ ${exportLog.actionBy?.company?.name}`
              : ""
          }`}
          arrow
        >
          <InfoTag>
            <PersonOutlineIcon sx={{ fontSize: "14px", mr: "2px" }} />
            {exportLog.actionBy?.fullname}
          </InfoTag>
        </Tooltip>
        <InfoTag
          sx={{
            "&:hover .from-now": { display: "none !important" },
            "&:hover .exact-date": { display: "inline !important" },
          }}
        >
          <AccessTimeIcon sx={{ fontSize: "14px", mr: "2px" }} />
          <div className="from-now">{fToNow(exportLog.createdAt)}</div>
          <div className="exact-date" style={{ display: "none" }}>
            {fDateTime(exportLog.createdAt)}
          </div>
        </InfoTag>
        <Tooltip title="Copy link" placement="top" arrow>
          <LinkIcon
            sx={{ cursor: "pointer", ml: 1 }}
            onClick={() => {
              navigator.clipboard.writeText(
                `${window.env.DATA_LAKE_APP_URL}/dashboard?${
                  type == SampleGroupType.DownloadLog
                    ? "download-log-id"
                    : "upload-log-id"
                }=${exportLog._id}`
              );
              showSnackbar("success", "", "Group link is copied to clipboard");
            }}
          />
        </Tooltip>
      </Box>
      {/* {exportLog.uids?.length ? (
        <Tooltip title="View in table" placement="right" arrow>
          <IconButton
            onClick={() =>
              onClickViewSamples &&
              onClickViewSamples({
                _id: exportLog._id,
                name: exportLog.fileName,
                uids: exportLog.uids || [],
                type: type,
              })
            }
            sx={{ position: "absolute", right: "4px", top: "15px" }}
          >
            <VisibilityIcon />
          </IconButton>
        </Tooltip>
      ) : null} */}
    </Box>
  );
}
