/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as React from "react";
import { Box, Typography } from "@mui/material";
import { PickersShortcutsItem } from "@mui/x-date-pickers/PickersShortcuts";
import { DateRange } from "@mui/x-date-pickers-pro";
import dayjs, { Dayjs } from "dayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateRangePicker } from "@mui/x-date-pickers-pro/DateRangePicker";
import { SingleInputDateRangeField } from "@mui/x-date-pickers-pro/SingleInputDateRangeField";
import { PaginationParams } from "../../../../types/tables";
import {
  fDateTime,
  getIntakeRecordDateString,
} from "../../../../helpers/formatTime";
import {
  defaultPaginationParams,
  DefaultRowsPerPageOptions,
} from "../../../../helpers/defaults/pagination";
import { DataGridPro, GridColDef } from "@mui/x-data-grid-pro";
import { ISampleRecordPopulated } from "../../../../types/sample";
import sampleRecordService from "../../../../service/sampleRecord.service";

const shortcutsItems: PickersShortcutsItem<DateRange<Dayjs>>[] = [
  {
    label: "Current Month",
    getValue: () => {
      const today = dayjs();
      return [today.startOf("month"), today.endOf("month")];
    },
  },
  {
    label: "Last Month",
    getValue: () => {
      const currentDate = new Date();

      // Set the date to the 1st day of the current month
      const firstDayOfCurrentMonth = new Date(
        currentDate.getFullYear(),
        currentDate.getMonth(),
        1
      );

      // Subtract 1 day from the 1st day of the current month to get the last day of the previous month
      const lastDayOfPreviousMonth = new Date(firstDayOfCurrentMonth);
      lastDayOfPreviousMonth.setDate(firstDayOfCurrentMonth.getDate() - 1);

      // Set the date to the 1st day of the previous month
      const firstDayOfPreviousMonth = new Date(lastDayOfPreviousMonth);
      firstDayOfPreviousMonth.setDate(1);
      return [dayjs(firstDayOfPreviousMonth), dayjs(lastDayOfPreviousMonth)];
    },
  },
  {
    label: "Last 100 Days",
    getValue: () => {
      const today = dayjs();
      return [today.subtract(100, "day"), today];
    },
  },
  {
    label: "This Year",
    getValue: () => {
      const today = dayjs();
      return [today.startOf("year"), today.endOf("year")];
    },
  },
  {
    label: "Last 365 Days",
    getValue: () => {
      const today = dayjs();
      return [today.subtract(365, "day"), today];
    },
  },
];

export default function ReportUsage({ companyId }: { companyId: string }) {
  const [loading, setLoading] = React.useState(true);
  const [uploadLogs, setUploadLogs] = React.useState<ISampleRecordPopulated[]>(
    []
  );
  const [paginationParams, setPaginationParams] =
    React.useState<PaginationParams>({
      ...defaultPaginationParams,
      pageSize: 25,
    });
  const [totalCount, setTotalCount] = React.useState(0);
  const [fromDate, setFromDate] = React.useState<Date>(
    dayjs().startOf("month").toDate()
  );
  const [toDate, setToDate] = React.useState<Date>(new Date());

  const columns: GridColDef[] = [
    {
      field: "sampleNumber",
      headerName: "Sample #",
      flex: 1,
    },
    {
      field: "reportMadeAt",
      headerName: "Report Opened At",
      flex: 1,
      valueGetter: (params) => {
        return fDateTime(params.row.reportMadeAt);
      },
    },
  ];

  const fetchData = async (pagination?: PaginationParams) => {
    setLoading(true);
    try {
      const currentPaginationParams = pagination || paginationParams;
      setLoading(true);
      const { data, count } = await sampleRecordService.getReportMadeSamples({
        page: currentPaginationParams.page || 0,
        pageSize: currentPaginationParams.pageSize || 25,
        companyId,
        fromDate,
        toDate,
      });
      setUploadLogs(data);
      setTotalCount(count);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  React.useEffect(() => {
    fetchData();
  }, [fromDate, toDate]);

  return (
    <Box>
      <Typography variant="h6" mb={1}>
        Reports Made
      </Typography>
      <Box mb={2}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DateRangePicker
            slots={{ field: SingleInputDateRangeField }}
            slotProps={{
              textField: { size: "small", fullWidth: true },
              shortcuts: {
                items: shortcutsItems,
              },
            }}
            value={[dayjs(fromDate), dayjs(toDate)]}
            onChange={(val) => {
              if (val[0]) {
                setFromDate(val[0]?.toDate());
              }
              if (val[1]) {
                setToDate(val[1]?.toDate());
              }
            }}
          />
        </LocalizationProvider>
      </Box>
      <Box
        sx={{
          height: "500px",
          maxWidth: uploadLogs?.length ? undefined : "400px",
          "& .MuiDataGrid-renderingZone": {
            maxHeight: "none !important",
          },
          "& .MuiDataGrid-cell": {
            lineHeight: "unset !important",
            maxHeight: "none !important",
            whiteSpace: "normal",
            padding: "0px !important",
          },
          "& .MuiDataGrid-row": {
            maxHeight: "none !important",
          },
        }}
      >
        <DataGridPro
          rows={uploadLogs}
          columns={columns}
          loading={loading}
          rowHeight={35}
          pagination
          paginationMode="server"
          paginationModel={{
            page: paginationParams.page || 0,
            pageSize: paginationParams.pageSize || 25,
          }}
          pageSizeOptions={
            paginationParams && paginationParams.rowsPerPageOptions
              ? paginationParams.rowsPerPageOptions
              : DefaultRowsPerPageOptions
          }
          onPaginationModelChange={(changedModel) => {
            const newPaginationParams = { ...paginationParams };
            newPaginationParams.page = changedModel.page;
            newPaginationParams.pageSize = changedModel.pageSize;
            fetchData(newPaginationParams);
            setPaginationParams(newPaginationParams);
          }}
          rowCount={totalCount}
          disableRowSelectionOnClick
        />
      </Box>
    </Box>
  );
}
