import React from "react";
import { IComment } from "../../../types/comment";
import { IDocumentation } from "../../../types/documentation";

export interface IDocumentationsPageContext {
  currentOpenedDocumentation?: IDocumentation;
  currentDocumentationComments: IComment[];
  unseenCommentIds: string[];
  markCommentSeen?: (commentId: string) => void;
}

export const DocumentationsPageContext =
  React.createContext<IDocumentationsPageContext>({
    currentDocumentationComments: [],
    unseenCommentIds: [],
  });
