/* eslint-disable @typescript-eslint/no-explicit-any */
import backendApi from "./backendApi";
import { showSnackbar } from "./snackbar.service";

const routePrefix = "datalake/prediction-ai-model";

export function getDateString(dateParam?: Date) {
  const date = dateParam || new Date();
  const year = date.getFullYear();

  let month = (1 + date.getMonth()).toString();
  month = month.length > 1 ? month : "0" + month;

  let day = date.getDate().toString();
  day = day.length > 1 ? day : "0" + day;

  return year + "-" + month + "-" + day;
}

export default {
  async createPredictionModel({
    date,
    product,
    title,
    description,
    link,
  }: {
    date: string;
    product?: string;
    title?: string;
    description?: string;
    link?: string;
  }): Promise<any> {
    try {
      const result = await backendApi()?.post(`${routePrefix}/create`, {
        date,
        product,
        title,
        description,
        link,
      });
      showSnackbar(
        "success",
        "Success",
        result?.data.message || "New prediction model successfully created"
      );
      return result.data;
    } catch (error: any) {
      if (error?.response?.status) {
        showSnackbar(
          "danger",
          "Error",
          error?.response?.data?.message || "API error response"
        );
        return null;
      }
    }
  },
  async updatePredictionModel({
    id,
    title,
    description,
    link,
    product,
    files,
  }: {
    id: string;
    title?: string;
    description?: string;
    link?: string;
    product?: string;
    files?: string[];
  }): Promise<any> {
    try {
      const result = await backendApi()?.post(`${routePrefix}/update/${id}`, {
        title,
        description,
        link,
        product,
        files,
      });
      showSnackbar(
        "success",
        "Success",
        result?.data.message || "Prediction model successfully updated"
      );
      return result.data;
    } catch (error: any) {
      if (error?.response?.status) {
        showSnackbar(
          "danger",
          "Error",
          error?.response?.data?.message || "API error response"
        );
        return null;
      }
    }
  },
  async getAllPredictionModels(): Promise<any> {
    try {
      const result = await backendApi()?.get(`${routePrefix}`);
      return result.data;
    } catch (error: any) {
      if (error?.response?.status) {
        showSnackbar(
          "danger",
          "Error",
          error?.response?.data?.message || "API error response"
        );
        return null;
      }
    }
  },
  async getModelByDateAndProduct(date: string, product: string): Promise<any> {
    try {
      const result = await backendApi()?.post(
        `${routePrefix}/get-by-date-and-product`,
        {
          date,
          product,
        }
      );
      return result.data;
    } catch (error: any) {
      if (error?.response?.status) {
        showSnackbar(
          "danger",
          "Error",
          error?.response?.data?.message || "API error response"
        );
        return null;
      }
    }
  },
};
