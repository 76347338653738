/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import * as React from "react";
import { Box, Button, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { IComment } from "../../../../types/comment";
import useWindowHeight from "../../../../hooks/useWindowHeight";
import CommentEditForm from "./CommentEditForm";
import { useAppContext } from "../../../../contexts/useStorageContext";
import { AutoResizeTextField } from "../AutoResizeTextField";
import CommentItem from "./CommentItem";

export default function CommentsPanel({
  comments,
  onUpdateComment,
  onAddComment,
  onClosePanel,
  resourceId,
}: {
  comments: IComment[];
  onUpdateComment: (updated: IComment) => Promise<void>;
  onAddComment: (newComment: IComment) => Promise<void>;
  onClosePanel: () => void;
  resourceId: string;
}) {
  const { state } = useAppContext();
  const { loggedInUserInfo } = state;
  const windowHeight = useWindowHeight();
  const [openNewCommentForm, setOpenNewCommentForm] = React.useState(false);

  const handleAddNewComment = async (newComment: IComment) => {
    await onAddComment(newComment);
    setOpenNewCommentForm(false);
  };

  return (
    <Box sx={{ position: "relative" }}>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          borderBottom: "solid 1px #ddd",
        }}
      >
        <Box sx={{ fontSize: "18px", fontWeight: "500" }}>Comments</Box>
        <Box
          sx={{ fontSize: "18px", fontWeight: "500", opacity: 0.6, ml: 1 }}
        >{`(${comments.length})`}</Box>
        <div style={{ flex: 1 }} />
        <IconButton onClick={() => onClosePanel()}>
          <CloseIcon />
        </IconButton>
      </Box>
      <Box
        sx={{
          height: `${windowHeight - 56 - 48 - 32 - 80}px`,
          overflow: "auto",
          background: "#fefefe",
          paddingBottom: "100px",
          boxSizing: "border-box",
        }}
      >
        {comments
          .filter((c) => !c.parentCommentId)
          .map((comment) => (
            <CommentItem
              key={comment._id}
              comment={comment}
              allComments={comments}
              onAddReply={(newComment) => handleAddNewComment(newComment)}
              onUpdateComment={onUpdateComment}
            />
          ))}
      </Box>
      <Box
        sx={{
          display: "flex",
          position: "absolute",
          bottom: "0px",
          width: "100%",
        }}
      >
        {openNewCommentForm && loggedInUserInfo ? (
          <Box
            sx={{
              maxWidth: "600px",
              background: "white",
              position: "absolute",
              bottom: "0px",
              left: "0px",
              width: "100%",
              border: "solid 1px #ddd",
              borderRadius: "3px",
              boxShadow:
                "rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;",
              marginLeft: "-5px",
            }}
          >
            <CommentEditForm
              comment={{
                content: "",
                actionBy: loggedInUserInfo,
                resourceId: resourceId,
              }}
              onCancel={() => setOpenNewCommentForm(false)}
              onCreate={(newComment) => handleAddNewComment(newComment)}
            />
          </Box>
        ) : (
          <Button
            onClick={() => setOpenNewCommentForm(true)}
            fullWidth
            variant="contained"
            sx={{ borderRadius: "100px" }}
            disableElevation
          >
            Add New Comment
          </Button>
        )}
      </Box>
    </Box>
  );
}
