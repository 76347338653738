import * as React from "react";
import { Box, CircularProgress, InputAdornment, Stack } from "@mui/material";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import PasswordIcon from "@mui/icons-material/Password";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import TextField from "@mui/material/TextField";
import { useNavigate } from "react-router-dom";
import AuthContainer from "./AuthContainer";
import ProgressButton from "../../components/Common/ProgressButton";
import authService from "../../../service/auth.service";
import { useAppContext } from "../../../contexts/useStorageContext";
import { actionTypes } from "../../../types/state";

export default function Login() {
  const navigate = useNavigate();
  const { dispatch } = useAppContext();
  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [passwordShown, setPasswordShown] = React.useState(false);
  const [showValidation, setShowValidation] = React.useState(false);
  const [processing, setProcessing] = React.useState(false);
  const [loading, setLoading] = React.useState(true);

  const togglePasswordVisiblity = () => {
    setPasswordShown(passwordShown ? false : true);
  };

  const validateForm = () => {
    return !!(email && password);
  };

  const handleLogin = async () => {
    if (validateForm()) {
      setProcessing(true);
      const result = await authService.login(email, password);
      if (result) {
        dispatch!({
          type: actionTypes.setLoggedInUserInfo,
          value: result.data,
        });
        navigate("/dashboard");
      }
      setProcessing(false);
    } else {
      setShowValidation(true);
    }
  };

  React.useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 300);
  }, []);

  return loading ? (
    <Box
      sx={{
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <CircularProgress
        variant="indeterminate"
        disableShrink
        sx={{
          animationDuration: "550ms",
        }}
        size={40}
      />
    </Box>
  ) : (
    <AuthContainer>
      <Stack spacing={2}>
        <TextField
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          error={showValidation && !email}
          type="email"
          placeholder="Email"
          fullWidth
          size="small"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <MailOutlineIcon />
              </InputAdornment>
            ),
          }}
          onKeyDown={(e) =>
            e.key?.toLowerCase() == "enter" ? handleLogin() : undefined
          }
        />
        <TextField
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          error={showValidation && !password}
          type={passwordShown ? "text" : "password"}
          placeholder="Password"
          fullWidth
          size="small"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <PasswordIcon />
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment
                position="end"
                onClick={togglePasswordVisiblity}
                sx={{ cursor: "pointer" }}
              >
                {!passwordShown ? <VisibilityIcon /> : <VisibilityOffIcon />}
              </InputAdornment>
            ),
          }}
          onKeyDown={(e) =>
            e.key?.toLowerCase() == "enter" ? handleLogin() : undefined
          }
        />
        <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
          <Box
            onClick={() => {
              navigate("/forgot-password");
            }}
            sx={{
              fontStyle: "italic",
              ml: 1,
              fontSize: "14px",
              textDecoration: "underline",
              cursor: "pointer",
              "&:hover": { fontWeight: "500", color: "primary.main" },
            }}
          >
            Forgot password
          </Box>
        </Box>
        <ProgressButton
          inProgress={processing}
          title="Login"
          onClick={() => !processing && handleLogin()}
          disableElevation
        />
        <Box
          sx={{
            fontSize: "15px",
            display: "flex",
            alignItems: "center",
            flexWrap: "wrap",
          }}
        >
          Don't have account yet?
          <Box
            onClick={() => {
              navigate("/register");
            }}
            sx={{
              color: "primary.main",
              ml: 1,
              fontSize: "15px",
              textDecoration: "underline",
              cursor: "pointer",
              "&:hover": { fontWeight: "500" },
            }}
          >
            Create account
          </Box>
        </Box>
      </Stack>
    </AuthContainer>
  );
}
