/* eslint-disable @typescript-eslint/no-explicit-any */
import Box from "@mui/material/Box";
import Popover from "@mui/material/Popover";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import * as React from "react";
import { TextField, Autocomplete } from "@mui/material";
import { ChemicalFields } from "../../../types/chemicals";

export interface IChemicalValueFilterItem {
  field: string;
  min?: number;
  max?: number;
}

export default function ChemicalValueFilter({
  currentFilters,
  onSubmit,
  triggerElement,
}: {
  currentFilters: IChemicalValueFilterItem[];
  onSubmit: (newFilter: IChemicalValueFilterItem) => void;
  triggerElement: JSX.Element;
}) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [selectedFieldName, setSelectedFieldName] = React.useState<string>("");
  const [filterMinValue, setFilterMinValue] = React.useState<number>();
  const [filterMaxValue, setFilterMaxValue] = React.useState<number>();

  const handleClick = (event: any) => {
    setFilterMinValue(undefined);
    setFilterMaxValue(undefined);
    setSelectedFieldName("");
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <Tooltip title="Add Filter" arrow placement="top">
        <Box
          onClick={handleClick}
          aria-describedby={id}
          sx={{
            cursor: "pointer",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {triggerElement}
        </Box>
      </Tooltip>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <Box px={2} py={2} sx={{ width: "400px" }}>
          <Stack spacing={2}>
            <Stack
              sx={{ display: "flex", alignItems: "center" }}
              direction={"row"}
              spacing={1}
            >
              <Autocomplete
                fullWidth
                value={selectedFieldName ?? ""}
                onChange={(e, val) => {
                  setSelectedFieldName(val || "");
                }}
                getOptionDisabled={(option) =>
                  currentFilters?.map((f) => f.field)?.includes(option)
                }
                options={ChemicalFields.map((f) => f.fieldName)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder="Field name"
                    size="small"
                    sx={{ flex: 1 }}
                  />
                )}
              />
            </Stack>
            {
              <Stack
                sx={{ display: "flex", alignItems: "center" }}
                direction={"row"}
                spacing={1}
              >
                <TextField
                  placeholder="Minimum"
                  type="number"
                  size="small"
                  value={filterMinValue || undefined}
                  sx={{ minWidth: "102px" }}
                  onChange={(e) => {
                    setFilterMinValue(
                      e.target.value ? Number(e.target.value) : undefined
                    );
                  }}
                />
                <TextField
                  placeholder="Maximum"
                  type="number"
                  size="small"
                  value={filterMaxValue || undefined}
                  sx={{ minWidth: "102px" }}
                  onChange={(e) => {
                    setFilterMaxValue(
                      e.target.value ? Number(e.target.value) : undefined
                    );
                  }}
                />
              </Stack>
            }
            <Button
              onClick={() => {
                onSubmit &&
                  onSubmit({
                    field: selectedFieldName,
                    min: filterMinValue,
                    max: filterMaxValue,
                  });
                setSelectedFieldName("");
                handleClose();
              }}
              size="small"
              fullWidth
              variant="contained"
              disabled={
                !selectedFieldName || (!filterMinValue && !filterMaxValue)
              }
            >
              Add Filter
            </Button>
          </Stack>
        </Box>
      </Popover>
    </Box>
  );
}
