const Styles = {
  light: {
    // palette values for light mode
    primary: {
      light: "#29736f",
      main: "#29736f",
      dark: "#125a56",
    },
    success: {
      light: "#0fa510",
      main: "#0fa510",
      dark: "#0fa510",
    },
    warning: {
      light: "#d37501",
      main: "#d37501",
      dark: "#d35401",
    },
    secondary: {
      light: "#e75d16",
      main: "#e75d16",
      dark: "#1566ab",
    },
    error: {
      light: "#df1c1c",
      main: "#df1c1c",
      dark: "#df1c1c",
    },
    default: {
      light: "#7CFC00",
      main: "#1976d2",
      dark: "#125a56",
    },
    background: {
      underTabContainer: "#f57a14",
      default: "#dedede",
      toolbar: "#fff",
      card: "#000",
    },
    text: {
      primary: "#282828",
      secondary: "#6c6c6c",
    },
    button: {
      black: "#444",
    },
    navbar: {
      default: "#fff",
    },
    card: {
      default: "#fff",
      borderColor: "#0000",
      borderWidth: "0px",
    },
    scrollTopButton: {
      background: "#fff",
      border: "#20aceb",
      icon: "#20aceb",
    },
  },
  dark: {
    // palette values for dark mode
    primary: {
      light: "#D3D3D3",
      main: "#20aceb",
      dark: "#282828",
    },
    default: {
      light: "#D3D3D3",
      main: "#696969",
      dark: "#282828",
    },
    background: {
      default: "#dedede",
      card: "#000",
    },
    text: {
      primary: "#e2e8f0",
      secondary: "#94a3b8",
    },
    navbar: {
      default: "#1e293b",
    },
    card: {
      default: "#0e1828",
      borderColor: "#353f4f",
      borderWidth: "1px",
    },
    scrollTopButton: {
      background: "#0e1828",
      border: "#fff",
      icon: "#fff",
    },
  },
};

export default Styles;
