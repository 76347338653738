import * as React from "react";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import FilePresentIcon from "@mui/icons-material/FilePresent";
import Paper from "@mui/material/Paper";
import CloseIcon from "@mui/icons-material/Close";
import { styled } from "@mui/material/styles";
import { getFileNameFromAssetUrl } from "../../../helpers/utils";
import assetsService from "../../../service/assets.service";
import { CircularProgress } from "@mui/material";

export const AddFileBox = styled(Box)(() => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  background: "#eee",
  border: "dashed 2px #bbb",
  borderRadius: "5px",
  height: "50px",
  cursor: "pointer",
  "&:hover": {
    background: "#ddd",
  },
}));

export default function AttachedFileItem({
  url,
  onRemove,
  mb,
  mr,
  inline,
}: {
  url: string;
  onRemove?: () => void;
  mb?: number;
  mr?: number;
  inline?: boolean;
}) {
  const [downloadingFile, setDownloadingFile] = React.useState(false);
  const downloadFile = async () => {
    setDownloadingFile(true);
    await assetsService.downloadFile({ fileName: url });
    setDownloadingFile(false);
  };
  const isPrivateFile = !url.includes("//intaikassets.s3.");
  return (
    <Paper
      sx={{
        pt: "4px",
        display: inline ? "inline-flex" : "flex",
        alignItems: "center",
        flex: 1,
        pl: "8px",
        pr: 2,
        border: "solid 1px #eee",
        mb: mb ? `${mb}px` : undefined,
        mr: mr ? `${mr}px` : undefined,
        minHeight: "33px",
      }}
    >
      <Box mr={2}>
        {downloadingFile ? (
          <CircularProgress size={16} />
        ) : (
          <FilePresentIcon sx={{ fontSize: "25px", opacity: "0.6" }} />
        )}
      </Box>
      <Box
        sx={{
          flex: 1,
          mb: "5px",
          wordWrap: "break-word",
          overflow: "hidden",
          "&:hover": {
            color: "primary.main",
            textDecoration: "underline",
          },
        }}
        className="ellipsis-line-1"
      >
        {isPrivateFile ? (
          <Box
            sx={{
              cursor: "pointer",
            }}
            onClick={() => downloadFile()}
          >
            {getFileNameFromAssetUrl(url)}
          </Box>
        ) : (
          <a href={url} target="_blank">
            {getFileNameFromAssetUrl(url)}
          </a>
        )}
      </Box>
      {onRemove ? (
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <IconButton onClick={() => onRemove()}>
            <CloseIcon />
          </IconButton>
        </Box>
      ) : null}
    </Paper>
  );
}
