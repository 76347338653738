/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as React from "react";
import GeneralPopoverWrapper from "../Common/GeneralPopoverWrapper";
import Box from "@mui/material/Box";
import { IPermissionStatus, SpecialPermissions } from "../../../types/user";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";

export default function SpecialPermissionPopover({
  currentPermissions,
  onChangePermissions,
  triggerElement,
}: {
  currentPermissions: IPermissionStatus[];
  onChangePermissions: (changed: IPermissionStatus[]) => void;
  triggerElement: JSX.Element;
}) {
  return (
    <GeneralPopoverWrapper
      anchorHorizontal="left"
      transformHorizontal="left"
      triggerElement={triggerElement}
      popoverContent={
        <Box sx={{ minWidth: "350px", p: 2 }}>
          {Object.values(SpecialPermissions).map((p) => {
            const currentPermission = currentPermissions.find(
              (cp) => cp.permissionName == p
            );
            return (
              <Box sx={{ borderBottom: "solid 1px #ddd", py: 1 }}>
                <div>{p}</div>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        onChange={(e, checked) => {
                          if (!checked) {
                            onChangePermissions(
                              currentPermissions.filter(
                                (cp) => cp.permissionName != p
                              )
                            );
                          } else {
                            onChangePermissions([
                              ...currentPermissions.filter(
                                (cp) => cp.permissionName != p
                              ),
                              { permissionName: p, read: true },
                            ]);
                          }
                        }}
                        checked={currentPermission?.read || false}
                      />
                    }
                    label="Read"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        onChange={(e, checked) => {
                          onChangePermissions([
                            ...currentPermissions.filter(
                              (cp) => cp.permissionName != p
                            ),
                            { permissionName: p, read: true, write: checked },
                          ]);
                        }}
                        checked={currentPermission?.write || false}
                      />
                    }
                    label="Write"
                  />
                </div>
              </Box>
            );
          })}
        </Box>
      }
      tooltipTitle="Edit Special Permission"
    />
  );
}
