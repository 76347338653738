import * as React from "react";
import Box from "@mui/material/Box";
import ClosableDialog from "../Common/ClosableDialog";
import Stack from "@mui/material/Stack";
import { Button } from "@mui/material";
import { ISampleRecordPopulated } from "../../../types/sample";
import { ChemicalFields } from "../../../types/chemicals";
import sampleRecordService from "../../../service/sampleRecord.service";

export default function BatchReportDialog({
  samples,
  onComplete,
  onClose,
}: {
  samples: ISampleRecordPopulated[];
  onComplete: (goodSamples: ISampleRecordPopulated[]) => void;
  onClose: () => void;
}) {
  const [loading, setLoading] = React.useState(false);
  const gasRequiredFieldNames = ChemicalFields.filter(
    (f) => f.requiredForGasReport
  ).map((f) => f.fieldName);
  const goodSamples = samples.filter(
    (s) =>
      !gasRequiredFieldNames.some(
        (requiredFieldName) => !s.chemicals?.[requiredFieldName]
      )
  );
  const badSamples = samples.filter((s) =>
    gasRequiredFieldNames.some(
      (requiredFieldName) => !s.chemicals?.[requiredFieldName]
    )
  );

  const handleComplete = async () => {
    setLoading(true);
    await sampleRecordService.markAsReportMade(goodSamples.map((s) => s.uid));
    onComplete(goodSamples);
    setLoading(false);
    onClose();
  };
  return (
    <ClosableDialog
      onClose={onClose}
      open
      maxWidth="xs"
      titleElement={
        <div style={{ display: "flex", alignItems: "center" }}>
          Batch Report
          <Box
            sx={{ ml: 1, color: "warning.main" }}
          >{`(${samples.length} samples)`}</Box>
        </div>
      }
      contents={
        <Box p={2} pt={0}>
          <Stack spacing={2} width={400}>
            <Box
              sx={{
                border: "solid 1px #ddd",
                borderRadius: "5px",
                padding: "8px",
                "& .validity-note": {
                  display: "flex",
                  alignItems: "center",
                  "& .validity-label": {
                    fontSize: "17px",
                    fontWeight: "500",
                    mr: 2,
                  },
                  "& .validity-value": {
                    fontSize: "17px",
                    fontWeight: "500",
                  },
                },
              }}
            >
              <div
                className="validity-note"
                style={{
                  marginBottom: "6px",
                  paddingBottom: "6px",
                  borderBottom: "solid 1px #ddd",
                }}
              >
                <div className="validity-label">Good for report:</div>
                <Box
                  className="validity-value"
                  sx={{ color: goodSamples.length ? "success.main" : "" }}
                >
                  {`${goodSamples.length} Samples`}
                </Box>
              </div>
              <div className="validity-note">
                <div className="validity-label">Bad for report:</div>
                <Box
                  className="validity-value"
                  sx={{ color: badSamples.length ? "error.main" : "" }}
                >
                  {`${badSamples.length} Samples`}
                </Box>
              </div>
            </Box>
            <Button
              fullWidth
              variant="contained"
              disabled={!goodSamples.length || loading}
              onClick={handleComplete}
            >
              {`Start Downloading ${goodSamples.length} Reports`}
            </Button>
          </Stack>
        </Box>
      }
    />
  );
}
