import { ICompany } from "./company";
import { IPermissionStatus } from "./user";

export const SuperAdminProfileIdMock = "superAdmin";
export enum UserRoles {
  superadmin = "super",
  companyAdmin = "companyAdmin",
  user = "user",
  adminStaff = "adminStaff",
}
export interface ILoggedInUserInfo {
  _id: string;
  email: string;
  role: UserRoles;
  avatar?: string;
  phone?: string;
  fullname: string;
  authToken: string;
  company?: ICompany;
  permissions?: IPermissionStatus[];
}
export interface IUserShallow {
  fullname: string;
  email: string;
  role: UserRoles;
  phone: string;
  avatar: string;
  _id: string;
}

export interface IUserPopulated {
  fullname: string;
  email: string;
  password: string;
  approved: boolean;
  role: UserRoles;
  phone: string;
  avatar: string;
  isConfirmed: boolean;
  confirmOTP: string;
  otpTries: number;
  status: boolean;
  _id: string;
}
