/* eslint-disable @typescript-eslint/no-unused-vars */
import * as React from "react";
import CloseIcon from "@mui/icons-material/Close";
import Dialog, { DialogProps } from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";

interface Props extends DialogProps {
  width?: number;
  titleElement?: JSX.Element;
  contents: JSX.Element;
  actions?: JSX.Element;
  dividers?: boolean;
  headerBottom?: JSX.Element;
}

export default function ClosableDialog({
  width,
  titleElement,
  onClose,
  contents,
  actions,
  dividers,
  headerBottom,
  ...otherProps
}: Props) {
  const descriptionElementRef = React.useRef<HTMLElement>(null);
  React.useEffect(() => {
    const { current: descriptionElement } = descriptionElementRef;
    if (descriptionElement !== null) {
      descriptionElement.focus();
    }
  }, []);

  return (
    <Dialog
      {...otherProps}
      open
      onClose={onClose}
      scroll={"paper"}
      aria-labelledby="closable-dialog-scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
    >
      <DialogTitle
        id="closable-dialog-scroll-dialog-title"
        sx={{ display: "flex", alignItems: "center", pb: 1 }}
      >
        <div style={{ flex: 1 }}>
          <div style={{ display: "flex", alignItems: "center" }}>
            <div style={{ flex: 1 }}>{titleElement}</div>
            {onClose ? (
              <IconButton
                onClick={() => onClose && onClose({}, "backdropClick")}
              >
                <CloseIcon />
              </IconButton>
            ) : null}
          </div>
          <div style={{ fontSize: "unset", fontWeight: "unset" }}>
            {headerBottom}
          </div>
        </div>
      </DialogTitle>
      <DialogContent
        dividers={dividers}
        sx={{ width: `${width}px`, p: 0, m: 0, maxWidth: "100%" }}
      >
        {contents}
      </DialogContent>
      {actions ? (
        <DialogActions sx={{ justifyContent: "unset", display: "block" }}>
          {actions}
        </DialogActions>
      ) : null}
    </Dialog>
  );
}
