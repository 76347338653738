/* eslint-disable @typescript-eslint/no-explicit-any */
import { IDocumentation } from "../types/documentation";
import backendApi from "./backendApi";
import { showSnackbar } from "./snackbar.service";

const routePrefix = "datalake/documentation";

export default {
  async revertToOldVersion(
    documentationId: string,
    version: number
  ): Promise<any> {
    try {
      const result = await backendApi()?.post(
        `${routePrefix}/revert-old-version`,
        {
          documentationId,
          version,
        }
      );
      return result.data;
    } catch (error: any) {
      if (error?.response?.status) {
        showSnackbar(
          "danger",
          "Error",
          error?.response?.data?.message || "API error response"
        );
        throw error;
      }
    }
  },
  async fetchArchivedVersion(
    documentationId: string,
    version: number
  ): Promise<any> {
    try {
      const result = await backendApi()?.post(
        `${routePrefix}/get-archived-version`,
        {
          documentationId,
          version,
        }
      );
      if (!result.data) {
        showSnackbar("danger", "Error", "Version Not Found");
      }
      return result.data;
    } catch (error: any) {
      if (error?.response?.status) {
        showSnackbar(
          "danger",
          "Error",
          error?.response?.data?.message || "API error response"
        );
        throw error;
      }
    }
  },
  async fetchArchivedVersionsList(documentationId: string): Promise<any> {
    try {
      const result = await backendApi()?.get(
        `${routePrefix}/get-archived-versions/${documentationId}`
      );
      return result.data;
    } catch (error: any) {
      if (error?.response?.status) {
        showSnackbar(
          "danger",
          "Error",
          error?.response?.data?.message || "API error response"
        );
        throw error;
      }
    }
  },
  async addNewComment({
    parentCommentId,
    content,
    documentationId,
  }: {
    parentCommentId?: string;
    content: string;
    documentationId: string;
  }): Promise<any> {
    try {
      const result = await backendApi()?.post(`${routePrefix}/add-comment`, {
        parentCommentId,
        content,
        documentationId,
      });
      return result.data;
    } catch (error: any) {
      if (error?.response?.status) {
        showSnackbar(
          "danger",
          "Error",
          error?.response?.data?.message || "API error response"
        );
        throw error;
      }
    }
  },
  async updateComment({
    content,
    commentId,
  }: {
    commentId: string;
    content: string;
  }): Promise<any> {
    try {
      const result = await backendApi()?.post(`${routePrefix}/update-comment`, {
        content,
        commentId,
      });
      return result.data;
    } catch (error: any) {
      if (error?.response?.status) {
        showSnackbar(
          "danger",
          "Error",
          error?.response?.data?.message || "API error response"
        );
        throw error;
      }
    }
  },
  async markCommentSeen(commentId: string): Promise<any> {
    try {
      const result = await backendApi()?.post(
        `${routePrefix}/comments/mark-seen/${commentId}`
      );
      return result.data;
    } catch (error: any) {
      if (error?.response?.status) {
        showSnackbar(
          "danger",
          "Error",
          error?.response?.data?.message || "API error response"
        );
        throw error;
      }
    }
  },

  async fetchComments(documentationId: string): Promise<any> {
    try {
      const result = await backendApi()?.get(
        `${routePrefix}/comments/${documentationId}`
      );
      return result.data;
    } catch (error: any) {
      if (error?.response?.status) {
        showSnackbar(
          "danger",
          "Error",
          error?.response?.data?.message || "API error response"
        );
        throw error;
      }
    }
  },
  async fetchAllDocumentations(searchTerm?: string): Promise<any> {
    try {
      const result = await backendApi()?.get(`${routePrefix}/`, {
        params: {
          searchTerm,
        },
      });
      return result.data;
    } catch (error: any) {
      if (error?.response?.status) {
        showSnackbar(
          "danger",
          "Error",
          error?.response?.data?.message || "API error response"
        );
        return null;
      }
    }
  },
  async fetchDocumentationById(id: string): Promise<any> {
    try {
      const result = await backendApi()?.get(`${routePrefix}/${id}`);
      return result.data;
    } catch (error: any) {
      if (error?.response?.status) {
        showSnackbar(
          "danger",
          "Error",
          error?.response?.data?.message || "API error response"
        );
        throw error;
      }
    }
  },
  async createNewDocumentation(payload: IDocumentation): Promise<any> {
    try {
      const result = await backendApi()?.post(`${routePrefix}/create`, {
        documentation: payload,
      });
      showSnackbar(
        "success",
        "Success",
        result?.data.message || "New documentation successfully created"
      );
      return result.data;
    } catch (error: any) {
      if (error?.response?.status) {
        showSnackbar(
          "danger",
          "Error",
          error?.response?.data?.message || "API error response"
        );
        throw error;
      }
    }
  },
  async updateDocumentation(
    payload: IDocumentation,
    changedFields: string[]
  ): Promise<any> {
    try {
      const result = await backendApi()?.post(`${routePrefix}/update`, {
        documentation: payload,
        changedFields,
      });
      showSnackbar(
        "success",
        "Success",
        result?.data.message || "Documentation successfully updated"
      );
      return result.data;
    } catch (error: any) {
      if (error?.response?.status) {
        showSnackbar(
          "danger",
          "Error",
          error?.response?.data?.message || "API error response"
        );
        throw error;
      }
    }
  },
};
