import * as React from "react";
import ShoppingBasketIcon from "@mui/icons-material/ShoppingBasket";
import GeneralPopoverWrapper from "../Common/GeneralPopoverWrapper";
import CloseIcon from "@mui/icons-material/Close";
import Badge from "@mui/material/Badge";
import SsidChartIcon from "@mui/icons-material/SsidChart";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { ISampleRecordPopulated } from "../../../types/sample";
import IconButton from "@mui/material/IconButton";
import Box from "@mui/material/Box";
import Tooltip from "@mui/material/Tooltip";
import Button from "@mui/material/Button";
import ProgressButton from "../Common/ProgressButton";

export default function CollectionBucketPopover({
  collectedRows,
  onChangeCollectedRows,
  displayRowsInTable,
  addRowIdsToSpectraGraph,
  loadingSpectraData,
}: {
  collectedRows: ISampleRecordPopulated[];
  onChangeCollectedRows: (changed: ISampleRecordPopulated[]) => void;
  displayRowsInTable: (rowsToShow: ISampleRecordPopulated[]) => void;
  addRowIdsToSpectraGraph: (rowIds: number[]) => void;
  loadingSpectraData: boolean;
}) {
  return (
    <GeneralPopoverWrapper
      triggerElement={
        <Badge
          badgeContent={collectedRows.length}
          color={collectedRows.length ? "error" : "default"}
          showZero
          sx={{
            "& .MuiBadge-badge": {
              top: "7px",
              right: "9px",
              background: !collectedRows.length ? "#dadada" : "",
            },
          }}
        >
          <IconButton size="small" sx={{ mr: 1 }}>
            <ShoppingBasketIcon />
          </IconButton>
        </Badge>
      }
      popoverContent={
        <Box sx={{ width: "350px" }}>
          <Box
            px={2}
            py={1}
            sx={{ borderBottom: "solid 1px #ddd", fontWeight: "500" }}
          >
            Collected Rows
          </Box>
          <Box sx={{ maxHeight: "400px", overflow: "auto" }}>
            {collectedRows.map((r) => (
              <Box
                key={`${r.uid}`}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  pl: 2,
                  pr: 1,
                  borderBottom: "solid 1px #ddd",
                }}
              >
                <div style={{ marginRight: "5px" }}>{r.uid}</div>
                <div style={{ opacity: "0.6" }}>{`(${r.sampleNumber})`}</div>
                <div style={{ flex: 1 }} />
                <Tooltip title="View details in table" placement="left" arrow>
                  <IconButton onClick={() => displayRowsInTable([r])}>
                    <VisibilityIcon />
                  </IconButton>
                </Tooltip>
                <IconButton
                  onClick={() =>
                    onChangeCollectedRows(
                      collectedRows.filter((cr) => cr.uid != r.uid)
                    )
                  }
                >
                  <CloseIcon />
                </IconButton>
              </Box>
            ))}
          </Box>
          <Box px={1} py={1}>
            <Button
              size="small"
              variant="contained"
              startIcon={<VisibilityIcon />}
              sx={{ mr: "6px" }}
              onClick={() => {
                displayRowsInTable(collectedRows);
              }}
            >
              View
            </Button>
            <ProgressButton
              size="small"
              variant="contained"
              startIcon={<SsidChartIcon />}
              sx={{ mr: "6px" }}
              inProgress={loadingSpectraData}
              onClick={() =>
                addRowIdsToSpectraGraph(collectedRows.map((r) => r.uid))
              }
              title="+ Graph"
            />
          </Box>
        </Box>
      }
      tooltipTitle="Collection Bucket"
    />
  );
}
