import { styled } from "@mui/material/styles";
import TextareaAutosize from "@mui/base/TextareaAutosize";

export const AutoResizeTextField = styled(TextareaAutosize)(() => ({
  outline: "none",
  boxSizing: "border-box",
  minWidth: "200px",
  width: "100%",
  border: "none",
  resize: "none",
  fontWeight: 500,
  color: "#2c2c2c",
  borderRadius: "3px",
  fontSize: "23px",
  background: "#eee",
  paddingTop: "6px",
  paddingBottom: "6px",
  paddingLeft: "6px",
  "&.small": { fontSize: "20px" },
  "&.blank": {
    background: "#eee",
    paddingLeft: "8px",
  },
  "&.changed": {
    border: "solid 1px #ed6c0255",
  },
  "&.error": {
    border: "solid 1px #d32f2f",
  },
  "&:hover": {
    background: "#eee",
  },
  "&:focus": {
    paddingLeft: "8px",
    background: "#eee",
  },
}));
