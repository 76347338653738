import * as React from "react";
import { Box, CircularProgress, IconButton, Tooltip } from "@mui/material";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import WhitePanel from "../../components/Common/WhitePanel";
import documentationService from "../../../service/documentationService";
import { IDocumentation } from "../../../types/documentation";
import AddIconButton from "../../components/Common/AddIconButton";
import SimpleSearchInputWithDebounce from "../../components/Common/SimpleSearchInputWithDebounce";
import DocumentationForm from "../../components/Documentation/DocumentationForm";
import DocumentationListItem from "../../components/Documentation/DocumentationListItem";
import useWindowHeight from "../../../hooks/useWindowHeight";
import { IComment } from "../../../types/comment";
import CommentsPanel from "../../components/Common/Comment/CommentsPanel";
import {
  DocumentationsPageContext,
  IDocumentationsPageContext,
} from "./DocumentationsPageContext";
import { useSearchParams } from "react-router-dom";

export default function DocumentationPage() {
  const [searchParams] = useSearchParams();
  const [documentations, setDocumentations] = React.useState<IDocumentation[]>(
    []
  );
  const windowHeight = useWindowHeight();
  const [loading, setLoading] = React.useState(false);
  const [targetDocumentation, setTargetDocumentation] =
    React.useState<IDocumentation>();
  const [searchTerm, setSearchTerm] = React.useState("");
  const [openCommentPanel, setOpenCommentPanel] = React.useState(false);
  const [comments, setComments] = React.useState<IComment[]>([]);
  const [loadingComments, setLoadingComments] = React.useState(false);
  const [unseenCommentIds, setUnseenCommentIds] = React.useState<string[]>([]);

  const documentationsPageContextValues: IDocumentationsPageContext = {
    currentOpenedDocumentation: targetDocumentation,
    currentDocumentationComments: comments,
    unseenCommentIds: unseenCommentIds,
    markCommentSeen: (seenCommentId: string) => {
      setUnseenCommentIds([
        ...unseenCommentIds.filter((cId) => cId != seenCommentId),
      ]);
    },
  };

  const fetchDocumentations = async () => {
    setLoading(true);
    const result = await documentationService.fetchAllDocumentations(
      searchTerm
    );
    setDocumentations(result.documentations || []);
    if (result.documentations?.[0]) {
      setTargetDocumentation(result.documentations?.[0]);
    }
    setUnseenCommentIds(result.unseenCommentIds || []);
    setLoading(false);
  };

  const fetchComments = async (docId: string) => {
    try {
      setLoadingComments(true);
      const commentsResult = await documentationService.fetchComments(docId);
      setComments(commentsResult || []);
      setLoadingComments(false);
    } catch (error) {
    } finally {
      setLoadingComments(false);
    }
  };

  const handleAddNewComment = async (newComment: IComment) => {
    if (!targetDocumentation?._id) return;
    try {
      const result = await documentationService.addNewComment({
        parentCommentId: newComment.parentCommentId,
        content: newComment.content,
        documentationId: targetDocumentation._id,
      });
      if (result) {
        setComments([...comments, result]);
      }
    } catch (error) {}
  };
  const handleUpdateComment = async (updatedComment: IComment) => {
    if (!updatedComment?._id) return;
    try {
      await documentationService.updateComment({
        commentId: updatedComment._id || "",
        content: updatedComment.content,
      });
      const tempComments = [...comments];
      const updatedIndex = tempComments.findIndex(
        (c) => c._id == updatedComment._id
      );
      if (updatedIndex > -1) {
        tempComments.splice(updatedIndex, 1, updatedComment);
        setComments(tempComments);
      }
    } catch (error) {}
  };

  React.useEffect(() => {
    fetchDocumentations();
  }, [searchTerm]);

  React.useEffect(() => {
    if (targetDocumentation?._id) {
      fetchComments(targetDocumentation._id);
    }
  }, [targetDocumentation]);

  React.useEffect(() => {
    const docId = searchParams.get("id");
    if (docId && documentations.length) {
      const urlDefinedDoc = documentations.find((d) => d._id == docId);
      if (urlDefinedDoc) {
        setTargetDocumentation(urlDefinedDoc);
        if (searchParams.get("open_comment")) {
          setOpenCommentPanel(true);
        }
      }
    }
  }, [searchParams, documentations]);
  return (
    <DocumentationsPageContext.Provider value={documentationsPageContextValues}>
      <Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            fontSize: "24px",
            fontWeight: "500",
            opacity: 0.8,
            mb: 1,
          }}
        >
          Documentation
        </Box>
        <WhitePanel>
          <Box sx={{ display: "flex", position: "relative" }}>
            <Box
              sx={{
                width: openCommentPanel ? "0px !important" : "400px",
                maxWidth: "100%",
                borderRight: "solid 1px #ddd",
                p: "4px",
                pr: openCommentPanel ? 0 : 2,
                transition: "0.1s",
                overflow: "hidden",
              }}
            >
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <AddIconButton
                  onClick={() => setTargetDocumentation(undefined)}
                />
                <SimpleSearchInputWithDebounce
                  fullWidth
                  searchTerm={searchTerm}
                  onChangeSearchTerm={(changed) => setSearchTerm(changed)}
                />
              </Box>
              {loading ? (
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    height: "400px",
                  }}
                >
                  <CircularProgress />
                </Box>
              ) : !documentations.length ? (
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    height: "400px",
                  }}
                >
                  No Documents
                </Box>
              ) : (
                <Box
                  sx={{
                    maxHeight: `${windowHeight - 56 - 48 - 32 - 96}px`,
                    overflow: "auto",
                    mt: 2,
                  }}
                >
                  {documentations.map((doc) => (
                    <DocumentationListItem
                      key={doc._id}
                      searchTerms={[searchTerm]}
                      selected={targetDocumentation?._id == doc._id}
                      documentation={doc}
                      onClick={() => setTargetDocumentation(doc)}
                    />
                  ))}
                </Box>
              )}
            </Box>
            <Box sx={{ flex: 1, px: 2 }}>
              <DocumentationForm
                onClickComments={() => setOpenCommentPanel(!openCommentPanel)}
                loadingComments={loadingComments}
                commentsCount={comments?.length}
                documentation={targetDocumentation}
                onCreate={(created) => {
                  setDocumentations([created, ...documentations]);
                  setTargetDocumentation(created);
                }}
                onUpdate={(updated) => {
                  const tempDocs = [...documentations];
                  const targetIndex = tempDocs.findIndex(
                    (d) => d._id == updated._id
                  );
                  if (targetIndex > -1) {
                    tempDocs.splice(targetIndex, 1, updated);
                    setDocumentations(tempDocs);
                  }
                  setTargetDocumentation({ ...updated });
                }}
              />
            </Box>
            <Box
              sx={{
                width: openCommentPanel ? "500px" : "0px !important",
                maxWidth: "100%",
                borderLeft: "solid 1px #ddd",
                pl: openCommentPanel ? 2 : 0,
                overflow: "hidden",
                transition: "0.1s",
              }}
            >
              {targetDocumentation?._id ? (
                <CommentsPanel
                  resourceId={targetDocumentation?._id}
                  comments={comments}
                  onUpdateComment={async (updated) => {
                    await handleUpdateComment(updated);
                  }}
                  onAddComment={async (newComment) => {
                    await handleAddNewComment(newComment);
                  }}
                  onClosePanel={() => setOpenCommentPanel(false)}
                />
              ) : null}
            </Box>
            {openCommentPanel && (
              <Tooltip title="Open Documentation List" arrow placement="right">
                <IconButton
                  onClick={() => setOpenCommentPanel(false)}
                  sx={{
                    position: "absolute",
                    top: "45%",
                    left: "0%",
                    transform: "translate(-50%, -50%)",
                    backgroundColor: "#ccc",
                    width: "22px",
                    height: "22px",
                    "&:hover": {
                      backgroundColor: "#bbb",
                    },
                  }}
                  size="small"
                >
                  <ChevronRightIcon />
                </IconButton>
              </Tooltip>
            )}
          </Box>
        </WhitePanel>
      </Box>
    </DocumentationsPageContext.Provider>
  );
}
