import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import * as React from "react";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import { ICompany } from "../../../types/company";
import ProgressButton from "../../components/Common/ProgressButton";
import companyService from "../../../service/company.service";
import IconButton from "@mui/material/IconButton";
import FreeRatioCropper from "../../components/Common/FreeRatioCropper";
import { UserRoles } from "../../../types/auth";
import { useAppContext } from "../../../contexts/useStorageContext";

export default function CompanyDetailForm({
  company,
  onNewCreated,
  onUpdated,
}: {
  company?: ICompany;
  onNewCreated?: (created: ICompany) => void;
  onUpdated?: (updates: ICompany) => void;
}) {
  const { state } = useAppContext();
  const { loggedInUserInfo } = state;
  const isSuperAdmin = loggedInUserInfo?.role == UserRoles.superadmin;
  const [editingCompany, setEditingCompany] = React.useState<ICompany>(
    company || {}
  );
  const [processing, setProcessing] = React.useState(false);
  const [openImageUploadDialog, setOpenImageUploadDialog] =
    React.useState(false);

  const handleCreate = async () => {
    setProcessing(true);
    const result = await companyService.addNewCompany(editingCompany);
    if (result?.data) {
      onNewCreated && onNewCreated(result.data);
    }
    setProcessing(false);
  };

  const handleUpdate = async () => {
    if (!company) return;
    setProcessing(true);
    const updateInfo = { ...editingCompany };
    delete updateInfo._id;
    const result = await companyService.updateCompany(updateInfo, company._id);
    if (result?.data) {
      onUpdated && onUpdated(editingCompany);
    }
    setProcessing(false);
  };

  React.useEffect(() => {
    if (company) {
      setEditingCompany(company);
    }
  }, [company]);
  return (
    <Box>
      <Stack spacing={3}>
        <Stack direction={{ xs: "column", sm: "row" }} spacing={3}>
          <Box sx={{ flex: 1 }}>
            <Box
              sx={{
                position: "relative",
                width: "100%",
                height: "100%",
                maxHeight: "200px",
                background: "#f3f3f3",
                borderRadius: "5px",
                backgroundImage: `url(${editingCompany.logoUrl || ""})`,
                backgroundRepeat: "no-repeat",
                backgroundSize: "contain",
                backgroundPosition: "center",
              }}
            >
              <IconButton
                sx={{
                  position: "absolute",
                  top: 10,
                  left: 10,
                  backgroundColor: "#0003",
                  color: "#fff",
                  "&:hover": { backgroundColor: "primary.main", color: "#fff" },
                }}
                onClick={() => setOpenImageUploadDialog(true)}
              >
                <CameraAltIcon />
              </IconButton>
            </Box>
          </Box>
          <Stack spacing={3} sx={{ flex: 1 }}>
            <TextField
              label="Company Name"
              value={editingCompany.name || ""}
              onChange={(e) =>
                setEditingCompany({ ...editingCompany, name: e.target.value })
              }
              placeholder="Company Name"
              fullWidth
              size="small"
            />
            <TextField
              label="Contact Email"
              value={editingCompany.email || ""}
              onChange={(e) =>
                setEditingCompany({ ...editingCompany, email: e.target.value })
              }
              placeholder="Contact Email"
              fullWidth
              size="small"
            />
            <TextField
              label="Contact Phone Number"
              value={editingCompany.phone || ""}
              onChange={(e) =>
                setEditingCompany({ ...editingCompany, phone: e.target.value })
              }
              placeholder="Phone Number"
              fullWidth
              size="small"
            />
          </Stack>
        </Stack>

        <TextField
          label="Location"
          value={editingCompany.address || ""}
          onChange={(e) =>
            setEditingCompany({ ...editingCompany, address: e.target.value })
          }
          placeholder="Address"
          fullWidth
          size="small"
        />
        <TextField
          value={editingCompany.logoUrl || ""}
          onChange={(e) =>
            setEditingCompany({ ...editingCompany, logoUrl: e.target.value })
          }
          placeholder="Logo URL"
          fullWidth
          size="small"
        />
      </Stack>
      <Box mt={3} sx={{ display: "flex", justifyContent: "flex-end" }}>
        {company ? (
          <ProgressButton
            disabled={!editingCompany?.name}
            onClick={() => !processing && handleUpdate()}
            title="Save Changes"
            inProgress={processing}
          />
        ) : (
          <ProgressButton
            disabled={!editingCompany?.name}
            onClick={() => !processing && handleCreate()}
            title={isSuperAdmin ? "Add New Lab" : "Add New Client"}
            inProgress={processing}
          />
        )}
      </Box>

      {openImageUploadDialog && (
        <FreeRatioCropper
          onSave={(url) => {
            setEditingCompany({ ...editingCompany, logoUrl: url });
            setOpenImageUploadDialog(false);
          }}
          open
          onClose={() => setOpenImageUploadDialog(false)}
        />
      )}
    </Box>
  );
}
