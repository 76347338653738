import { ILoggedInUserInfo } from "./auth";
import { ICompany } from "./company";
import { IUser } from "./user";

export interface StateType {
  openSideDrawer: boolean;
  loggedInUserInfo?: ILoggedInUserInfo;
  myProfile?: IUser;
  myCompanyProfile?: ICompany;
  myClients?: ICompany[];
}

export const actionTypes = {
  setMyProfile: "setMyProfile",
  setMyCompanyProfile: "setMyCompanyProfile",
  setOpenSideDrawer: "setOpenSideDrawer",
  setLoggedInUserInfo: "setLoggedInUserInfo",
  setCurrentCompany: "setCurrentCompany",
  setCurrentFarm: "setCurrentFarm",
  setAllState: "setAllState",
  setMyClients: "setMyClients",
};

export type ActionType =
  | { type: typeof actionTypes.setMyProfile; value: IUser | undefined }
  | {
      type: typeof actionTypes.setMyCompanyProfile;
      value: ICompany | undefined;
    }
  | { type: typeof actionTypes.setOpenSideDrawer; value: boolean }
  | {
      type: typeof actionTypes.setLoggedInUserInfo;
      value: ILoggedInUserInfo | undefined;
    }
  | { type: typeof actionTypes.setAllState; value: StateType }
  | { type: typeof actionTypes.setMyClients; value: ICompany[] };

export interface ContextType {
  state: StateType;
  dispatch?: React.Dispatch<ActionType>;
}
