/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState, useEffect } from "react";
import AddIcon from "@mui/icons-material/Add";
import { styled } from "@mui/material/styles";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  IconButton,
  Stack,
  Box,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import {
  IPredictionAiModel,
  PredictionModelProduct,
} from "../../../types/predictionModel";
import predictionModelService, {
  getDateString,
} from "../../../service/predictionModel.service";
import dayjs from "dayjs";
import ProgressButton from "../Common/ProgressButton";
import AttachedFileItem, { AddFileBox } from "../Common/AttachedFileItem";
import AnyAssetFileUploader, {
  AwsLocation,
} from "../Common/AnyAssetFileUploader";
import { uniqBy } from "lodash";

const FieldLabel = styled(Box)(() => ({
  fontSize: "13px",
  marginBottom: "5px",
  marginLeft: "3px",
}));

interface PredictionModelFormDialogProps {
  open: boolean;
  onClose: () => void;
  onSave: (model: IPredictionAiModel) => void;
  initialModel?: IPredictionAiModel;
}

const PredictionModelFormDialog: React.FC<PredictionModelFormDialogProps> = ({
  open,
  onClose,
  onSave,
  initialModel,
}) => {
  const [model, setModel] = useState<Partial<IPredictionAiModel>>(
    initialModel || {
      date: getDateString(new Date()),
      product: "",
      title: "",
      description: "",
      link: "",
    }
  );
  const [openFilesUploadDialog, setOpenFilesUploadDialog] =
    React.useState(false);
  const [processing, setProcessing] = useState(false);

  const handleSubmit = async () => {
    setProcessing(true);
    try {
      let result;
      if (initialModel) {
        await predictionModelService.updatePredictionModel({
          id: initialModel._id, // Assuming 'id' is the correct property
          ...model,
        });
        result = model;
      } else {
        result = await predictionModelService.createPredictionModel(
          model as Required<IPredictionAiModel>
        );
      }
      if (result) {
        onSave(result);
        onClose();
      }
    } catch (error) {
      console.error("Error saving prediction model:", error);
    } finally {
      setProcessing(false);
    }
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle sx={{ display: "flex", justifyContent: "space-between" }}>
        {initialModel ? "Edit Prediction Model" : "Add New Prediction Model"}
        <IconButton onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent sx={{ overflow: "visible", minWidth: "450px" }}>
        <Stack spacing={2}>
          <Stack direction="row" spacing={2}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                disabled={!!initialModel}
                label="Updated Date"
                value={model?.date ? dayjs(model.date) : null}
                onChange={(newValue) =>
                  setModel({
                    ...model,
                    date: getDateString(newValue?.toDate()) || undefined,
                  })
                }
                slotProps={{ textField: { size: "small" } }}
              />
            </LocalizationProvider>
            <FormControl fullWidth margin="dense">
              <TextField
                disabled={!!initialModel}
                label={"Product"}
                select
                fullWidth
                value={model.product || ""}
                size="small"
                onChange={(event) => {
                  setModel({
                    ...model,
                    product: event.target.value as PredictionModelProduct,
                  });
                }}
              >
                <MenuItem value={PredictionModelProduct.TMR}>TMR</MenuItem>
                <MenuItem value={PredictionModelProduct.CS}>CS</MenuItem>
              </TextField>
            </FormControl>
          </Stack>
          <TextField
            margin="dense"
            name="title"
            label="Title"
            fullWidth
            value={model.title || ""}
            onChange={(event) => {
              setModel({
                ...model,
                title: event.target.value,
              });
            }}
            size="small"
          />
          <TextField
            margin="dense"
            name="description"
            label="Description"
            fullWidth
            multiline
            rows={4}
            value={model.description || ""}
            onChange={(event) => {
              setModel({
                ...model,
                description: event.target.value,
              });
            }}
            size="small"
          />
          <TextField
            margin="dense"
            name="link"
            label="Link"
            fullWidth
            value={model.link || ""}
            onChange={(event) => {
              setModel({
                ...model,
                link: event.target.value,
              });
            }}
            size="small"
          />
          <Box>
            <FieldLabel>Result Files</FieldLabel>
            {model.files?.map((f) => (
              <AttachedFileItem
                url={f}
                onRemove={() =>
                  setModel({
                    ...model,
                    files: (model.files || []).filter((rf) => rf != f),
                  })
                }
                mb={8}
              />
            ))}
            <AddFileBox onClick={() => setOpenFilesUploadDialog(true)}>
              <AddIcon />
            </AddFileBox>
          </Box>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <ProgressButton
          onClick={handleSubmit}
          color="primary"
          title={initialModel ? "Update" : "Create"}
          inProgress={processing}
          disabled={!model.date || !model.product}
        />
      </DialogActions>
      {openFilesUploadDialog && (
        <AnyAssetFileUploader
          open
          onClose={() => setOpenFilesUploadDialog(false)}
          onSave={(urls) => {
            setModel({
              ...model,
              files: uniqBy([...(model.files || []), ...urls], (v) => v),
            });
            setOpenFilesUploadDialog(false);
          }}
          title="Upload Result Files"
          location={AwsLocation.OrugenDatalake}
          directory={`prediction-models`}
          multiple
        />
      )}
    </Dialog>
  );
};

export default PredictionModelFormDialog;
