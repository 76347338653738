import Tooltip from "@mui/material/Tooltip";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { SvgIconProps } from "@mui/material/SvgIcon";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import React from "react";

export interface ICopyToClipboardIconProps extends SvgIconProps {
  text?: string;
}

export default function CopyToClipboardIcon({
  text = "",
  sx,
  ...otherProps
}: ICopyToClipboardIconProps) {
  const [openSnackbar, setOpenSnackbar] = React.useState(false);
  return (
    <>
      <Tooltip title="Copy to clipboard" placement="top">
        <ContentCopyIcon
          onClick={() => {
            navigator.clipboard.writeText(text);
            setOpenSnackbar(true);
          }}
          sx={{ ...sx, cursor: "pointer" }}
          {...otherProps}
        />
      </Tooltip>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={3000}
        onClose={() => setOpenSnackbar(false)}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert
          onClose={() => setOpenSnackbar(false)}
          severity="success"
          variant="filled"
          sx={{ width: "100%", alignItems: "center" }}
        >
          Copied to your clipboard!
        </Alert>
      </Snackbar>
    </>
  );
}
