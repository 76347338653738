export function nameToHslColor(
  name: string,
  saturation: number,
  lightness: number
): string {
  let hash = 0;
  for (let i = 0; i < name?.length; i++) {
    hash = name.charCodeAt(i) + ((hash << 5) - hash);
  }

  const hue = Math.abs(hash) % 360;
  return "hsl(" + hue + ", " + saturation + "%, " + lightness + "%)";
}

export function nameInitials(name: string) {
  return name
    .toUpperCase()
    .split(" ")
    .map((wordInPartnerName) => wordInPartnerName.charAt(0))
    .join("");
}
