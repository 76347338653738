import TextField from "@mui/material/TextField";
import * as React from "react";
import { debounce } from "lodash";
import CloseIcon from "@mui/icons-material/Close";
import InputAdornment from "@mui/material/InputAdornment";
import SearchIcon from "@mui/icons-material/Search";
import IconButton from "@mui/material/IconButton";
import { SxProps, Theme } from "@mui/material";

export default function SimpleSearchInputWithDebounce({
  searchTerm,
  onChangeSearchTerm,
  sx,
  placeholder,
  fullWidth,
}: {
  searchTerm: string;
  onChangeSearchTerm: (changed: string) => void;
  sx?: SxProps<Theme>;
  placeholder?: string;
  fullWidth?: boolean;
}) {
  const [searchInputKey, setSearchInputKey] = React.useState(0);

  const debouncedSearch = debounce(onChangeSearchTerm, 500);

  return (
    <TextField
      sx={sx}
      fullWidth={fullWidth}
      key={searchInputKey}
      value={undefined}
      onChange={(e) => debouncedSearch(e.target.value)}
      focused={!!searchTerm}
      placeholder={placeholder || "Search..."}
      size="small"
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <SearchIcon />
          </InputAdornment>
        ),
        endAdornment: (
          <InputAdornment position="end">
            {searchTerm ? (
              <IconButton
                size="small"
                onClick={() => {
                  onChangeSearchTerm("");
                  setSearchInputKey(searchInputKey + 1);
                }}
              >
                <CloseIcon />
              </IconButton>
            ) : null}
          </InputAdornment>
        ),
      }}
    />
  );
}
