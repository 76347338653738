/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as React from "react";
import EditIcon from "@mui/icons-material/Edit";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { DataGridPro } from "@mui/x-data-grid-pro";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import WhitePanel from "../../components/Common/WhitePanel";
import Box from "@mui/material/Box";
import SimpleSearchInput from "../../components/Common/SimpleSearchInput";
import { IGasAnalysis } from "../../../types/gasAnalysis";
import { PaginationParams } from "../../../types/tables";
import {
  DefaultRowsPerPageOptions,
  defaultPaginationParams,
} from "../../../helpers/defaults/pagination";
import gasAnalysisService from "../../../service/gasAnalysisService";
import { getFileNameFromAssetUrl } from "../../../helpers/utils";
import Button from "@mui/material/Button";
import GasAnalysisFormDialog from "../../components/GasAnalysis/GasAnalysisFormDialog";
import { fDateTime } from "../../../helpers/formatTime";
import GeneralPopoverWrapper from "../../components/Common/GeneralPopoverWrapper";
import IconButton from "@mui/material/IconButton";
import MenuList from "@mui/material/MenuList";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";

export default function Index() {
  const [selectedIds, setSelectedIds] = React.useState<string[]>([]);
  const [loading, setLoading] = React.useState(false);
  const [searchTerm, setSearchTerm] = React.useState("");
  const [analysisItems, setAnalysisItems] = React.useState<IGasAnalysis[]>([]);
  const [targetAnalysis, setTargetAnalysis] = React.useState<IGasAnalysis>();
  const [paginationParams, setPaginationParams] =
    React.useState<PaginationParams>({
      ...defaultPaginationParams,
      pageSize: 25,
    });
  const [openFormDialog, setOpenFormDialog] = React.useState(false);

  const columns = [
    {
      field: "sourceFile",
      headerName: "Source File",
      width: 350,
      editable: false,
      valueGetter: (params: any) => getFileNameFromAssetUrl(params.value),
    },
    {
      field: "processedDate",
      headerName: "Processed Date",
      width: 250,
      editable: false,
      valueGetter: (params: any) =>
        params.value ? fDateTime(params.value) : "",
    },
    {
      field: "analysisDate",
      headerName: "Analysis Date",
      sortable: false,
      width: 250,
      valueGetter: (params: any) =>
        params.value ? fDateTime(params.value) : "",
    },
    {
      field: "actions",
      headerName: "Action",
      width: 80,
      editable: false,
      sortable: false,
      disableColumnMenu: true,
      disableReorder: true,
      renderCell: (params: any) => {
        return (
          <Box>
            <GeneralPopoverWrapper
              closeOnClickContent
              triggerElement={
                <IconButton size="small">
                  <MoreVertIcon />
                </IconButton>
              }
              popoverContent={
                <MenuList>
                  <MenuItem
                    onClick={() => {
                      setTargetAnalysis(params.row);
                      setOpenFormDialog(true);
                    }}
                  >
                    <ListItemIcon>
                      <EditIcon />
                    </ListItemIcon>
                    <ListItemText>View Analysis</ListItemText>
                  </MenuItem>
                </MenuList>
              }
            />
          </Box>
        );
      },
    },
  ];

  const fetchData = async (pagination?: PaginationParams) => {
    try {
      const currentPaginationParams = pagination || paginationParams;
      setLoading(true);
      const result = await gasAnalysisService.get({
        page: currentPaginationParams.page as number,
        pageSize: currentPaginationParams.pageSize as number,
        searchTerm,
      });
      setAnalysisItems(result);
      setLoading(false);
    } catch (e) {
    } finally {
      setLoading(false);
    }
  };

  React.useEffect(() => {
    fetchData();
  }, [searchTerm]);
  return (
    <>
      <Box mb={1}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            fontSize: "24px",
            fontWeight: "500",
            opacity: 0.8,
          }}
        >
          Gas Analysis
        </Box>
      </Box>
      <WhitePanel>
        <Box
          pb={2}
          sx={{ display: "flex", alignItems: "center", flexWrap: "wrap" }}
        >
          <Box>
            <SimpleSearchInput
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              size="small"
              placeholder="Search GasAnalysis"
              onClear={() => setSearchTerm("")}
            />
          </Box>
          <Box sx={{ flex: 1 }} />
          <Button
            onClick={() => {
              setTargetAnalysis(undefined);
              setOpenFormDialog(true);
            }}
            startIcon={<AddCircleOutlineIcon />}
            disableElevation
            variant="contained"
            sx={{ ml: 2 }}
          >
            Add New
          </Button>
        </Box>
        <DataGridPro
          getRowId={(row) => row._id as string}
          loading={loading}
          autoHeight
          rowSelectionModel={selectedIds}
          onRowSelectionModelChange={(changedIds: any) => {
            setSelectedIds(changedIds);
          }}
          rows={analysisItems}
          columns={columns}
          // checkboxSelection
          disableRowSelectionOnClick
          pagination
          paginationMode="server"
          paginationModel={{
            page: paginationParams.page || 0,
            pageSize: paginationParams.pageSize || 25,
          }}
          pageSizeOptions={
            paginationParams && paginationParams.rowsPerPageOptions
              ? paginationParams.rowsPerPageOptions
              : DefaultRowsPerPageOptions
          }
          onPaginationModelChange={(changedModel) => {
            const newPaginationParams = { ...paginationParams };
            newPaginationParams.page = changedModel.page;
            newPaginationParams.pageSize = changedModel.pageSize;
            fetchData(newPaginationParams);
            setPaginationParams(newPaginationParams);
          }}
        />
      </WhitePanel>
      {openFormDialog && (
        <GasAnalysisFormDialog
          gasAnalysis={targetAnalysis}
          open
          onClose={() => setOpenFormDialog(false)}
          onCreated={(created) => setAnalysisItems([created, ...analysisItems])}
          onUpdated={(updated) => {
            const tempAnalysis = [...analysisItems];
            const updatedIndex = tempAnalysis.findIndex(
              (a) => a._id == updated._id
            );
            if (updatedIndex > -1) {
              tempAnalysis.splice(updatedIndex, 1, updated);
              setAnalysisItems(tempAnalysis);
            }
          }}
        />
      )}
    </>
  );
}
