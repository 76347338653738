/* eslint-disable @typescript-eslint/no-explicit-any */
// import Box from "@mui/material/Box";
import * as React from "react";
import PermIdentityIcon from "@mui/icons-material/PermIdentity";
import {
  DataGridPro,
  DataGridProProps,
  GridDataGroupNode,
  gridFilteredDescendantCountLookupSelector,
  GridRenderCellParams,
  useGridApiContext,
  useGridSelector,
} from "@mui/x-data-grid-pro";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import WhitePanel from "../../components/Common/WhitePanel";
import Box from "@mui/material/Box";
import EditIcon from "@mui/icons-material/Edit";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import SimpleSearchInput from "../../components/Common/SimpleSearchInput";
import {
  Avatar,
  Button,
  ButtonProps,
  Chip,
  IconButton,
  ListItemIcon,
  ListItemText,
  MenuItem,
  MenuList,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { ICompany, ICompanyWithUsers } from "../../../types/company";
import companyService from "../../../service/company.service";
import CompanyFormDialog from "./CompanyFormDialog";
import { IUserShallow, UserRoles } from "../../../types/auth";
import GeneralPopoverWrapper from "../../components/Common/GeneralPopoverWrapper";
import { useAppContext } from "../../../contexts/useStorageContext";
import ConfirmDialog from "../../components/Common/ConfirmDialog";
import {
  avatarString,
  highLightWords,
  stringToColor,
} from "../../../helpers/utils";
import { uniqBy } from "lodash";
import ExpandMore from "@mui/icons-material/ExpandMore";
import UserFormDialog from "../users/UserFormDialog";
import LightTooltip from "../../components/Common/LightTooltip";
import PredictionModelUsage from "../../components/Calculations/PredictionModelUsage/PredictionModelUsage";
import ReportUsage from "../../components/Calculations/ReportUsage/ReportUsage";

interface ICompanyWithPath extends ICompanyWithUsers {
  path: string[];
}

export default function Index() {
  const [selectedIds, setSelectedIds] = React.useState<string[]>([]);
  const [companies, setCompanies] = React.useState<ICompanyWithPath[]>([]);
  const [companiesProcessing, setCompaniesProcessing] = React.useState(false);
  const [openCompanyFormDialog, setOpenCompanyFormDialog] =
    React.useState(false);
  const [targetCompany, setTargetCompany] = React.useState<ICompany>();
  const [searchTerm, setSearchTerm] = React.useState("");
  const [openConfirmDialog, setOpenConfirmDialog] = React.useState(false);
  const { state } = useAppContext();
  const { loggedInUserInfo } = state;
  const [openUserFormDialog, setOpenUserFormDialog] = React.useState(false);
  const isSuperAdmin = loggedInUserInfo?.role == UserRoles.superadmin;

  function CustomGridTreeDataGroupingCell(props: GridRenderCellParams) {
    const { id, field, rowNode } = props;
    const apiRef = useGridApiContext();
    const filteredDescendantCountLookup = useGridSelector(
      apiRef,
      gridFilteredDescendantCountLookupSelector
    );
    const filteredDescendantCount =
      filteredDescendantCountLookup[rowNode.id] ?? 0;

    const handleClick: ButtonProps["onClick"] = (event) => {
      if (rowNode.type !== "group") {
        return;
      }

      apiRef.current.setRowChildrenExpansion(id, !rowNode.childrenExpanded);
      apiRef.current.setCellFocus(id, field);
      event.stopPropagation();
    };

    return (
      <Box sx={{ ml: rowNode.depth * 4 }}>
        <div>
          {filteredDescendantCount > 0 ? (
            <Button
              onClick={handleClick}
              tabIndex={-1}
              size="small"
              endIcon={
                (rowNode as GridDataGroupNode).childrenExpanded ? (
                  <ExpandLessIcon />
                ) : (
                  <ExpandMore />
                )
              }
            >
              See {filteredDescendantCount} Clients
            </Button>
          ) : (
            <span />
          )}
        </div>
      </Box>
    );
  }

  const groupingColDef: DataGridProProps["groupingColDef"] = {
    headerName: "Hierarchy",
    width: 150,
    renderCell: (params) => <CustomGridTreeDataGroupingCell {...params} />,
  };

  const columns = [
    {
      field: "name",
      headerName: "Lab Name",
      width: 150,
      editable: false,
      renderCell: (params: any) => {
        return <Box>{highLightWords(params.row.name, [searchTerm])}</Box>;
      },
    },
    {
      field: "address",
      headerName: "Address",
      width: 250,
      editable: false,
    },
    {
      field: "email",
      headerName: "Email",
      sortable: false,
      width: 160,
    },
    {
      field: "phone",
      headerName: "Phone",
      sortable: false,
      width: 160,
    },
    {
      field: "users",
      headerName: "Users",
      sortable: false,
      width: 160,
      renderCell: (params: any) => {
        return (
          <Box>
            <LightTooltip
              title={
                <Box p={2}>
                  {params.row.users?.map((user: IUserShallow) => {
                    return (
                      <Box
                        key={user._id}
                        sx={{
                          fontSize: "15px",
                          display: "flex",
                          alignItems: "center",
                          mb: 2,
                        }}
                      >
                        <div style={{ marginRight: "12px" }}>
                          <Avatar
                            alt={user.fullname}
                            src={user.avatar}
                            sx={{ bgcolor: stringToColor(user.fullname) }}
                          >
                            {avatarString(user.fullname)}
                          </Avatar>
                        </div>
                        <div>
                          <div>{user.fullname}</div>
                          <div style={{ opacity: 0.6 }}>{user.email}</div>
                        </div>
                      </Box>
                    );
                  })}
                </Box>
              }
            >
              <Chip label={`${params.row.users?.length || 0} Users`} />
            </LightTooltip>
          </Box>
        );
      },
    },
    {
      field: "actions",
      headerName: "Action",
      width: 80,
      editable: false,
      sortable: false,
      disableColumnMenu: true,
      disableReorder: true,
      renderCell: (params: any) => {
        return (
          <Box>
            <GeneralPopoverWrapper
              closeOnClickContent
              triggerElement={
                <IconButton size="small">
                  <MoreVertIcon />
                </IconButton>
              }
              popoverContent={
                <MenuList>
                  <MenuItem
                    onClick={() => {
                      setTargetCompany(params.row);
                      setOpenCompanyFormDialog(true);
                    }}
                  >
                    <ListItemIcon>
                      <EditIcon />
                    </ListItemIcon>
                    <ListItemText>Edit Company Info</ListItemText>
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      setTargetCompany(params.row);
                      setOpenUserFormDialog(true);
                    }}
                  >
                    <ListItemIcon>
                      <PermIdentityIcon />
                    </ListItemIcon>
                    <ListItemText>Add New User</ListItemText>
                  </MenuItem>
                </MenuList>
              }
            />
          </Box>
        );
      },
    },
  ];

  const getFilteredCompanies = () => {
    const matchingCompanies = companies.filter(
      (c) =>
        !searchTerm ||
        c.name?.toLowerCase()?.includes(searchTerm.toLowerCase()) ||
        c.email?.toLowerCase()?.includes(searchTerm.toLowerCase())
    );
    const parentsOfMatchingCompanies = companies.filter((c) =>
      matchingCompanies.some((mc) => mc.vendorCompanyId == c._id)
    );
    return uniqBy(
      [...matchingCompanies, ...parentsOfMatchingCompanies],
      (c) => c._id
    );
  };

  const fetchCompanies = async () => {
    setCompaniesProcessing(true);
    const result = await companyService.fetchAllCompanies();
    if (result?.data) {
      setCompanies(
        result.data?.map(
          (item: { company: ICompany; users: IUserShallow[] }) => {
            const parentCompany = result.data.find(
              (c: ICompany) => c._id === item.company?.vendorCompanyId
            )?.company;
            return {
              ...item.company,
              users: item.users,
              path: parentCompany
                ? [parentCompany._id, item.company._id]
                : [item.company._id],
            };
          }
        )
      );
    }
    setCompaniesProcessing(false);
  };

  const handleDeleteCompanies = async () => {
    const result = await companyService.deleteCompanies(selectedIds);
    if (result?.data) {
      setCompanies(
        companies.filter((c) => c._id && !selectedIds.includes(c._id))
      );
    }
  };

  React.useEffect(() => {
    fetchCompanies();
  }, []);
  return (
    <>
      <Box mb={1}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            fontSize: "24px",
            fontWeight: "500",
            opacity: 0.8,
          }}
        >
          {isSuperAdmin ? "Manage Labs & Clients" : "Manage Clients"}
          <Box
            sx={{ ml: 1, fontSize: "24px", fontWeight: "500", opacity: "0.6" }}
          >{`(${companies.length})`}</Box>
        </Box>
      </Box>
      <WhitePanel>
        <Box
          pb={2}
          sx={{ display: "flex", alignItems: "center", flexWrap: "wrap" }}
        >
          <Box>
            <SimpleSearchInput
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              size="small"
              placeholder="Search Company"
              onClear={() => setSearchTerm("")}
            />
          </Box>

          {selectedIds?.length ? (
            <Button
              onClick={() => setOpenConfirmDialog(true)}
              startIcon={<DeleteIcon />}
              disableElevation
              variant="contained"
              sx={{ ml: 2 }}
              color="error"
            >
              Delete
            </Button>
          ) : null}
          <Box sx={{ flex: 1 }} />

          <Button
            onClick={() => {
              setTargetCompany(undefined);
              setOpenCompanyFormDialog(true);
            }}
            startIcon={<AddCircleOutlineIcon />}
            disableElevation
            variant="contained"
            sx={{ ml: 2 }}
          >
            {isSuperAdmin ? "Add New Lab" : "Add New Client"}
          </Button>
        </Box>
        <DataGridPro
          getRowId={(row) => row._id as string}
          loading={companiesProcessing}
          autoHeight
          rowSelectionModel={selectedIds}
          onRowSelectionModelChange={(changedIds: any) => {
            setSelectedIds(changedIds);
          }}
          rows={getFilteredCompanies()}
          columns={columns}
          checkboxSelection
          disableRowSelectionOnClick
          treeData
          getTreeDataPath={(row) => row.path}
          groupingColDef={groupingColDef}
          isGroupExpandedByDefault={() => !!searchTerm}
          slots={{
            treeDataExpandIcon: () => {
              return <Chip size="small" label={`View Clients`} />;
            },
            treeDataCollapseIcon: () => <ExpandLessIcon />,
            detailPanelExpandIcon: () => (
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <img
                  src="img/icons/calculator.svg"
                  style={{
                    maxHeight: "25px",
                    width: "auto",
                    opacity: 0.8,
                  }}
                />
              </Box>
            ),
            detailPanelCollapseIcon: () => (
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <img
                  src="img/icons/calculator.svg"
                  style={{
                    maxHeight: "25px",
                    width: "auto",
                    opacity: 0.8,
                  }}
                />
              </Box>
            ),
          }}
          getDetailPanelHeight={() => "auto"}
          getDetailPanelContent={(params) => {
            return (
              <Box p={2} sx={{ display: "flex" }}>
                <WhitePanel>
                  <PredictionModelUsage companyId={params.row._id} />
                </WhitePanel>
                <div style={{ width: "16px" }} />
                <WhitePanel>
                  <ReportUsage companyId={params.row._id} />
                </WhitePanel>
                <div style={{ flex: 1 }} />
              </Box>
            );
          }}
        />
      </WhitePanel>
      {openCompanyFormDialog && (
        <CompanyFormDialog
          company={targetCompany}
          open
          onClose={() => setOpenCompanyFormDialog(false)}
          onNewCreated={(created: ICompany) => {
            const parentCompany = companies.find(
              (c) => c._id === created.vendorCompanyId
            );
            const newCompany = {
              ...created,
              path: (parentCompany
                ? [...parentCompany.path, created._id]
                : [created._id]) as string[],
            };
            setCompanies([newCompany, ...companies]);
            setOpenCompanyFormDialog(false);
          }}
          onUpdated={(updatedCompany) => {
            const tempCompanies = [...companies];
            const targetIndex = tempCompanies.findIndex(
              (b) => b._id == updatedCompany._id
            );
            if (targetIndex > -1) {
              const parentCompany = companies.find(
                (c) => c._id === updatedCompany.vendorCompanyId
              );
              const updatedCompanyWithPath = {
                ...updatedCompany,
                path: (parentCompany
                  ? [...parentCompany.path, updatedCompany._id]
                  : [updatedCompany._id]) as string[],
              };
              tempCompanies.splice(targetIndex, 1, updatedCompanyWithPath);
              setCompanies(tempCompanies);
              setOpenCompanyFormDialog(false);
            }
          }}
        />
      )}
      {openConfirmDialog ? (
        <ConfirmDialog
          title={
            <Box sx={{ color: "error.main" }}>{`Delete ${
              isSuperAdmin ? "Lab" : "Client"
            }`}</Box>
          }
          content={
            <>{`Are you sure you want to delete ${selectedIds.length} ${
              isSuperAdmin ? "Lab" : "Client"
            }${selectedIds.length > 1 ? "s" : ""}?`}</>
          }
          onCancel={() => setOpenConfirmDialog(false)}
          onConfirm={() => {
            handleDeleteCompanies();
            setOpenConfirmDialog(false);
          }}
          confirmButtonColor="error"
        />
      ) : null}
      {openUserFormDialog && (
        <UserFormDialog
          open
          onClose={() => setOpenUserFormDialog(false)}
          clientCompany={targetCompany}
          onNewCreated={() => {
            fetchCompanies();
            setOpenUserFormDialog(false);
          }}
          // onUpdated={(updatedUser) => {
          //   const tempUsers = [...users];
          //   const targetIndex = tempUsers.findIndex(
          //     (p) => p._id == updatedUser._id
          //   );
          //   if (targetIndex > -1) {
          //     tempUsers.splice(targetIndex, 1, updatedUser);
          //     setUsers(tempUsers);
          //     setOpenUserFormDialog(false);
          //   }
          // }}
        />
      )}
    </>
  );
}
