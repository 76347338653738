export const getCoordinates = async (country: string, region: string) => {
  try {
    if (!country) return undefined;
    const response = await fetch(
      `https://nominatim.openstreetmap.org/search?country=${country}${
        region ? `&state=${region.toLowerCase()}` : ""
      }&format=json`
    );
    const data = await response.json();
    if (data.length > 0) {
      const { lat, lon } = data[0];
      return { lat, lon };
    } else {
      console.error("No results found.");
    }
  } catch (error) {
    console.error("Error fetching coordinates:", error);
    throw error;
  }
};
