import { LicenseInfo } from "@mui/x-license-pro";
import ReactDOM from "react-dom/client";

import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";

serviceWorkerRegistration.register();

LicenseInfo.setLicenseKey(window.env.MATERIAL_UI_LICENSE_KEY as string);
const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
root.render(<App />);

reportWebVitals();
