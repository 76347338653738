import Drawer from "@mui/material/Drawer";

import SideDrawerMenu from "./SideDrawerMenu";
import { IVersionsObject } from "./VersionsDialog";
import { useAppContext } from "../../../../contexts/useStorageContext";
import { actionTypes } from "../../../../types/state";

export interface SideDrawerProps {
  width?: number;
  anchor?: "left" | "right" | "top" | "bottom";
  backendVersions?: IVersionsObject;
}

export default function SideDrawer({
  anchor,
  width,
  backendVersions,
}: SideDrawerProps) {
  const { dispatch, state } = useAppContext();
  const { openSideDrawer } = state;
  return (
    <>
      {/* <Drawer
        anchor={anchor || "left"}
        open={openSideDrawer}
        variant="persistent"
        onClose={() => dispatch!({ type: actionTypes.setOpenSideDrawer, value: false })}
        sx={{
          display: { xs: "none", lg: "block" },
        }}
      >
        <SideDrawerMenu width={width} backendVersions={backendVersions || {}} />
      </Drawer> */}
      <Drawer
        anchor={anchor || "left"}
        open={openSideDrawer}
        variant="temporary"
        onClose={() =>
          dispatch!({ type: actionTypes.setOpenSideDrawer, value: false })
        }
        // sx={{
        //   display: { xs: "block", lg: "none" },
        // }}
      >
        <SideDrawerMenu width={width} backendVersions={backendVersions || {}} />
      </Drawer>
    </>
  );
}
