import { EncryptStorage } from "encrypt-storage";
export const encryptStorage = new EncryptStorage(window.env.ENCRYPT_STORAGE_KEY as string, {});

const EncryptStorageAuthTokenKey = "auth-token";
export const encryptStorageUtils = {
  setAuthTokenFromStorage: (authToken: string) => {
    return encryptStorage.setItem(EncryptStorageAuthTokenKey, authToken);
  },
  getAuthTokenFromStorage: () => {
    return encryptStorage.getItem(EncryptStorageAuthTokenKey);
  },
  clearKeyCloakTokens: () => {
    encryptStorage.removeItem(EncryptStorageAuthTokenKey);
  },
};
