/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as React from "react";
import GeneralPopoverWrapper from "../Common/GeneralPopoverWrapper";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import IconButton from "@mui/material/IconButton";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import Box from "@mui/material/Box";
import exportService from "../../../service/export.service";
import { IUploadDownloadLogPopulated } from "../../../types/exportLog";
import ExportLogListItem from "./ExportLogListItem";
import SimpleSearchInputWithDebounce from "../Common/SimpleSearchInputWithDebounce";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { ISampleRecordGroup, SampleGroupType } from "../../../types/sample";

export default function ExportLogPopover({
  viewSampleGroup,
}: {
  viewSampleGroup?: (group: ISampleRecordGroup) => void;
}) {
  const [exportLogs, setExportLogs] = React.useState<
    IUploadDownloadLogPopulated[]
  >([]);
  const [page, setPage] = React.useState(0);
  const [pageSize, setPageSize] = React.useState(10);
  const [searchTerm, setSearchTerm] = React.useState("");
  const [totalCount, setTotalCount] = React.useState(0);
  const [currentTab, setCurrentTab] = React.useState(0);

  const fetchExportLogs = async () => {
    const { data, count } =
      currentTab == 0
        ? await exportService.getExportLogs({
            page,
            pageSize,
            searchTerm,
          })
        : await exportService.getUploadLogs({
            page,
            pageSize,
            searchTerm,
          });
    setExportLogs(data);
    setTotalCount(count);
  };

  React.useEffect(() => {
    fetchExportLogs();
  }, [page, pageSize, searchTerm]);

  React.useEffect(() => {
    if (page == 0 && searchTerm == "") {
      fetchExportLogs();
    } else {
      setPage(0);
      setSearchTerm("");
    }
  }, [currentTab]);

  return (
    <GeneralPopoverWrapper
      anchorHorizontal="right"
      transformHorizontal="right"
      triggerElement={
        <IconButton size="small">
          <AccessTimeIcon />
        </IconButton>
      }
      popoverContent={
        <Box sx={{ minWidth: "380px" }}>
          <Box sx={{ borderBottom: "solid 1px #ddd" }}>
            <Tabs
              value={currentTab}
              onChange={(e, val) => setCurrentTab(val)}
              aria-label="basic tabs example"
            >
              <Tab key={`tab-export`} label="Download Logs" value={0} />
              <Tab key={`tab-upload`} label="Upload Logs" value={1} />
            </Tabs>
          </Box>
          <Box
            px={2}
            py={1}
            sx={{
              borderBottom: "solid 1px #ddd",
              fontWeight: "500",
            }}
          >
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <div style={{ flex: 1 }}>
                <SimpleSearchInputWithDebounce
                  placeholder="Search logs..."
                  searchTerm={searchTerm}
                  onChangeSearchTerm={(changed) => setSearchTerm(changed)}
                  sx={{
                    "& input": { padding: "5px 10px 5px 0px" },
                    mr: 1,
                    my: "4px",
                  }}
                />
              </div>
              <Box>
                <IconButton
                  onClick={() => setPage(page - 1)}
                  size="small"
                  disabled={page == 0}
                >
                  <ArrowBackIcon />
                </IconButton>
                <IconButton
                  onClick={() => setPage(page + 1)}
                  size="small"
                  disabled={(page + 1) * pageSize > totalCount}
                >
                  <ArrowForwardIcon />
                </IconButton>
              </Box>
            </Box>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginTop: "5px",
              }}
            >
              {totalCount
                ? new Array(Math.ceil(totalCount / pageSize))
                    .fill(undefined)
                    .map((el, i) => (
                      <Box
                        key={i}
                        style={{
                          cursor: "pointer",
                          flex: 1,
                          height: "4px",
                          borderRadius: "100px",
                          margin: "0px 2px",
                          background: i == page ? "#9cc6f2" : "#ededed",
                        }}
                        onClick={() => setPage(i)}
                      />
                    ))
                : null}
            </div>
          </Box>
          <Box sx={{ maxHeight: "400px", overflow: "auto" }}>
            {exportLogs.map((exportLog) => (
              <Box key={exportLog._id}>
                <ExportLogListItem
                  exportLog={exportLog}
                  type={
                    currentTab == 0
                      ? SampleGroupType.DownloadLog
                      : SampleGroupType.UploadLog
                  }
                  onClickViewSamples={viewSampleGroup}
                />
              </Box>
            ))}
          </Box>
        </Box>
      }
      tooltipTitle="Export Logs"
    />
  );
}
