import * as React from "react";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import { styled } from "@mui/material/styles";
import KeyboardReturnIcon from "@mui/icons-material/KeyboardReturn";
import Box from "@mui/material/Box";
import { fDateTime, fToNow } from "../../../helpers/formatTime";
import { Chip, CircularProgress, IconButton, Tooltip } from "@mui/material";
import { IArchivedDocumentation } from "../../../types/documentation";

const InfoTag = styled(Box)(() => ({
  background: "#dedede",
  padding: "2px 5px",
  borderRadius: "5px",
  marginRight: "5px",
  display: "flex",
  alignItems: "center",
}));

export default function DocumentationVersionListItem({
  isLatest,
  archivedVersion,
  onRevert,
  processing,
}: {
  isLatest?: boolean;
  archivedVersion: IArchivedDocumentation;
  onRevert: () => void;
  processing?: boolean;
}) {
  return (
    <Box px={2} py={1} sx={{ position: "relative", flex: 1 }}>
      <Box
        sx={{
          fontWeight: "500",
          color: "#007ad0",
          marginBottom: "3px",
          display: "flex",
          alignItems: "center",
        }}
      >
        {`V ${archivedVersion.version}`}
        {isLatest ? (
          <Box sx={{ color: "success.main", ml: 2 }}>(Latest)</Box>
        ) : null}
      </Box>
      <Box
        sx={{
          fontSize: "14px",
          opacity: "0.8",
          marginBottom: "6px",
        }}
      >
        {archivedVersion.changedFields?.length ? (
          <div
            style={{ display: "flex", alignItems: "center", flexWrap: "wrap" }}
          >
            <span style={{ marginRight: "4px", fontSize: "13px" }}>
              Changed Fields:{" "}
            </span>
            {archivedVersion.changedFields?.map((field) => (
              <Chip
                key={field}
                label={field}
                size="small"
                sx={{ mr: "4px", my: "2px" }}
                variant="outlined"
              />
            ))}
          </div>
        ) : null}
        {archivedVersion.revertedTo ? (
          <div
            style={{ display: "flex", alignItems: "center", flexWrap: "wrap" }}
          >
            <span style={{ marginRight: "4px", fontSize: "13px" }}>
              Reverted To:{" "}
            </span>
            <Chip
              label={`V ${archivedVersion.revertedTo}`}
              size="small"
              sx={{ mr: "4px", my: "2px" }}
              variant="outlined"
            />
          </div>
        ) : null}
      </Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          fontSize: "12px",
          opacity: "0.8",
        }}
      >
        <InfoTag>
          <PersonOutlineIcon sx={{ fontSize: "14px", mr: "2px" }} />
          {archivedVersion.actionBy?.fullname}
        </InfoTag>
        <InfoTag
          sx={{
            "&:hover .from-now": { display: "none !important" },
            "&:hover .exact-date": { display: "inline !important" },
          }}
        >
          <AccessTimeIcon sx={{ fontSize: "14px", mr: "2px" }} />
          <div className="from-now">{fToNow(archivedVersion.createdAt)}</div>
          <div className="exact-date" style={{ display: "none" }}>
            {fDateTime(archivedVersion.createdAt)}
          </div>
        </InfoTag>
      </Box>
      {!isLatest ? (
        <Tooltip title="Revert to this version" placement="right" arrow>
          <IconButton
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
              onRevert();
            }}
            sx={{ position: "absolute", right: "4px", top: "15px" }}
          >
            <KeyboardReturnIcon />
          </IconButton>
        </Tooltip>
      ) : null}
      {processing ? (
        <Box
          sx={{
            position: "absolute",
            left: "0px",
            top: "0px",
            width: "100%",
            height: "100%",
            background: "#fff5",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress />
        </Box>
      ) : null}
    </Box>
  );
}
