import { PaginationParams } from "../../types/tables";

export const DefaultRowsPerPageOptions = [25, 50, 100, 300];
export const defaultPaginationParams: PaginationParams = {
  page: 0,
  rowsPerPage: DefaultRowsPerPageOptions[0],
  pageSize: DefaultRowsPerPageOptions[0],
  rowsPerPageOptions: DefaultRowsPerPageOptions,
  count: 0,
  includeTotalCount: true,
  includeDisabled: false,
};
