/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as React from "react";
import GeneralPopoverWrapper from "../Common/GeneralPopoverWrapper";
import Box from "@mui/material/Box";
import {
  IArchivedDocumentation,
  IDocumentation,
} from "../../../types/documentation";
import documentationService from "../../../service/documentationService";
import { CircularProgress, MenuItem } from "@mui/material";
import DocumentationVersionListItem from "./DocumentationVersionListItem";

export default function DocumentationVersionsPopover({
  documentationId,
  onViewVersion,
  onReverted,
  triggerElement,
}: {
  documentationId: string;
  onViewVersion: (version: IDocumentation) => void;
  onReverted: (revertedDoc: IDocumentation) => void;
  triggerElement: JSX.Element;
}) {
  const [archivedVersions, setArchivedVersions] = React.useState<
    IArchivedDocumentation[]
  >([]);
  const [fetching, setFetching] = React.useState(false);
  const [processingVersion, setProcessingVersion] = React.useState(0);

  const fetchArchivedVersions = async () => {
    setFetching(true);
    try {
      const result = await documentationService.fetchArchivedVersionsList(
        documentationId
      );
      setArchivedVersions(result || []);
      setFetching(false);
    } catch (error) {
      setFetching(false);
    }
  };

  const handleViewVersion = async (version: number) => {
    try {
      setProcessingVersion(version);
      const result = await documentationService.fetchArchivedVersion(
        documentationId,
        version
      );
      if (result) {
        onViewVersion(result.documentation);
      }
      setProcessingVersion(0);
    } catch (error) {
      setProcessingVersion(0);
    }
  };

  const handleRevert = async (version: number) => {
    try {
      setProcessingVersion(version);
      const result = await documentationService.revertToOldVersion(
        documentationId,
        version
      );
      if (result) {
        onReverted(result);
      }
      setProcessingVersion(0);
    } catch (error) {
      setProcessingVersion(0);
    }
  };

  return (
    <GeneralPopoverWrapper
      onClickTriggerElement={() => fetchArchivedVersions()}
      anchorHorizontal="right"
      transformHorizontal="left"
      triggerElement={triggerElement}
      popoverContent={
        <Box sx={{ width: "380px" }}>
          <Box
            px={2}
            py={1}
            sx={{
              borderBottom: "solid 1px #ddd",
              fontWeight: "500",
            }}
          >
            Archived Versions
          </Box>
          <Box
            sx={{
              maxHeight: "400px",
              overflow: "auto",
              minHeight: "300px",
              position: "relative",
            }}
          >
            {archivedVersions.map((aDoc, i) => (
              <MenuItem
                onClick={() => {
                  handleViewVersion(aDoc.version);
                }}
                key={aDoc._id}
                sx={{ borderBottom: "solid 1px #e2e2e2", p: 0 }}
              >
                <DocumentationVersionListItem
                  isLatest={i == 0}
                  processing={processingVersion == aDoc.version}
                  archivedVersion={aDoc}
                  onRevert={() => {
                    handleRevert(aDoc.version);
                  }}
                />
              </MenuItem>
            ))}
            {fetching ? (
              <Box
                sx={{
                  position: "absolute",
                  top: "0px",
                  left: "0px",
                  width: "100%",
                  height: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  background: "#fff5",
                }}
              >
                <CircularProgress />
              </Box>
            ) : null}
          </Box>
        </Box>
      }
      tooltipTitle="See Sample Groups"
    />
  );
}
