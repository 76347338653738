import Box from "@mui/material/Box";
import PrintIcon from "@mui/icons-material/Print";
import * as React from "react";
import { styled } from "@mui/material/styles";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import AddIcon from "@mui/icons-material/Add";
import MessageIcon from "@mui/icons-material/Message";
import { IDocumentation } from "../../../types/documentation";
import { AutoResizeTextField } from "../Common/AutoResizeTextField";
import AttachedFileItem from "../Common/AttachedFileItem";
import AnyAssetFileUploader from "../Common/AnyAssetFileUploader";
import { Button, Chip, CircularProgress, Grid } from "@mui/material";
import SampleGroupPopover from "../SampleGroup/SampleGroupPopover";
import ProgressButton from "../Common/ProgressButton";
import documentationService from "../../../service/documentationService";
import DocumentationSubjectSelect from "./DocumentationSubjectSelect";
import useWindowHeight from "../../../hooks/useWindowHeight";
import CommentEditForm from "../Common/Comment/CommentEditForm";
import { useAppContext } from "../../../contexts/useStorageContext";
import { SpecialPermissions } from "../../../types/user";
import DocumentationVersionsPopover from "./DocumentationVersionsPopover";
import { uniqBy } from "lodash";
import DocumentationPrintDialog from "./DocumentationPrintDialog";

const AddFileBox = styled(Box)(() => ({
  width: "40px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  background: "#eee",
  border: "dashed 2px #bbb",
  borderRadius: "5px",
  height: "40px",
  cursor: "pointer",
  "&:hover": {
    background: "#ddd",
  },
  marginBottom: "8px",
}));

const FieldLabel = styled(Box)(() => ({
  fontSize: "14px",
  marginBottom: "8px",
  color: "#007ad0",
  fontWeight: "500",
  "&.changed": {
    color: "#ff7f00",
  },
}));

const FieldSection = styled(Box)(() => ({
  marginBottom: "16px",
}));

export default function DocumentationForm({
  documentation,
  onCreate,
  onUpdate,
  commentsCount,
  loadingComments,
  onClickComments,
}: {
  documentation?: IDocumentation;
  onCreate?: (created: IDocumentation) => void;
  onUpdate?: (updated: IDocumentation) => void;
  commentsCount?: number;
  loadingComments?: boolean;
  onClickComments?: () => void;
}) {
  const { state } = useAppContext();
  const { loggedInUserInfo } = state;
  const windowHeight = useWindowHeight();
  const [editingDocumentation, setEditingDocumentation] =
    React.useState<IDocumentation>({
      title: "",
    });
  const [openResultFilesUploadDialog, setOpenResultFilesUploadDialog] =
    React.useState(false);
  const [processing, setProcessing] = React.useState(false);
  const [loadingDocumentation, setLoadingDocumentation] = React.useState(false);
  const [showCommentForm, setShowCommentForm] = React.useState(false);
  const [changedFields, setChangedFields] = React.useState<string[]>([]);
  const [openPrintDialog, setOpenPrintDialog] = React.useState(false);

  const handleCreate = async () => {
    try {
      setProcessing(true);
      const created = await documentationService.createNewDocumentation(
        editingDocumentation
      );
      onCreate && onCreate(created);
      setProcessing(false);
    } catch (error) {
      setProcessing(false);
    }
  };

  const handleUpdate = async () => {
    try {
      setProcessing(true);
      const updatedVersion = await documentationService.updateDocumentation(
        editingDocumentation,
        changedFields
      );
      onUpdate &&
        onUpdate({ ...editingDocumentation, version: updatedVersion });
      setEditingDocumentation({
        ...editingDocumentation,
        version: updatedVersion,
      });
      setProcessing(false);
      setChangedFields([]);
    } catch (error) {
      setProcessing(false);
    }
  };

  const fetchFullDocumentationData = async (id: string) => {
    try {
      setLoadingDocumentation(true);
      const result = await documentationService.fetchDocumentationById(id);
      setEditingDocumentation(result);
    } catch (error) {
    } finally {
      setLoadingDocumentation(false);
    }
  };
  const isCurrentVersion =
    !documentation || documentation.version == editingDocumentation.version;

  React.useEffect(() => {
    if (documentation?._id && editingDocumentation?._id != documentation?._id) {
      fetchFullDocumentationData(documentation._id);
    }
    if (!documentation?._id) {
      setEditingDocumentation({
        title: "",
      });
    }
    setChangedFields([]);
  }, [documentation]);

  return (
    <Box sx={{ position: "relative" }}>
      <Box
        sx={{
          height: `${windowHeight - 56 - 48 - 32 - 80}px`,
          overflowY: "auto",
          pr: 1,
        }}
      >
        <Box>
          {!documentation ? (
            <Box
              sx={{
                mb: 2,
                color: "#444",
                fontSize: "23px",
                textAlign: "center",
              }}
            >
              New Documenation
            </Box>
          ) : null}
          <FieldSection>
            <FieldLabel
              className={
                changedFields.some((f) => f.includes("Subject"))
                  ? "changed"
                  : ""
              }
            >
              Subject *
            </FieldLabel>
            <Box>
              <DocumentationSubjectSelect
                disabled={!isCurrentVersion}
                value={editingDocumentation.subject}
                onChange={(newVal) => {
                  setEditingDocumentation({
                    ...editingDocumentation,
                    subject: newVal,
                  });
                  if (documentation && documentation.subject != newVal) {
                    if (!changedFields.includes("Subject"))
                      setChangedFields([...changedFields, "Subject"]);
                  } else {
                    setChangedFields(
                      changedFields.filter((f) => f != "Subject")
                    );
                  }
                }}
              />
            </Box>
          </FieldSection>

          <FieldSection>
            <FieldLabel
              className={
                changedFields.some((f) => f.includes("Title")) ? "changed" : ""
              }
            >
              Documentation Title *
            </FieldLabel>
            <AutoResizeTextField
              disabled={!isCurrentVersion}
              value={editingDocumentation.title || ""}
              onChange={(e) => {
                const newVal = e.target.value;
                setEditingDocumentation({
                  ...editingDocumentation,
                  title: newVal,
                });
                if (documentation && documentation.title != newVal) {
                  if (!changedFields.includes("Title"))
                    setChangedFields([...changedFields, "Title"]);
                } else {
                  setChangedFields(changedFields.filter((f) => f != "Title"));
                }
              }}
            />
          </FieldSection>
          <FieldSection>
            <FieldLabel
              className={
                changedFields.some((f) => f.includes("Abstract"))
                  ? "changed"
                  : ""
              }
            >
              Abstract *
            </FieldLabel>
            <AutoResizeTextField
              disabled={!isCurrentVersion}
              sx={{ fontSize: "16px" }}
              minRows={3}
              value={editingDocumentation.abstract || ""}
              onChange={(e) => {
                const newVal = e.target.value;
                setEditingDocumentation({
                  ...editingDocumentation,
                  abstract: newVal,
                });
                if (documentation && documentation.abstract != newVal) {
                  if (!changedFields.includes("Abstract"))
                    setChangedFields([...changedFields, "Abstract"]);
                } else {
                  setChangedFields(
                    changedFields.filter((f) => f != "Abstract")
                  );
                }
              }}
            />
          </FieldSection>
          <FieldSection>
            <FieldLabel
              className={
                changedFields.some((f) => f.includes("Material"))
                  ? "changed"
                  : ""
              }
            >
              Material Methods
            </FieldLabel>
            <AutoResizeTextField
              disabled={!isCurrentVersion}
              sx={{ fontSize: "16px" }}
              minRows={3}
              value={editingDocumentation.materialMethods || ""}
              onChange={(e) => {
                const newVal = e.target.value;
                setEditingDocumentation({
                  ...editingDocumentation,
                  materialMethods: newVal,
                });
                if (documentation && documentation.materialMethods != newVal) {
                  if (!changedFields.includes("Material Methods"))
                    setChangedFields([...changedFields, "Material Methods"]);
                } else {
                  setChangedFields(
                    changedFields.filter((f) => f != "Material Methods")
                  );
                }
              }}
            />
          </FieldSection>
          <FieldSection>
            <FieldLabel
              className={
                changedFields.some((f) => f.includes("Results"))
                  ? "changed"
                  : ""
              }
            >
              Results / Conclusions
            </FieldLabel>
            <AutoResizeTextField
              disabled={!isCurrentVersion}
              sx={{ fontSize: "16px" }}
              minRows={3}
              value={editingDocumentation.result || ""}
              onChange={(e) => {
                const newVal = e.target.value;
                setEditingDocumentation({
                  ...editingDocumentation,
                  result: newVal,
                });
                if (documentation && documentation.result != newVal) {
                  if (!changedFields.includes("Results / Conclusions"))
                    setChangedFields([
                      ...changedFields,
                      "Results / Conclusions",
                    ]);
                } else {
                  setChangedFields(
                    changedFields.filter((f) => f != "Results / Conclusions")
                  );
                }
              }}
            />
          </FieldSection>
          <FieldSection sx={{ display: "flex" }}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={6}>
                <FieldLabel
                  className={
                    changedFields.some((f) => f.includes("Files"))
                      ? "changed"
                      : ""
                  }
                >
                  Result Files
                </FieldLabel>
                <Box>
                  {editingDocumentation.docUrls?.map((f, i) => (
                    <AttachedFileItem
                      key={i}
                      url={f}
                      onRemove={() => {
                        const updatedDocUrls = (
                          editingDocumentation.docUrls || []
                        ).filter((rf) => rf != f);
                        setEditingDocumentation({
                          ...editingDocumentation,
                          docUrls: updatedDocUrls,
                        });
                        if (
                          documentation &&
                          (documentation.docUrls || []).sort()?.join(",") !=
                            updatedDocUrls.sort().join(",")
                        ) {
                          if (!changedFields.includes("Result Files"))
                            setChangedFields([
                              ...changedFields,
                              "Result Files",
                            ]);
                        } else {
                          setChangedFields(
                            changedFields.filter((f) => f != "Result Files")
                          );
                        }
                      }}
                      mb={8}
                    />
                  ))}
                  {isCurrentVersion ? (
                    <AddFileBox
                      onClick={() => setOpenResultFilesUploadDialog(true)}
                    >
                      <AddIcon />
                    </AddFileBox>
                  ) : null}
                </Box>
              </Grid>
              <Grid item xs={12} md={6}>
                <FieldLabel
                  className={
                    changedFields.some((f) => f.includes("Group"))
                      ? "changed"
                      : ""
                  }
                >
                  Related Sample Groups
                </FieldLabel>
                <Box>
                  {editingDocumentation.sampleGroups?.map((g) => (
                    <Chip
                      key={g._id}
                      label={g.name}
                      onDelete={() => {
                        const updatedSampleGroups = (
                          editingDocumentation.sampleGroups || []
                        ).filter((rg) => rg._id != g._id);
                        setEditingDocumentation({
                          ...editingDocumentation,
                          sampleGroups: updatedSampleGroups,
                        });
                        if (
                          documentation &&
                          (documentation.sampleGroups?.map((g) => g._id) || [])
                            .sort()
                            ?.join(",") !=
                            updatedSampleGroups
                              .map((g) => g._id)
                              .sort()
                              .join(",")
                        ) {
                          if (!changedFields.includes("Sample Groups"))
                            setChangedFields([
                              ...changedFields,
                              "Sample Groups",
                            ]);
                        } else {
                          setChangedFields(
                            changedFields.filter((f) => f != "Sample Groups")
                          );
                        }
                      }}
                      sx={{ mb: 1, mr: 1 }}
                    />
                  ))}
                  {isCurrentVersion ? (
                    <SampleGroupPopover
                      triggerElement={
                        <AddFileBox>
                          <AddIcon />
                        </AddFileBox>
                      }
                      selectTooltipTitle={"Select Sample Group"}
                      selectIcon={<AddIcon />}
                      onSelectGroup={(selectedGroup) => {
                        const updatedSampleGroups = uniqBy(
                          [
                            ...(editingDocumentation.sampleGroups || []),
                            selectedGroup,
                          ],
                          (v) => v._id
                        );
                        setEditingDocumentation({
                          ...editingDocumentation,
                          sampleGroups: updatedSampleGroups,
                        });

                        if (
                          documentation &&
                          (documentation.sampleGroups?.map((g) => g._id) || [])
                            .sort()
                            ?.join(",") !=
                            updatedSampleGroups
                              .map((g) => g._id)
                              .sort()
                              .join(",")
                        ) {
                          if (!changedFields.includes("Sample Groups"))
                            setChangedFields([
                              ...changedFields,
                              "Sample Groups",
                            ]);
                        } else {
                          setChangedFields(
                            changedFields.filter((f) => f != "Sample Groups")
                          );
                        }
                      }}
                    />
                  ) : null}
                </Box>
              </Grid>
            </Grid>
          </FieldSection>
        </Box>
        {loadingDocumentation ? (
          <Box
            sx={{
              position: "absolute",
              top: "0px",
              left: "0px",
              width: "100%",
              height: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              background: "#fff6",
            }}
          >
            <CircularProgress />
          </Box>
        ) : null}
      </Box>
      <Box
        pt={1}
        sx={{
          borderTop: "solid 1px #ddd",
          display: "flex",
          alignItems: "center",
        }}
      >
        {loggedInUserInfo?.permissions?.find(
          (p) => p.permissionName == SpecialPermissions.Documentation
        )?.write ? (
          documentation ? (
            <ProgressButton
              disabled={
                !editingDocumentation.subject ||
                !editingDocumentation.title ||
                !changedFields.length ||
                !isCurrentVersion
              }
              onClick={() => handleUpdate()}
              inProgress={processing}
              title={`Save Documentation`}
            />
          ) : (
            <ProgressButton
              disabled={
                !editingDocumentation.subject || !editingDocumentation.title
              }
              onClick={() => handleCreate()}
              inProgress={processing}
              title="Create Documentation"
            />
          )
        ) : null}
        {loggedInUserInfo?.permissions?.find(
          (p) => p.permissionName == SpecialPermissions.Documentation
        )?.write &&
        documentation?.version &&
        !loadingDocumentation ? (
          <DocumentationVersionsPopover
            key={editingDocumentation.version}
            onViewVersion={(viewVersion) => {
              setEditingDocumentation(viewVersion);
            }}
            onReverted={(revertedDoc) => {
              onUpdate && onUpdate(revertedDoc);
              setEditingDocumentation({
                ...revertedDoc,
              });
            }}
            documentationId={documentation._id || ""}
            triggerElement={
              <Button
                color="inherit"
                variant="outlined"
                sx={{ ml: 1, opacity: 0.7 }}
                endIcon={<MoreHorizIcon />}
              >
                {`V ${editingDocumentation.version}`}
                <Box
                  sx={{
                    ml: 1,
                    color: isCurrentVersion ? "success.main" : "warning.main",
                  }}
                >
                  {isCurrentVersion ? "(Latest)" : "(Old)"}
                </Box>
              </Button>
            }
          />
        ) : null}
        <Button
          onClick={() => {
            setOpenPrintDialog(true);
          }}
          startIcon={<PrintIcon />}
          color="inherit"
          variant="outlined"
          sx={{ opacity: 0.7, ml: 1 }}
        >
          Preview Print
        </Button>
        <div style={{ flex: 1 }} />
        {documentation ? (
          <Button
            disabled={loadingComments}
            startIcon={
              loadingComments ? <CircularProgress size={16} /> : <MessageIcon />
            }
            variant="outlined"
            sx={{ ml: 2 }}
            onClick={() => onClickComments && onClickComments()}
          >
            {`${commentsCount || 0} Comment${
              (commentsCount || 0) > 1 ? "s" : ""
            }`}
          </Button>
        ) : null}
      </Box>
      {showCommentForm && loggedInUserInfo && documentation?._id ? (
        <Box
          sx={{
            maxWidth: "600px",
            background: "white",
            position: "absolute",
            bottom: "0px",
            left: "0px",
            width: "100%",
            border: "solid 1px #ddd",
            borderRadius: "3px",
            boxShadow:
              "rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;",
          }}
        >
          <CommentEditForm
            comment={{
              content: "",
              actionBy: loggedInUserInfo,
              resourceId: documentation?._id,
            }}
            onCancel={() => setShowCommentForm(false)}
          />
        </Box>
      ) : null}
      {openResultFilesUploadDialog && (
        <AnyAssetFileUploader
          directory="documentation"
          open
          onClose={() => setOpenResultFilesUploadDialog(false)}
          onSave={(urls) => {
            const updatedDocUrls = uniqBy(
              [...(editingDocumentation.docUrls || []), ...urls],
              (v) => v
            );
            setEditingDocumentation({
              ...editingDocumentation,
              docUrls: updatedDocUrls,
            });
            setOpenResultFilesUploadDialog(false);

            if (
              documentation &&
              (documentation.docUrls || []).sort()?.join(",") !=
                updatedDocUrls.sort().join(",")
            ) {
              if (!changedFields.includes("Result Files"))
                setChangedFields([...changedFields, "Result Files"]);
            } else {
              setChangedFields(
                changedFields.filter((f) => f != "Result Files")
              );
            }
          }}
          title="Upload Files"
          multiple
        />
      )}
      {openPrintDialog && documentation ? (
        <DocumentationPrintDialog
          documentation={editingDocumentation}
          onClose={() => setOpenPrintDialog(false)}
        />
      ) : null}
    </Box>
  );
}
