import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";

export const InfoTag = styled(Box)(() => ({
  background: "#dedede",
  padding: "2px 5px",
  borderRadius: "5px",
  marginRight: "5px",
  display: "flex",
  alignItems: "center",
}));
