import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";
const WhitePanel = styled(Box)(() => ({
  background: "white",
  padding: "14px 16px",
  borderRadius: "10px",
  border: "solid 1px #efefef",
}));

export default WhitePanel;
