/* eslint-disable @typescript-eslint/no-explicit-any */
import { SampleRecordFilterParams } from "../types/common";
import backendApi from "./backendApi";
import { showSnackbar } from "./snackbar.service";

const routePrefix = "datalake/export";

interface exportParams extends SampleRecordFilterParams {
  fileName: string;
  description: string;
  groupName?: string;
  groupDescription?: string;
}
export default {
  async exportData(params: exportParams): Promise<any> {
    try {
      const result = await backendApi()?.post(`${routePrefix}/`, params);
      return result.data;
    } catch (error: any) {
      if (error?.response?.status) {
        showSnackbar(
          "danger",
          "Error",
          error?.response?.data?.message || "API error response"
        );
        return null;
      }
    }
  },
  async getExportLogs({
    page,
    pageSize,
    searchTerm,
    minUid,
    maxUid,
  }: {
    page?: number;
    pageSize?: number;
    searchTerm?: string;
    minUid?: number;
    maxUid?: number;
  }): Promise<any> {
    try {
      const result = await backendApi()?.post(
        `${routePrefix}/get-export-logs`,
        {
          page,
          pageSize,
          searchTerm,
          minUid,
          maxUid,
        }
      );
      return result.data;
    } catch (error: any) {
      if (error?.response?.status) {
        showSnackbar(
          "danger",
          "Error",
          error?.response?.data?.message || "API error response"
        );
        return null;
      }
    }
  },
  async getUploadLogs({
    page,
    pageSize,
    searchTerm,
    companyId,
    onlyPredictionModelUsed,
    fromDate,
    toDate,
  }: {
    page?: number;
    pageSize?: number;
    searchTerm?: string;
    companyId?: string;
    onlyPredictionModelUsed?: boolean;
    fromDate?: Date;
    toDate?: Date;
  }): Promise<any> {
    try {
      const result = await backendApi()?.post(
        `${routePrefix}/get-upload-logs`,
        {
          page,
          pageSize,
          searchTerm,
          companyId,
          onlyPredictionModelUsed,
          fromDate,
          toDate,
        }
      );
      return result.data;
    } catch (error: any) {
      if (error?.response?.status) {
        showSnackbar(
          "danger",
          "Error",
          error?.response?.data?.message || "API error response"
        );
        return null;
      }
    }
  },
  async getDownloadLogById(id: string): Promise<any> {
    try {
      const result = await backendApi()?.get(
        `${routePrefix}/get-download-log-by-id/${id}`
      );
      return result.data;
    } catch (error: any) {
      if (error?.response?.status) {
        showSnackbar(
          "danger",
          "Error",
          error?.response?.data?.message || "API error response"
        );
        throw error;
      }
    }
  },
  async getUploadLogById(id: string): Promise<any> {
    try {
      const result = await backendApi()?.get(
        `${routePrefix}/get-upload-log-by-id/${id}`
      );
      return result.data;
    } catch (error: any) {
      if (error?.response?.status) {
        showSnackbar(
          "danger",
          "Error",
          error?.response?.data?.message || "API error response"
        );
        throw error;
      }
    }
  },
};
