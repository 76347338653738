/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import Box from "@mui/material/Box";
import React from "react";
import CloseIcon from "@mui/icons-material/Close";
import ReactApexChart from "react-apexcharts";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { ISampleRecordPopulated } from "../../../types/sample";
import Chip from "@mui/material/Chip";
import { IconButton } from "@mui/material";

const SpectraChart = ({
  spectraDataItems,
  onRemoveItem,
  onRemoveAll,
  height,
  hideVisibilityButton,
}: {
  spectraDataItems: ISampleRecordPopulated[];
  onRemoveItem?: (ssid: number) => void;
  onRemoveAll?: () => void;
  height?: number;
  hideVisibilityButton?: boolean;
}) => {
  const [visible, setVisible] = React.useState(true);
  const getAllWaveLengths = () => {
    const waveLengths: number[] = [];
    spectraDataItems.map((dataItem) =>
      dataItem.spectraItems?.map((item) => {
        if (!waveLengths.includes(item.wavelength)) {
          waveLengths.push(item.wavelength);
        }
      })
    );
    return waveLengths;
  };
  const [mainChartOptions] = React.useState<ApexCharts.ApexOptions>({
    title: {
      text: "Spectra Insights",
      align: "left",
    },
    chart: {
      id: "spectra",
      group: "1",
      type: "line",
      animations: {
        enabled: false,
      },
    },
    series: spectraDataItems.map((dataItem) => ({
      name:
        `${dataItem.uid?.toString()} ${
          dataItem.sampleNumber ? `(${dataItem.sampleNumber})` : ""
        }` || "",
      data: dataItem.spectraItems?.map((sItem) => sItem.value || null) as any[],
    })),
    xaxis: {
      categories: getAllWaveLengths(),
      type: "numeric",
    },
    yaxis: {
      labels: {
        formatter: function (value) {
          return value?.toFixed(2) || "";
        },
      },
    },
    tooltip: {
      y: {
        formatter: function (value) {
          return value?.toFixed(4);
        },
      },
    },
    stroke: {
      width: 1,
    },
    legend: {
      position: "top",
    },
  });

  return (
    <Box>
      <Box p={2} sx={{ display: "flex" }}>
        <Box sx={{ flex: 1 }}>
          {onRemoveItem
            ? spectraDataItems.map((sItem) => (
                <Chip
                  key={sItem.uid}
                  label={
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <div>{sItem.uid}</div>
                      {sItem.sampleNumber ? (
                        <div
                          style={{ opacity: "0.6", marginLeft: "5px" }}
                        >{`(${sItem.sampleNumber})`}</div>
                      ) : null}
                    </div>
                  }
                  onDelete={(e) => onRemoveItem && onRemoveItem(sItem.uid)}
                  sx={{ mr: "4px", my: "2px" }}
                />
              ))
            : null}
          {onRemoveAll ? (
            <Chip
              label={
                <div style={{ display: "flex", alignItems: "center" }}>
                  <CloseIcon sx={{ mr: "4px" }} />
                  Remove All
                </div>
              }
              sx={{ cursor: "pointer" }}
              onClick={() => onRemoveAll && onRemoveAll()}
            />
          ) : null}
        </Box>
        <div>
          {hideVisibilityButton ? null : (
            <IconButton
              onClick={() => setVisible(!visible)}
              sx={visible ? { mt: "-12px", mr: "-12px" } : undefined}
            >
              {visible ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            </IconButton>
          )}
        </div>
      </Box>
      {visible ? (
        <ReactApexChart
          options={mainChartOptions}
          series={mainChartOptions.series}
          type="line"
          height={height || 600}
        />
      ) : null}

      {/* <ReactApexChart
        options={brushChartOptions}
        series={mainChartOptions.series}
        type="line"
        height={120}
      /> */}
    </Box>
  );
};

export default SpectraChart;
