import { ISampleRecordGroup } from "./sample";
import { IUser } from "./user";

export enum DocumentationSubject {
  NIR_R_AND_D = 1,
  FERMENTATION_R_AND_D = 2,
  PROTOCOLS = 3,
  HARDWARE_CALIBRATIONS = 4,
  OTHER = 5,
}

export const DocumentationSubjectToString: { [key: number]: string } = {
  [DocumentationSubject.NIR_R_AND_D]: "NIR R&D",
  [DocumentationSubject.FERMENTATION_R_AND_D]: "Fermentation R&D",
  [DocumentationSubject.PROTOCOLS]: "Protocols",
  [DocumentationSubject.HARDWARE_CALIBRATIONS]: "Hardware Calibrations",
  [DocumentationSubject.OTHER]: "Other",
};

export const DocumentationSubjectToColor: { [key: number]: string } = {
  [DocumentationSubject.NIR_R_AND_D]: "#dfc5fd",
  [DocumentationSubject.FERMENTATION_R_AND_D]: "#ffcece",
  [DocumentationSubject.PROTOCOLS]: "#9df0e8",
  [DocumentationSubject.HARDWARE_CALIBRATIONS]: "#b1f3a8",
  [DocumentationSubject.OTHER]: "#f2ed9d",
};

export interface IDocumentation {
  _id?: string;
  subject?: DocumentationSubject;
  title: string;
  abstract?: string;
  materialMethods?: string;
  result?: string;
  docUrls?: string[];
  sampleGroups?: ISampleRecordGroup[];
  comments?: string[];
  actionBy?: IUser;
  createdAt?: Date;
  version?: number;
}

export interface IArchivedDocumentation {
  _id: string;
  documentationId: string;
  version: number;
  changedFields: string[];
  revertedTo: number;
  actionBy: IUser;
  documentation?: IDocumentation;
  createdAt: Date;
}
