/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as React from "react";
import { Box, CircularProgress } from "@mui/material";
import { IOnboardingGuide } from "../../types/onboardingGuide";
import useWindowHeight from "../../hooks/useWindowHeight";
import onboardingGuideService from "../../service/onboardingGuide.service";
import WhitePanel from "../components/Common/WhitePanel";
import AddIconButton from "../components/Common/AddIconButton";
import SimpleSearchInputWithDebounce from "../components/Common/SimpleSearchInputWithDebounce";
import OnboardingGuideFormDialog from "../components/OnboardingGuide/OnboardingGuideFormDialog";
import OnboardingGuideListItem from "../components/OnboardingGuide/OnboardingGuideListItem";
import OnboardingGuideDisplayPanel from "../components/OnboardingGuide/OnboardingGuideDisplayPanel";

export default function OnboardingGuidesPage() {
  const [guides, setGuides] = React.useState<IOnboardingGuide[]>([]);
  const windowHeight = useWindowHeight();
  const [loading, setLoading] = React.useState(false);
  const [targetGuide, setTargetGuide] = React.useState<IOnboardingGuide>();
  const [searchTerm, setSearchTerm] = React.useState("");
  const [openGuideForm, setOpenGuideForm] = React.useState(false);

  const fetchGuides = async () => {
    try {
      setLoading(true);
      const result = await onboardingGuideService.getAll();
      setGuides(result || []);
      if (result?.[0]) {
        setTargetGuide(result?.[0]);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  React.useEffect(() => {
    fetchGuides();
  }, []);

  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          fontSize: "24px",
          fontWeight: "500",
          opacity: 0.8,
          mb: 1,
        }}
      >
        Onboarding Guides
      </Box>
      <WhitePanel>
        <Box sx={{ display: "flex", position: "relative" }}>
          <Box
            sx={{
              width: "350px",
              maxWidth: "100%",
              borderRight: "solid 1px #ddd",
              p: "4px",
              pr: 2,
              transition: "0.1s",
              overflow: "hidden",
            }}
          >
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <AddIconButton
                onClick={() => {
                  setTargetGuide(undefined);
                  setOpenGuideForm(true);
                }}
              />
              <SimpleSearchInputWithDebounce
                fullWidth
                searchTerm={searchTerm}
                onChangeSearchTerm={(changed) => setSearchTerm(changed)}
              />
            </Box>
            {loading ? (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  height: "400px",
                }}
              >
                <CircularProgress />
              </Box>
            ) : !guides.length ? (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  height: "400px",
                }}
              >
                No Guides
              </Box>
            ) : (
              <Box
                sx={{
                  maxHeight: `${windowHeight - 56 - 48 - 32 - 96}px`,
                  overflow: "auto",
                  mt: 2,
                }}
              >
                {guides.map((guide) => (
                  <OnboardingGuideListItem
                    key={guide._id}
                    selected={targetGuide?._id === guide._id}
                    searchTerms={[searchTerm]}
                    onboardingGuide={guide}
                    onClick={() => {
                      setTargetGuide(guide);
                    }}
                    onEdit={() => {
                      setTargetGuide(guide);
                      setOpenGuideForm(true);
                    }}
                  />
                ))}
              </Box>
            )}
          </Box>
          <Box sx={{ flex: 1, px: 2 }}>
            {targetGuide ? (
              <OnboardingGuideDisplayPanel onboardingGuide={targetGuide} />
            ) : null}
          </Box>
        </Box>
        {openGuideForm ? (
          <OnboardingGuideFormDialog
            open={openGuideForm}
            onClose={() => setOpenGuideForm(false)}
            onboardingGuide={targetGuide}
            onCreated={(created) => {
              setGuides([...guides, created]);
              setOpenGuideForm(false);
              setTargetGuide(created);
            }}
            onUpdated={(updated) => {
              setGuides(
                guides.map((guide) =>
                  guide._id === updated._id ? updated : guide
                )
              );
              setTargetGuide(updated);
              setOpenGuideForm(false);
            }}
          />
        ) : null}
      </WhitePanel>
    </Box>
  );
}
