/* eslint-disable @typescript-eslint/no-explicit-any */
import { MenuItem, TextField } from "@mui/material";
import * as React from "react";
import { camelCaseToHumanReadable } from "../../../helpers/utils";

export enum SpectraDevice {
  BlueSun = "BlueSun",
}

interface ISpectraDeviceSelectProps {
  label?: string;
  placeholder?: string;
  value?: SpectraDevice;
  onChange: (newVal?: SpectraDevice) => void;
  error?: boolean;
  helperText?: string;
}
export default function SpectraDeviceSelect(props: ISpectraDeviceSelectProps) {
  const { value, onChange, helperText, error } = props;
  return (
    <TextField
      label={props.label}
      select
      fullWidth
      placeholder="Spectra Device"
      helperText={helperText}
      error={error}
      value={value || ""}
      size="small"
      onChange={(e) => {
        onChange(e.target.value as unknown as SpectraDevice);
      }}
    >
      {Object.values(SpectraDevice).map((device, i) => (
        <MenuItem key={`device-type-${i}`} value={device as SpectraDevice}>
          {camelCaseToHumanReadable(device)}
        </MenuItem>
      ))}
    </TextField>
  );
}
