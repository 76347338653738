/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as React from "react";
import { DataGridPro } from "@mui/x-data-grid-pro";
import Box from "@mui/material/Box";
import ErrorIcon from "@mui/icons-material/Error";
import {
  ISampleRecordCreationPayload,
  ISampleRecordPopulated,
} from "../../../types/sample";
import Tooltip from "@mui/material/Tooltip";
import Chip from "@mui/material/Chip";

export default function SpectraDataTable({
  analysisItems,
  headerFields,
  existingDataset,
  showValidation,
}: {
  analysisItems: ISampleRecordCreationPayload[];
  headerFields?: number[];
  existingDataset: { uid: number; companyUid: number; sampleNumber: string }[];
  showValidation?: boolean;
}) {
  const getNumberValueColumn = (field: number) => {
    return {
      field: field.toString(),
      headerName: field.toString(),
      width: 100,
      renderCell: (params: any) => {
        const row: ISampleRecordCreationPayload = params.row;
        return (
          <div>
            {row.spectraItems
              ?.find((item) => item.wavelength == Number(field))
              ?.value?.toString() || ""}
          </div>
        );
      },
    } as any;
  };

  const columns = [
    {
      field: "uid",
      headerName: "",
      width: 70,
      renderCell: (params: any) => {
        const row: ISampleRecordCreationPayload = params.row;
        return (
          <div style={{ display: "flex", alignItems: "center" }}>
            {existingDataset?.some(
              (d) => d.sampleNumber == row.sampleNumber
            ) ? (
              <Tooltip
                title="Sample ID already existing. Will be updated by this."
                placement="right"
                arrow
              >
                <ErrorIcon color="warning" sx={{ mr: 1 }} />
              </Tooltip>
            ) : showValidation ? (
              <Chip
                size="small"
                label="NEW"
                variant="outlined"
                color="success"
              />
            ) : null}

            {existingDataset?.find((d) => d.sampleNumber == row.sampleNumber)
              ?.uid || ""}
          </div>
        );
      },
    },
    {
      field: "companyUid",
      headerName: "Lab SSID",
      width: 70,
      renderCell: (params: any) => {
        const row: ISampleRecordCreationPayload = params.row;
        return (
          <div style={{ display: "flex", alignItems: "center" }}>
            {row.companyUid ||
              existingDataset?.find((d) => d.sampleNumber == row.sampleNumber)
                ?.companyUid}
          </div>
        );
      },
    },
    {
      field: "sampleNumber",
      headerName: "Sample ID",
      width: 100,
      renderCell: (params: any) => {
        const row: ISampleRecordCreationPayload = params.row;
        return (
          <div style={{ display: "flex", alignItems: "center" }}>
            {row.sampleNumber ? (
              row.sampleNumber
            ) : (
              <Tooltip title="This is required field" placement="right" arrow>
                <ErrorIcon color="error" />
              </Tooltip>
            )}
          </div>
        );
      },
    },
    {
      field: "predictionAiModel",
      headerName: "Prediction Model",
      width: 150,
      valueGetter: (params: any) => {
        const row: ISampleRecordPopulated = params.row;
        return row.predictionAiModel?.title || row.predictionAiModel?.date;
      },
    },
    ...(
      headerFields ||
      analysisItems?.[0]?.spectraItems?.map((f) => f.wavelength) ||
      []
    ).map((f) => getNumberValueColumn(f)),
  ];

  return (
    <Box
      sx={{
        height: "500px",
        margin: "auto",
      }}
    >
      <DataGridPro
        isCellEditable={() => false}
        getRowId={(row) => row.companyUid || row.temporaryId || 0}
        columns={columns}
        rows={analysisItems}
        rowHeight={38}
        disableRowSelectionOnClick
        initialState={{ pinnedColumns: { left: ["uid", "companyUid"] } }}
      />
    </Box>
  );
}
