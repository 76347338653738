/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import CloseIcon from "@mui/icons-material/Close";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ErrorIcon from "@mui/icons-material/Error";
import { useDropzone } from "react-dropzone";
import FilePresentIcon from "@mui/icons-material/FilePresent";
import Box from "@mui/material/Box";
import Dialog from "@mui/material/Dialog";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import * as React from "react";
import "react-image-crop/dist/ReactCrop.css";
import ProgressButton from "./ProgressButton";
import { Button, CircularProgress, Paper } from "@mui/material";
import assetsService from "../../../service/assets.service";
import { uniqBy } from "lodash";

export enum AwsLocation {
  OrugenDatalake = "datalake",
  IntakeAssets = "intake",
}

export const AwsLocationToBaseUrl = {
  [AwsLocation.OrugenDatalake]:
    process.env.REACT_APP_DATA_LAKE_ASSET_BUCKET_BASE_URL,
  [AwsLocation.IntakeAssets]:
    process.env.REACT_APP_INTAKE_ASSET_BUCKET_BASE_URL,
};

export interface FileInfo {
  name: string;
  size: number;
  blob: Blob;
}
export interface AnyAssetFileUploaderProps {
  open: boolean;
  onClose: () => void;
  onSave: (urls: string[]) => void;
  title?: string;
  ratio?: number;
  multiple?: boolean;
  location?: AwsLocation;
  directory?: string;
}

export default function AnyAssetFileUploader({
  open,
  onClose,
  onSave,
  title,
  multiple,
  location,
  directory,
}: AnyAssetFileUploaderProps) {
  const baseUrl = AwsLocationToBaseUrl[location || AwsLocation.IntakeAssets];
  const [files, setFiles] = React.useState<FileInfo[]>([]);
  const [uploading, setUploading] = React.useState(false);
  const [checkingExistence, setCheckingExistence] = React.useState(false);
  const [existingFiles, setExistingFiles] = React.useState<
    { key: string; size: number }[]
  >([]);
  const [acceptedExistingFiles, setAcceptedExistingFiles] = React.useState<
    { key: string; size: number }[]
  >([]);

  const checkFileExistence = async (
    files: { fileName: string; size: number }[]
  ) => {
    try {
      setCheckingExistence(true);
      const existingFiles = await assetsService.checkFileExistence({
        files,
        location: location || AwsLocation.IntakeAssets,
      });
      setCheckingExistence(false);
      setExistingFiles(existingFiles);
    } catch (error) {
      setCheckingExistence(false);
    }
  };

  const onFilesLoad = React.useCallback(
    (acceptedFiles: any[]) => {
      try {
        const promises = acceptedFiles.map((file) => {
          const fileName = file?.name || "";
          return new Promise<FileInfo>((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = (e) => {
              try {
                if (!reader.result) {
                  reject("Reader result is empty");
                  return;
                }
                const blob = new Blob([reader.result], { type: file.type });
                resolve({
                  name: fileName,
                  size: file.size,
                  blob,
                });
              } catch (error) {
                reject(error);
              }
            };
            reader.readAsArrayBuffer(file);
          });
        });

        Promise.all(promises)
          .then((tempFiles) => {
            let updatedFiles;
            if (multiple) {
              updatedFiles = uniqBy([...files, ...tempFiles], (v) => v.name);
            } else {
              updatedFiles = tempFiles;
            }
            setFiles(updatedFiles);
            checkFileExistence(
              updatedFiles.map((f) => ({ fileName: f.name, size: f.size }))
            );
          })
          .catch((error) => {
            console.error("Error processing files:", error);
          });
      } catch (error) {
        console.error("Error:", error);
      }
    },
    [files]
  );

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: onFilesLoad,
    multiple: multiple,
  });

  const uploadFile = async () => {
    if (!files?.length) return;
    const acceptedFiles = acceptedExistingFiles.filter((f) =>
      files.some((file) => f.key.endsWith(file.name) && f.size == file.size)
    );
    const filesToUpload = files.filter(
      (file) =>
        !acceptedFiles.some(
          (f) => f.key.endsWith(file.name) && f.size == file.size
        )
    );
    setUploading(true);
    const URLs = await Promise.all(
      filesToUpload.map((f) =>
        assetsService.uploadFile({
          blob: f.blob,
          fileName: f.name,
          location,
          directory,
        })
      )
    );

    const acceptedFileUrls = acceptedFiles.map(
      (f) => `${baseUrl}${baseUrl?.endsWith("/") ? "" : "/"}${f.key}`
    );
    onSave([...(URLs || []), ...(acceptedFileUrls || [])]);
    setUploading(false);
  };

  return (
    <Dialog open={open} maxWidth={false}>
      <Box
        p={2}
        sx={{ background: "white", maxWidth: "100%", minWidth: "450px" }}
      >
        <Box pb={1} sx={{ display: "flex", justifyContent: "space-between" }}>
          <Typography variant="h6">{title || "Upload File"}</Typography>
          <IconButton onClick={onClose} sx={{ marginTop: "-10px" }}>
            <CloseIcon />
          </IconButton>
        </Box>
        <Box key={files.length}>
          <Box
            sx={{
              maxWidth: "500px",
            }}
          >
            {files.map((file, i) => {
              const existingMatchedFile = existingFiles.find(
                (existingFile) =>
                  existingFile.size == file.size &&
                  existingFile.key?.endsWith(file.name)
              );
              const isExistingFileAccepted =
                existingMatchedFile &&
                acceptedExistingFiles.find(
                  (acceptedFile) => acceptedFile.key == existingMatchedFile?.key
                );
              return (
                <Paper
                  key={i}
                  sx={{
                    flex: 1,
                    padding: "10px 20px",
                    border: "solid 1px #eee",
                    mb: 1,
                  }}
                >
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <Box mr={2}>
                      <FilePresentIcon
                        sx={{
                          fontSize: "50px",
                          opacity: "0.6",
                          marginLeft: "-7px",
                        }}
                      />
                    </Box>
                    <Box sx={{ flex: 1 }}>
                      <Box sx={{ mb: "5px" }}>{file.name}</Box>
                      <Box
                        sx={{ fontSize: "14px", opacity: "0.8" }}
                      >{`Size: ${file.size}`}</Box>
                      {checkingExistence ? (
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            mt: 1,
                            fontSize: "14px",
                            opacity: 0.7,
                            color: "warning.main",
                          }}
                        >
                          <CircularProgress size={16} sx={{ mr: 1 }} />
                          Checking existence...
                        </Box>
                      ) : null}
                    </Box>
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <IconButton
                        onClick={() => {
                          setFiles(files.filter((f) => f.name != file.name));
                        }}
                      >
                        <CloseIcon />
                      </IconButton>
                    </Box>
                  </div>
                  {existingMatchedFile ? (
                    <div style={{ fontSize: "14px" }}>
                      {isExistingFileAccepted ? (
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            color: "success.main",
                          }}
                        >
                          <CheckCircleIcon sx={{ mr: "4px" }} />
                          <span style={{ marginRight: "8px" }}>
                            Accepted to use existing file
                          </span>
                          <Button
                            size="small"
                            variant="outlined"
                            onClick={() =>
                              setAcceptedExistingFiles(
                                acceptedExistingFiles.filter(
                                  (f) =>
                                    !(
                                      f.key == existingMatchedFile.key &&
                                      f.size == existingMatchedFile.size
                                    )
                                )
                              )
                            }
                            disableElevation
                            sx={{
                              borderRadius: "100px",
                              minHeight: "unset",
                              maxHeight: "24px",
                            }}
                            color="warning"
                          >
                            Cancel
                          </Button>
                        </Box>
                      ) : (
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            color: "warning.main",
                          }}
                        >
                          <ErrorIcon sx={{ mr: "4px" }} />
                          <span style={{ marginRight: "8px" }}>
                            File already existing with same name, same size
                          </span>
                          <Button
                            size="small"
                            variant="contained"
                            onClick={() => {
                              const updatedAcceptedExistingFiles = [
                                ...acceptedExistingFiles,
                                existingMatchedFile,
                              ];

                              if (
                                !files.some(
                                  (file) =>
                                    !updatedAcceptedExistingFiles.some(
                                      (f) =>
                                        f.key?.endsWith(file.name) &&
                                        f.size == file.size
                                    )
                                )
                              ) {
                                const urls = updatedAcceptedExistingFiles.map(
                                  (f) =>
                                    `${baseUrl}${
                                      baseUrl?.endsWith("/") ? "" : "/"
                                    }${f.key}`
                                );
                                onSave(urls);
                              } else {
                                setAcceptedExistingFiles(
                                  updatedAcceptedExistingFiles
                                );
                              }
                            }}
                            disableElevation
                            sx={{
                              borderRadius: "100px",
                              minHeight: "unset",
                              maxHeight: "24px",
                            }}
                            color="success"
                          >
                            Use it
                          </Button>
                        </Box>
                      )}
                    </div>
                  ) : null}
                </Paper>
              );
            })}
          </Box>
          {multiple || !files.length ? (
            <Box>
              <div {...getRootProps()}>
                <input {...getInputProps()} />
                <Box
                  sx={{
                    border: "dashed 2px #ddd",
                    borderRadius: "5px",
                    height: files.length ? "50px" : "300px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    opacity: "0.8",
                    background: "#f3f3f3",
                  }}
                >
                  Click here to add local file
                </Box>
              </div>
            </Box>
          ) : null}
          <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 2 }}>
            <Button
              variant="outlined"
              size="small"
              sx={{ mr: 1 }}
              onClick={() => {
                setFiles([]);
                setExistingFiles([]);
                setAcceptedExistingFiles([]);
              }}
            >
              Cancel All Files
            </Button>
            <ProgressButton
              disabled={uploading || !files?.length || checkingExistence}
              inProgress={uploading}
              onClick={() => uploadFile()}
              title="Upload"
            />
          </Box>
        </Box>
      </Box>
    </Dialog>
  );
}
