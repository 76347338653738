/* eslint-disable @typescript-eslint/no-unused-vars */
import Box from "@mui/material/Box";
import React from "react";
import Dialog from "@mui/material/Dialog";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";

import { ICompany } from "../../../types/company";
import CompanyDetailForm from "./CompanyDetailForm";

export default function CompanyFormDialog({
  company,
  open,
  onClose,
  onUpdated,
  onNewCreated,
}: {
  company?: ICompany;
  open: boolean;
  onClose: () => void;
  onUpdated?: (updateInfo: ICompany) => void;
  onNewCreated?: (created: ICompany) => void;
}): React.ReactElement {
  return (
    <>
      <Dialog open={open} maxWidth="sm">
        <Box sx={{ background: "white", width: "1000px", maxWidth: "100%" }}>
          <Box p={2} pb={0} sx={{ display: "flex", justifyContent: "space-between" }}>
            <Typography variant="h6">{company ? "Company Details" : "New Company"}</Typography>
            <IconButton onClick={onClose}>
              <CloseIcon />
            </IconButton>
          </Box>
          <Box p={2}>
            <CompanyDetailForm
              company={company}
              onNewCreated={(created) => onNewCreated && onNewCreated(created)}
              onUpdated={(updateInfo) => onUpdated && onUpdated(updateInfo)}
            />
          </Box>
        </Box>
      </Dialog>
    </>
  );
}
