/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as React from "react";
import {
  Box,
  Tabs,
  Tab,
  Button,
  IconButton,
  MenuItem,
  Chip,
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import EditIcon from "@mui/icons-material/Edit";
import {
  IPredictionAiModel,
  PredictionModelProduct,
} from "../../../types/predictionModel";
import predictionModelService from "../../../service/predictionModel.service";
import { DataGridPro } from "@mui/x-data-grid-pro";
import PredictionModelFormDialog from "./PredictionModelFormDialog";
import { uniqBy } from "lodash";
import GeneralPopoverWrapper from "../Common/GeneralPopoverWrapper";
import AttachedFileItem from "../Common/AttachedFileItem";

export default function PredictionModelsTabs() {
  const [currentTab, setCurrentTab] = React.useState(
    PredictionModelProduct.TMR
  );
  const [predictionModels, setPredictionModels] = React.useState<
    IPredictionAiModel[]
  >([]);
  const [openFormDialog, setOpenFormDialog] = React.useState(false);
  const [targetModel, setTargetModel] = React.useState<
    IPredictionAiModel | undefined
  >(undefined);

  const columns = [
    {
      field: "date",
      headerName: "Date",
      width: 120,
      valueGetter: (params: any) => new Date(params.value).toLocaleDateString(),
    },
    {
      field: "title",
      headerName: "Title",
      width: 200,
    },
    {
      field: "description",
      headerName: "Description",
      width: 300,
    },
    {
      field: "link",
      headerName: "Link",
      width: 200,
      renderCell: (params: any) => (
        <a href={params.value} target="_blank" rel="noopener noreferrer">
          {params.value}
        </a>
      ),
    },
    {
      field: "files",
      headerName: "Files",
      width: 100,
      renderCell: (params: any) => {
        const row: IPredictionAiModel = params.row;
        return (
          <GeneralPopoverWrapper
            disablePop={!row.files?.length}
            triggerElement={
              <Chip
                variant="outlined"
                sx={{ cursor: "pointer" }}
                size="small"
                label={`${row.files?.length || 0} File${
                  row.files?.length && row.files.length > 1 ? "s" : ""
                }`}
              />
            }
            popoverContent={
              <Box p={2}>
                <div style={{ marginBottom: "12px" }}>Attached Files</div>
                <div>
                  {row.files?.map((calFile) => (
                    <AttachedFileItem key={calFile} url={calFile} mb={8} />
                  ))}
                </div>
              </Box>
            }
          />
        );
      },
      sortable: false,
      filterable: false,
    },
    {
      field: "actionBy",
      headerName: "Added By",
      width: 150,
      valueGetter: (params: any) => params.value?.fullname || "",
    },
    {
      field: "actions",
      headerName: "Action",
      width: 100,
      renderCell: (params: any) => {
        return (
          <>
            <GeneralPopoverWrapper
              triggerElement={
                <IconButton>
                  <MoreVertIcon />
                </IconButton>
              }
              popoverContent={
                <Box>
                  <MenuItem
                    onClick={() => {
                      setTargetModel(params.row);
                      setOpenFormDialog(true);
                    }}
                  >
                    <EditIcon sx={{ mr: 1, fontSize: "20px" }} />
                    Edit
                  </MenuItem>
                </Box>
              }
            />
          </>
        );
      },
    },
  ];

  const fetchPredictionModels = React.useCallback(async () => {
    try {
      const result = await predictionModelService.getAllPredictionModels();
      if (result) {
        setPredictionModels(result);
      }
    } catch (error) {
      console.error("Error fetching prediction models:", error);
    }
  }, []);

  React.useEffect(() => {
    fetchPredictionModels();
  }, [fetchPredictionModels]);

  React.useEffect(() => {
    if (!openFormDialog) {
      setTargetModel(undefined);
    }
  }, [openFormDialog]);

  return (
    <Box>
      <Box
        sx={{
          borderBottom: "solid 1px #ddd",
          display: "flex",
          alignItems: "center",
        }}
      >
        <div style={{ flex: 1 }}>
          <Tabs
            value={currentTab}
            onChange={(e, val) => setCurrentTab(val)}
            aria-label="basic tabs example"
          >
            <Tab
              key={`tab-export`}
              label="TMR"
              value={PredictionModelProduct.TMR}
            />
            <Tab
              key={`tab-upload`}
              label="CS"
              value={PredictionModelProduct.CS}
            />
          </Tabs>
        </div>
        <div>
          <Button
            onClick={() => setOpenFormDialog(true)}
            variant="contained"
            color="primary"
          >
            Add New Version
          </Button>
        </div>
      </Box>
      <Box>
        <DataGridPro
          autoHeight
          rows={predictionModels.filter(
            (model) => model.product === currentTab
          )}
          columns={columns}
          disableRowSelectionOnClick
          pinnedColumns={{
            right: ["actions"],
          }}
        />
      </Box>
      {openFormDialog && (
        <PredictionModelFormDialog
          open={openFormDialog}
          onClose={() => setOpenFormDialog(false)}
          onSave={(savingModel) => {
            const tempModels = [...predictionModels];
            const targetIndex = tempModels.findIndex(
              (model) => model._id === savingModel._id
            );
            if (targetIndex > -1) {
              tempModels.splice(
                tempModels.findIndex((model) => model._id === savingModel._id),
                1,
                savingModel
              );
            } else {
              tempModels.unshift(savingModel);
            }
            setPredictionModels(tempModels);
            setOpenFormDialog(false);
          }}
          initialModel={targetModel}
        />
      )}
    </Box>
  );
}
