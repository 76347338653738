import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
} from "@mui/material";
import { useState } from "react";
import userService from "../../service/user.service";
import ProgressButton from "./Common/ProgressButton";

function ContactSupportDialog({
  open,
  onClose,
}: {
  open: boolean;
  onClose: () => void;
}) {
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [sending, setSending] = useState(false);

  const handleSendMessage = () => {
    setSending(true);
    try {
      userService.sendContactSupportMessage(subject, message).then(() => {
        onClose();
      });
    } catch (error) {
      setSending(false);
    }
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Contact Support</DialogTitle>
      <DialogContent sx={{ width: "500px" }}>
        <TextField
          autoFocus
          margin="dense"
          id="subject"
          label="Subject *"
          type="text"
          fullWidth
          variant="outlined"
          value={subject}
          onChange={(e) => setSubject(e.target.value)}
          sx={{ mb: 3 }}
        />
        <TextField
          multiline
          minRows={5}
          autoFocus
          margin="dense"
          id="message"
          label="Your Message *"
          type="text"
          fullWidth
          variant="outlined"
          value={message}
          onChange={(e) => setMessage(e.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <ProgressButton
          disabled={!subject || !message}
          title="Send"
          onClick={handleSendMessage}
          inProgress={sending}
        />
      </DialogActions>
    </Dialog>
  );
}

export default ContactSupportDialog;
