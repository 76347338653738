import { useState, useEffect } from "react";

const useWindowHeight = () => {
  const [windowHeight, setWindowHeight] = useState(window.innerHeight);

  useEffect(() => {
    const handleResize = () => {
      setWindowHeight(window.innerHeight);
      // Optional: Call your function here
      console.log("Window height changed to: " + window.innerHeight + "px");
      // yourFunction();
    };

    window.addEventListener("resize", handleResize);

    // Call the function initially to set up the state with the current height
    handleResize();

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return windowHeight;
};

export default useWindowHeight;
