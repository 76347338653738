/* eslint-disable @typescript-eslint/no-explicit-any */
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import * as React from "react";
import { useDropzone } from "react-dropzone";
import * as XLSX from "xlsx";

export default function LoadDataFile({
  onParseData,
}: {
  onParseData: (parsedData: any, fileName?: string) => void;
}) {
  const [isInvalidFile, setIsInvalidFile] = React.useState(false);

  const onDrop = React.useCallback((acceptedFiles: any[]) => {
    if (!acceptedFiles?.length) return;
    try {
      const reader = new FileReader();
      reader.onload = (e) => {
        if (!e?.target) {
          setIsInvalidFile(true);
          return;
        }
        try {
          setIsInvalidFile(false);
          const data = e.target.result;
          const workbook = XLSX.read(data, { type: "binary" });
          const sheetName = workbook.SheetNames[0];
          const sheet = workbook.Sheets[sheetName];
          const jsonData = XLSX.utils.sheet_to_json(sheet, { header: 1 });
          onParseData(jsonData, acceptedFiles[0]?.name);
        } catch (error) {
          console.error(error);
          setIsInvalidFile(true);
        }
      };
      reader.readAsBinaryString(acceptedFiles[0]);
    } catch (error) {
      console.error(error);
      setIsInvalidFile(true);
    }
  }, []);
  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: {
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [
        ".xlsx",
      ],
      "text/csv": [".csv"],
    },
  });

  return (
    <Box>
      <Box
        sx={{
          background: "#eee",
          border: "dashed 2px #bbb",
          height: "250px",
          borderRadius: "8px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          minWidth: "400px",
        }}
        {...getRootProps()}
      >
        <input {...getInputProps()} />
        <p style={{ textAlign: "center" }}>
          Drag and drop file here, <br />
          or click to select file
        </p>
      </Box>
      {isInvalidFile && (
        <Typography color="error" fontWeight={500} mt={1}>
          Invalid Data Format
        </Typography>
      )}
    </Box>
  );
}
