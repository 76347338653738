import WhitePanel from "../../components/Common/WhitePanel";
import { Content, Section, SectionTitle } from "./privacyPolicy";

export default function TermsAndConditions() {
  return (
    <WhitePanel sx={{ padding: "24px 48px" }}>
      <Section>
        <SectionTitle sx={{ fontSize: "25px", marginBottom: "36px" }}>
          Terms & Conditions for Fermentrics & Orugen Service
        </SectionTitle>
        <Content>
          This page sets out the terms and conditions on which we, Fermentrics
          Technologies Inc., provide our Orugen Service (as defined below).
        </Content>
        <Content>
          Please read these terms and conditions carefully before signing up for
          the Orugen Service. By registering on the Orugen site, you agree to be
          bound by these terms and conditions.
        </Content>
        <Content>
          You should print a copy of these terms and conditions for future
          reference. Please understand that if you do not accept these terms and
          conditions, you are not entitled to use our Service.
        </Content>
      </Section>

      <Section>
        <SectionTitle>1. Definitions</SectionTitle>
        <Content>
          1.1 In these Conditions (unless the context otherwise requires):
        </Content>
        <Content>
          Fermentrics means Fermentrics Technologies Inc. (961 Campbell Drive,
          Arnprior, Ontario, Canada);
        </Content>
        <Content>
          Agreement means the agreement between the User and Fermentrics for the
          provision of the Orugen Service;
        </Content>
        <Content>Fermentrics Website means www.fermentrics.com</Content>
        <Content>
          Orugen Service means the microsite available via the Fermentrics
          website and located at www.orugen.com
        </Content>
        <Content>
          Orugen Online Results means the results generated using Orugen, as
          described in Condition 3.1 below;
        </Content>
        <Content>
          Orugen means the online service for assessment of feed quality
          described in Condition 3 below and available on the Orugen Microsite;
        </Content>
        <Content>Conditions means these terms and conditions;</Content>
        <Content>NIR means Near Infra-Red Reflectance;</Content>
        <Content>
          NIR Calibrations means the NIR calibrations developed by Fermentrics
          and/or its licensors;
        </Content>
        <Content>
          Samples means the physical samples including feed & feed ingredients;
        </Content>
        <Content>
          Unique Account means the personal account that a User shall be
          required to set up in order to use the Orugen, as further described in
          Condition 4 below;
        </Content>
        <Content>
          User means any user of the Orugen, which may be an individual or
          company, as applicable;
        </Content>
        <Content>
          You means the User or any individual or company wishing to register to
          use the Orugen (and Your shall take its meaning accordingly).
        </Content>
      </Section>
      <Section>
        <SectionTitle>2. Formation of Agreement</SectionTitle>
        <Content>
          2.1 Fermentrics provides the Orugen subject to these Conditions which
          shall govern and be deemed to be incorporated into the Agreement
          between Fermentrics and the User to the exclusion of all other terms
          and conditions. Any other terms and conditions referred to in any
          letter, form of agreement or other communication sent by the User to
          Fermentrics shall not be binding on Fermentrics. The acceptance or use
          of the Orugen Service by the User shall indicate unqualified
          acceptance of these Conditions and the User’s agreement to be bound by
          these Conditions.
        </Content>
        <Content>
          2.2 No variation, amendment or waiver of these Conditions shall be
          valid unless expressly accepted in writing by a director of
          Fermentrics.
        </Content>
        <Content>
          2.3 Fermentrics reserves the right to amend these Conditions at any
          time by amending this page. Users are expected to check this page from
          time to time to take notice of any changes made. Any changes are
          binding on Users.
        </Content>
      </Section>
      <Section>
        <SectionTitle>3. Orugen</SectionTitle>
        <Content>
          3.1 The Feed Quality Service consists of an on-line facility made
          available by Fermentrics to provide Users with predicted nutrient
          parameter measures in respect of uploaded NIR spectra (the “Orugen
          Results”), such results being generated using the NIR Calibrations.
          All NIR Calibrations (Software Models) are the property of Fermentrics
          Technologies Inc. unless otherwise agreed upon.
        </Content>
        <Content>
          3.2 Fermentrics shall use its reasonable endeavors to respond to all
          enquiries surrounding the Feed Quality Service but has the right to
          refuse provision of the Feed Quality Service to a User at any time
          without cause.
        </Content>
        <Content>
          3.3 Users shall be responsible for (i) running NIR analysis on their
          Samples, thereby generating NIR spectra and (ii) uploading the NIR
          spectra they have generated onto the Orugen Microsite through their
          Unique Account or via API. Orugen Online Results will be generated and
          will be sent back to the User within a reasonable period of the time
          of upload.
        </Content>
        <Content>
          3.4 If a Sample or the NIR spectra in respect of any Sample does not
          fit within the NIR Calibrations, the relevant User will be notified
          and may be asked to send the Sample to Fermentrics for analysis.
          Fermentrics will investigate the results of the analysis in
          conjunction with the NIR spectra uploaded. Fermentrics will report
          back the findings to the User.
        </Content>
        <Content>
          3.5 In order to validate the results of the Orugen Service,
          Fermentrics will randomly check certain samples. In the event
          Fermentrics notifies the User that a Sample is required for analysis,
          that User shall be responsible for sending the Fermentrics.
        </Content>
      </Section>
      <Section>
        <SectionTitle>
          4. On-line Accounts and Access to and Use of the Orugen Microsite
        </SectionTitle>
        <Content>
          4.1 You may request to sign up to use the Orugen Service on the Orugen
          Microsite by creating a user name and password, in which case (and
          provided your request has been approved by a duly authorized
          representative of Fermentrics), the requested credentials can be used
          to access Your Unique Account and You can start using the Feed Quality
          Service. The User is responsible for maintaining the confidentiality
          of their account and password and changing if appropriate.
        </Content>
        <Content>
          4.2 Access to the Fermentrics Website is permitted on a temporary
          basis, and Fermentrics reserves the right to withdraw or amend the
          Feed Quality Service at any time without notice. Fermentrics will not
          be liable if for any reason the Fermentrics Website or the Orugen
          Service is unavailable at any time or for any period and does not
          warrant that the operation of the Fermentrics Website will be
          uninterrupted or error free. From time to time, Fermentrics may
          restrict access to some parts of the Fermentrics Website, or the
          entire Fermentrics Website, to Users.
        </Content>
        <Content>
          4.3 Fermentrics has the right to terminate a User’s Unique Account at
          any time without cause.
        </Content>
        <Content>
          4.4 Commentary and other materials posted on the Orugen Microsite are
          not intended to amount to advice on which reliance should be placed.
          Fermentrics therefore disclaims all liability and responsibility
          arising from any reliance placed on such materials by any User or by
          anyone who may be informed of any of its contents.
        </Content>
        <Content>
          4.5 Users must not misuse the Orugen Microsite by knowingly
          introducing viruses, trojans, worms, logic bombs or other material
          which is malicious or technologically harmful. Users must not attempt
          to gain unauthorized access to the Orugen Microsite, the server on
          which the Orugen Microsite is stored or any server, computer or
          database connected to the Orugen Microsite.
        </Content>
        <Content>
          4.6 If a User would like their consultant to have access to the Orugen
          Online Results, the User should notify Fermentrics of this and provide
          Fermentrics with the consultant’s email address. Fermentrics will then
          provide the consultant with a username and password that will allow
          them to access the User’s Unique Account. Users will be responsible
          for:
        </Content>
        <Content>
          (a) informing the relevant consultant that their details will be sent
          to Fermentrics in order to facilitate their access to the User’s
          Unique Account ;
        </Content>
        <Content>
          (b) ensuring that the details supplied to Fermentrics in relation to
          the relevant consultant are accurate; and
        </Content>
        <Content>
          (c) notifying Fermentrics without delay using the information on the
          Contact section of the Fermentrics Website if they want the relevant
          consultant’s access to their Unique Account to be terminated.
        </Content>
        <Content>
          Fermentrics shall not be responsible for a consultant retaining access
          to a User’s Unique Account if the User has not informed Fermentrics
          that it wants the consultant’s access to be terminated. The User is
          aware and accepts that upon receiving notice that the User wants the
          consultant’s access to be terminated, Fermentrics shall have five
          working days in which to implement such request.
        </Content>
      </Section>
      <Section>
        <SectionTitle>5. Service Standards</SectionTitle>
        <Content>
          5.1 Fermentrics shall provide the Orugen Service with all reasonable
          care and skill and in accordance with good industry practice.
        </Content>
        <Content>
          5.2 The User accepts that the Orugen Online Results are confined to
          the actual Samples analyzed and that Fermentrics does not give any
          assurances that the bulk from which those Samples were taken would
          yield the same results if analyzed or be of the same quality or
          composition.
        </Content>
        <Content>
          5.3 Each party shall comply with all legislation, statutes,
          regulations and other enactments having the force of law from time to
          time that are applicable to it in respect of the provision and
          procurement of the Orugen Service.
        </Content>
        <Content>
          5.4 Fermentrics reserves the right to withhold any information
          resulting from calculations based on a User’s NIR spectra.
        </Content>
      </Section>
      <Section>
        <SectionTitle>6. Samples</SectionTitle>
        <Content>
          6.1 Unless Fermentrics otherwise agrees, a User who has been prompted
          to send their Sample to Fermentrics for the purpose of analysis shall
          be responsible for arranging the delivery of Samples to Fermentrics.
          The User shall bear the risk of loss, damage to or deterioration of
          Samples whilst in transit. The User is responsible for any costs
          incurred in connection with the delivery of Samples to Fermentrics.
        </Content>
        <Content>
          6.2 If Fermentrics agrees to arrange the collection or delivery of
          Samples in doing so it is acting as the agent of the User.
        </Content>
        <Content>
          6.3 The User warrants and undertakes that, as regards any Samples that
          Fermentrics agrees to handle in accordance with Condition 6.2 above:
        </Content>
        <Content>
          a. it is the owner of all such Samples or where it is not the owner it
          has the consent of the owner for Fermentrics to handle the Samples;
          and
        </Content>
        <Content>
          b. the Samples are safe for handling and are not of a nature that is
          likely to cause injury or harm to persons handling them or damage to
          the equipment or vehicles used to handle them.
        </Content>
        <Content>
          6.4 The User shall ensure that all Samples to be handled by
          Fermentrics in accordance with Condition 6.2 above are supplied in
          appropriate packaging and that this packaging and the Samples
          contained in it are labelled with and accompanied by appropriate
          safety labelling and handling precautions and the correct address
          label.
        </Content>
        <Content>
          6.5 In any event, the User shall, without prejudice to its other
          obligations, inform Fermentrics in writing before dispatching Samples
          to Fermentrics of any special precautions to be taken by Fermentrics
          in the handling or care of the Samples and any suspected health and
          safety risks associated with such Samples. Fermentrics reserves the
          right to refuse to provide the Orugen Service, without liability to
          the User, in relation to any Samples which Fermentrics reasonably
          considers may pose a threat to the health and safety of Fermentrics
          staff. In these circumstances, the User shall not dispatch the Samples
          before Fermentrics has given consent.
        </Content>
        <Content>
          6.6 In sending Samples to Fermentrics for analysis the User is thereby
          licensing Fermentrics to handle, process and deal with such Samples
          and to carry out the analysis on them as it thinks fit in its absolute
          discretion.
        </Content>
        <Content>
          6.7 When Fermentrics has completed its analysis on Samples it is free
          to dispose of them as it chooses unless notified by the User within 5
          days from receipt of the results that it would like the Sample
          retained for collection.
        </Content>
      </Section>
      <Section>
        <SectionTitle>7. Liability</SectionTitle>
        <Content>
          7.1 If the User believes that Fermentrics has provided the Orugen
          Service in a defective manner it will immediately inform Fermentrics
          explaining why it considers the Service to be defective, and if
          Fermentrics considers the User’s concerns to be legitimate, it will
          investigate, and may at its discretion repeat the relevant analyses to
          ascertain whether the Orugen Service is defective.
        </Content>
        <Content>
          7.2 Except as set out in these Conditions, any conditions or
          warranties (whether express or implied by statute or common law or
          arising from conduct or a previous course of dealing or trade custom
          or usage or otherwise) are hereby expressly excluded to the fullest
          extent permitted by law. In particular, but without prejudice to the
          above, Fermentrics expressly does not warrant that the Orugen Online
          Results shall be completely accurate or reliable and Fermentrics takes
          no responsibility whatsoever for the way in which the User uses the
          Orugen Online Results nor the consequences of the User’s use of or
          reliance on any of the Orugen Online Results, that being entirely a
          matter for the User and not something that Fermentrics has influence
          over or, in many cases, awareness of. The Orugen Online Results are
          not intended to be relied upon in isolation when the User is making
          any decision relating to its policies or practices and are intended to
          be used as one of a number of considerations in relation to which the
          User will make such decisions. The User accepts that all use of and
          reliance on any Orugen Online Results shall be at the User’s sole risk
          and that Fermentrics shall not be liable, whether in contract, tort or
          otherwise, for the consequences of the User’s use of or reliance on
          any Orugen Online Results.
        </Content>
        <Content>
          7.3 To the extent permitted by law, Fermentrics hereby expressly
          excludes:
        </Content>
        <Content>
          a. liability for any indirect or consequential loss or damage incurred
          by any User in connection with (i) the Orugen Microsite; (ii) the use
          or inability to use the Feed
        </Content>
        <Content>
          Quality Service; or (iii) the Orugen Online Results; and
        </Content>
        <Content>
          b. any of the following types of losses incurred by any User in
          connection with (i)
        </Content>
        <Content>
          the Orugen Microsite; (ii) the use or inability to use the Orugen
          Service; or (iii)
        </Content>
        <Content>
          the Orugen Online Results (whether direct, indirect or consequential):
        </Content>
        <Content>▪ loss of income or revenue;</Content>
        <Content>▪ loss of business;</Content>
        <Content>▪ loss of profits or contracts;</Content>
        <Content>▪ loss of anticipated savings;</Content>
        <Content>▪ loss of data;</Content>
        <Content>▪ loss of goodwill;</Content>
        <Content>▪ wasted management or office time; and</Content>
        <Content>
          whether caused by tort (including negligence), breach of contract or
          otherwise, even if foreseeable, provided that this Condition shall not
          prevent claims for loss of or damage to the User’s tangible property
          or any other claims for direct financial loss that are not excluded by
          any of the categories set out above in respect of which Fermentrics’s
          liability shall be limited to the total amount payable by the User to
          Fermentrics under this Agreement in the calendar month in which the
          occurrence of the event giving rise to the claim arose.
        </Content>
        <Content>
          7.4 Condition 7.3 does not affect Fermentrics’s liability for death or
          personal injury arising from Fermentrics’s negligence, nor
          Fermentrics’s liability for fraudulent misrepresentation or
          misrepresentation as to a fundamental matter, nor any other liability
          which cannot be excluded or limited under applicable law.
        </Content>
        <Content>
          7.5 Fermentrics shall not be liable for any failure to provide the
          Orugen Service or make the Orugen Microsite available as a result of
          circumstances beyond its reasonable control (including, without
          limitation, the amendment or coming into force of any legal provision
          adversely affecting Fermentrics in relation to the performance of the
          Orugen Service, including any Economic Sanctions Law).
        </Content>
      </Section>
      <Section>
        <SectionTitle>8. Intellectual Property</SectionTitle>
        <Content>
          8.1 All material published on the Orugen Microsite (including all
          copyright, trademarks, database rights and other intellectual property
          rights contained therein) and all information calculated or generated
          from uploaded spectra or information taken from that spectra in the
          process of providing the Orugen Service (including, without
          limitation, all Orugen Online Results), is the property of
          Fermentrics. Fermentrics agrees not to identify the source of any
          uploaded spectra to any third party outside Fermentrics. Fermentrics
          grants Users a non-exclusive license to use Orugen Online Results for
          their internal purposes.
        </Content>
      </Section>
      <Section>
        <SectionTitle>9. Cost of Service</SectionTitle>
        <Content>
          9.1 Fermentrics offers the Orugen Online Service to Users at its
          discretion and where offered, the cost of the Orugen Online Service is
          covered by Fermentrics with no additional cost to the User.
          Fermentrics will inform Users of the scope of what is included in
          their subscription to the Orugen Online Service (in terms of number of
          Samples, duration of subscription or otherwise).
        </Content>
      </Section>
      <Section>
        <SectionTitle>
          10. Ethical Trading, Anti-Bribery and Sanctions Compliance
        </SectionTitle>
        <Content>
          10.1 In accordance with Fermentrics’s commitment to sustainable and
          ethical business practices the User warrants and represents that in
          connection with any matter arising under or pursuant to the Agreement
          it shall (i) protect its workers' rights, including by ensuring: safe
          and hygienic working conditions, freedom of association, living wages
          are paid, working hours are not excessive, no discrimination is
          practiced, no harsh or inhumane treatment is allowed and no child
          labor is used; (ii) ensure environmental management programmes are in
          place (iii) (without prejudice to Condition 10.2) not offer, promise,
          give or receive any improper financial payment and/or other improper
          advantage to or from any person, customer or supplier; and (iv) not
          make or offer, directly or indirectly, any payment, gift or other
          advantage to a public official with the intention of influencing them
          and obtaining or retaining an advantage in the conduct of business
        </Content>
        <Content>10.2 In addition, the User:</Content>
        <Content>
          (a) shall comply with all applicable laws, regulations, codes and
          sanctions relating to anti-bribery and anti-corruption including but
          not limited to the Bribery Act 2010 (all of the aforesaid being
          “Relevant Requirements”);
        </Content>
        <Content>
          (b) shall have and shall maintain in place throughout the term of this
          agreement its own policies and procedures, including but not limited
          to adequate procedures under the Bribery Act 2010, to ensure
          compliance with the Relevant Requirements, and will enforce them where
          appropriate;
        </Content>
        <Content>
          (c) shall on request by Fermentrics certify to Fermentrics in writing
          signed by an officer of the User, compliance with this Condition 10.2
          by the User and all persons associated with it. The User shall provide
          such supporting evidence of compliance as the Company may reasonably
          request;
        </Content>
        <Content>
          (d) warrants that neither it nor, to its knowledge, its officers,
          employees, nor any person involved by or for it in the performance of
          the Agreement, is a Sanctioned Person; and
        </Content>
        <Content>
          (e) shall comply with Economic Sanctions Law in all respects related
          to the performance of this Contract and shall not have any dealings or
          transactions with any Sanctioned Person (including in respect of any
          further performance of the services under this Agreement) if such
          dealings or transactions would cause Fermentrics to be in violation,
          or to be subject to a risk of punitive measures being imposed pursuant
          to, any Economic Sanctions Law.
        </Content>
        <Content>
          10.3 For the purposes of this Agreement, “Sanctioned Person” means any
          person, organisation or vessel
        </Content>
        <Content>
          (i) designated on the United Nations Consolidated Lists, the
          Consolidated List of Financial Sanctions Targets maintained by the UK
          HM Treasury, the Office of Foreign Assets Control list of Specially
          Designated Nationals and Blocked Persons, [the US Government's Denied
          Persons List, Entities List, Debarred Parties List and Terrorism
          Exclusion List] or an any list of targeted persons issued under the
          Economic Sanctions Law of any other country (including the European
          Union);
        </Content>
        <Content>
          (ii) that is, or is part of, a government of a Sanctioned Territory;
        </Content>
        <Content>
          (iii) owned or controlled, directly or indirectly, by, or acting on
          behalf of, any of the foregoing; or
        </Content>
        <Content>
          (iv) incorporated within, located within or operating from a
          Sanctioned Territory and subject to any Economic Sanctions Law; or
        </Content>
        <Content>
          (v) otherwise targeted under any Economic Sanctions Law. “Economic
          Sanctions Law” means any laws, regulations, or other binding measures
          of the European Union, any EU member state, the United Nations, the
          United States of America or any other jurisdiction applicable to the
          Parties which relates to economic or trade sanctions, export controls,
          non-proliferation, anti-terrorism or similar restrictions.
        </Content>
        <Content>
          “Sanctioned Territory” means any country or other territory subject to
          a general export, import, financial or investment embargo under
          Economic Sanctions Law from time to time, including without limitation
          Iran, Myanmar, Sudan, Syria, North Korea and Russia/Ukraine.
        </Content>
      </Section>
      <Section>
        <SectionTitle>11. General</SectionTitle>
        <Content>
          11.1 A waiver of any right or remedy under the Agreement is only
          effective if given in writing. Any waiver by Fermentrics of any
          breach, or any default under, any provision of Agreement by the User
          shall not be deemed a waiver of any subsequent breach or default and
          shall not affect the other terms of the Agreement.
        </Content>
        <Content>
          11.2 Subject to Condition 2.3, the Agreement may only be varied by the
          written agreement of both parties, and in the case of Fermentrics must
          be signed by a duly authorized representative of Fermentrics.
        </Content>
        <Content>
          11.3 If, in any particular case, any provision of these Conditions (or
          any part of any provision) shall be held to be invalid, illegal or
          unenforceable by any court or competent authority, or shall not apply
          to the Agreement, that provision or part provision shall, to the
          extent required, be deemed to be deleted and the other terms and
          conditions shall continue in full force and effect and will not in any
          way be impaired. If any provision of these Conditions is held to be
          invalid or unenforceable but would be valid or enforceable if some
          part of the provision were deleted, the provision in question will
          apply with the minimum modifications necessary to make it valid and
          enforceable.
        </Content>
        <Content>
          11.4 Fermentrics may assign the Agreement or sub-contract the whole or
          any part thereof. The User shall not attempt to assign, transfer,
          charge or otherwise deal with its rights or obligations under the
          Contract without the prior written consent of Fermentrics.
        </Content>
        <Content>
          11.5 The rights and remedies of Fermentrics under these Conditions
          shall be cumulative and no right or remedy of Fermentrics set out in
          these terms and conditions shall be deemed to be in lieu of any other
          right or remedy.
        </Content>
        <Content>
          11.6 Nothing in these Conditions shall create or be deemed to create a
          partnership or joint venture or relationship of employer and employee
          or principal and agent between the parties and no employee of one
          party shall be deemed to be or become an employee of the other party.
        </Content>
        <Content>
          11.7 Any notice or communication with Fermentrics shall be delivered
          to the address set out below:
        </Content>
        <Content>Fermentrics™ - Head Office | Lab</Content>
        <Content>Fermentrics Technologies Inc.</Content>
        <Content>Suite 211, Kenwood Corporate Center.</Content>
        <Content>Arnprior, ON, CA, K7S3W4</Content>
        <Content>Ph +1 (613) 880-3024</Content>
        <Content>fermentricsatgmail.com</Content>
        <Content>
          11.8 The parties to the Agreement do not intend that any term of the
          Contract shall be enforceable by a third party under the Contracts
          (Rights of Third Parties) Act 1999.
        </Content>
        <Content>
          11.9 These Conditions and any dispute or claim arising out of or in
          connection with them or their subject matter or formation (including
          non-contractual disputes or claims) shall be governed by and construed
          in accordance with the law of Canada.
        </Content>
      </Section>
    </WhitePanel>
  );
}
