export enum NotificationTitlePrefix {
  IntakeChangeAlert = "Intake Amount Change Alert",
}

export enum TargetApp {
  DataLake = "DataLake",
  IntakeApp = "Intake App",
}

export enum NotificationType {
  Warning = "warning",
  Success = "success",
  Info = "info",
  Error = "error",
}

export interface INewNotificationPayload {
  companyId: string;
  type: NotificationType;
  title: string;
  description: string;
}

export interface INotification {
  _id: string;
  companyId: string;
  type: NotificationType;
  title: string;
  description: string;
  seen?: boolean;
  seenUserIds?: string[];
}
