/* eslint-disable @typescript-eslint/no-explicit-any */
import { IUser, IUserCreationPayload } from "../types/user";
import backendApi from "./backendApi";
import { showSnackbar } from "./snackbar.service";

const routePrefix = "user";

export default {
  async getMyProfile(): Promise<any> {
    try {
      const result = await backendApi()?.get(`${routePrefix}/my-profile`);
      if (result?.status != 200) {
        showSnackbar(
          "danger",
          "Error",
          result?.data.message || "API error response"
        );
        return null;
      }
      return result.data;
    } catch (error: any) {
      if (error?.response?.status) {
        showSnackbar(
          "danger",
          "Error",
          error?.response?.data?.message || "API error response"
        );
        return null;
      }
    }
  },
  async fetchAllUsers(): Promise<any> {
    try {
      const result = await backendApi()?.get(`${routePrefix}/all`);
      if (result?.status != 200) {
        showSnackbar(
          "danger",
          "Error",
          result?.data.message || "API error response"
        );
        return null;
      }
      return result.data;
    } catch (error: any) {
      if (error?.response?.status) {
        showSnackbar(
          "danger",
          "Error",
          error?.response?.data?.message || "API error response"
        );
        return null;
      }
    }
  },
  async deleteUser(userId: string): Promise<any> {
    try {
      const result = await backendApi()?.delete(
        `${routePrefix}/delete/${userId}`
      );
      return result.data;
    } catch (error: any) {
      if (error?.response?.status) {
        showSnackbar(
          "danger",
          "Error",
          error?.response?.data?.message || "API error response"
        );
        return null;
      }
    }
  },
  async addNewUser(userInfo: IUserCreationPayload): Promise<any> {
    try {
      const result = await backendApi()?.post(`${routePrefix}/create`, {
        ...userInfo,
      });
      if (result?.status != 200) {
        showSnackbar(
          "danger",
          "Error",
          result?.data.message || "API error response"
        );
        return null;
      }
      showSnackbar(
        "success",
        "Success",
        result?.data.message || "New user successfully created"
      );
      return result;
    } catch (error: any) {
      if (error?.response?.status) {
        showSnackbar(
          "danger",
          "Error",
          error?.response?.data?.message || "API error response"
        );
        return null;
      }
    }
  },
  async updateUser(user: IUser): Promise<any> {
    try {
      const result = await backendApi()?.post(`${routePrefix}/update`, {
        user,
      });
      if (result?.status != 200) {
        showSnackbar(
          "danger",
          "Error",
          result?.data.message || "API error response"
        );
        return null;
      }
      showSnackbar(
        "success",
        "Success",
        result?.data.message || "User successfully updated"
      );
      return result;
    } catch (error: any) {
      if (error?.response?.status) {
        showSnackbar(
          "danger",
          "Error",
          error?.response?.data?.message || "API error response"
        );
        return null;
      }
    }
  },
  async sendContactSupportMessage(
    subject: string,
    message: string
  ): Promise<any> {
    try {
      const result = await backendApi()?.post(
        `${routePrefix}/contact-support`,
        {
          subject,
          message,
        }
      );
      showSnackbar("success", "Success", "Your message is sent successfully");
      return result.data;
    } catch (error: any) {
      if (error?.response?.status) {
        showSnackbar(
          "danger",
          "Error",
          error?.response?.data?.message || "API error response"
        );
        return null;
      }
    }
  },
};
