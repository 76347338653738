import IconButton from "@mui/material/IconButton";
import AddIcon from "@mui/icons-material/Add";

export default function AddIconButton({
  width,
  height,
  onClick,
}: {
  width?: number;
  height?: number;
  onClick: () => void;
}) {
  return (
    <IconButton
      onClick={onClick}
      sx={{
        borderRadius: "5px",
        backgroundColor: "primary.main",
        color: "white",
        mr: 1,
        width: `${width || 40}px`,
        height: `${height || 40}px`,
        transition: "0.2s",
        transform: "scale(1)",
        "&:hover": {
          transform: "scale(1.1)",
          borderRadius: "5px",
          backgroundColor: "primary.dark",
        },
      }}
    >
      <AddIcon />
    </IconButton>
  );
}
