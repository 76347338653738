import * as React from "react";
import Box from "@mui/material/Box";
import ClosableDialog from "../Common/ClosableDialog";
import Stack from "@mui/material/Stack";
import { Button, TextField } from "@mui/material";

export default function CreateNewSampleGroupDialog({
  dialogTitle,
  recordCount,
  onComplete,
  onClose,
  cancelButtonTitle,
}: {
  dialogTitle?: string;
  recordCount: number;
  onComplete: (name: string, description: string) => void;
  onClose: () => void;
  cancelButtonTitle?: string;
}) {
  const [groupName, setGroupName] = React.useState("");
  const [description, setDescription] = React.useState("");

  return (
    <ClosableDialog
      onClose={onClose}
      open
      maxWidth="sm"
      titleElement={
        <div style={{ display: "flex", alignItems: "center" }}>
          {dialogTitle || "New Sample Group"}
          <Box
            sx={{ ml: 1, color: "warning.main" }}
          >{`(${recordCount} samples)`}</Box>
        </div>
      }
      contents={
        <Box p={2}>
          <Stack spacing={2} sx={{ minWidth: "400px" }}>
            <TextField
              label="Group Name"
              size="small"
              value={groupName}
              onChange={(e) => setGroupName(e.target.value)}
            />
            <TextField
              label="Description"
              rows={2}
              maxRows={5}
              size="small"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              multiline
            />
            <Stack direction="row" spacing={2}>
              {cancelButtonTitle ? (
                <Button
                  fullWidth
                  variant="outlined"
                  color="inherit"
                  onClick={() => {
                    onComplete("", "");
                    onClose();
                  }}
                >
                  {cancelButtonTitle}
                </Button>
              ) : null}
              <Button
                fullWidth
                variant="contained"
                disabled={!groupName || !description}
                onClick={() => {
                  onComplete(groupName, description);
                  onClose();
                }}
              >
                Create
              </Button>
            </Stack>
          </Stack>
        </Box>
      }
    />
  );
}
