/* eslint-disable @typescript-eslint/no-explicit-any */
import { ICompany } from "../types/company";
import backendApi from "./backendApi";
import { showSnackbar } from "./snackbar.service";

const routePrefix = "company";

export default {
  async fetchCompanyShallowList(): Promise<any> {
    try {
      const result = await backendApi()?.get(`${routePrefix}/shallow-list`);
      if (result?.status != 200) {
        showSnackbar(
          "danger",
          "Error",
          result?.data.message || "API error response"
        );
        return null;
      }
      return result;
    } catch (error: any) {
      if (error?.response?.status) {
        showSnackbar(
          "danger",
          "Error",
          error?.response?.data?.message || "API error response"
        );
        return null;
      }
    }
  },
  async getMyCompany(): Promise<any> {
    try {
      const result = await backendApi()?.get(`${routePrefix}/my`);
      if (result?.status != 200) {
        showSnackbar(
          "danger",
          "Error",
          result?.data.message || "API error response"
        );
        return null;
      }
      return result;
    } catch (error: any) {
      if (error?.response?.status) {
        showSnackbar(
          "danger",
          "Error",
          error?.response?.data?.message || "API error response"
        );
        return null;
      }
    }
  },
  async getMyClients(): Promise<any> {
    try {
      const result = await backendApi()?.get(`${routePrefix}/my-clients`);
      if (result?.status != 200) {
        showSnackbar(
          "danger",
          "Error",
          result?.data.message || "API error response"
        );
        return null;
      }
      return result;
    } catch (error: any) {
      if (error?.response?.status) {
        showSnackbar(
          "danger",
          "Error",
          error?.response?.data?.message || "API error response"
        );
        return null;
      }
    }
  },
  async fetchAllCompanies(): Promise<any> {
    try {
      const result = await backendApi()?.get(`${routePrefix}/all`);
      if (result?.status != 200) {
        showSnackbar(
          "danger",
          "Error",
          result?.data.message || "API error response"
        );
        return null;
      }
      return result;
    } catch (error: any) {
      if (error?.response?.status) {
        showSnackbar(
          "danger",
          "Error",
          error?.response?.data?.message || "API error response"
        );
        return null;
      }
    }
  },
  async addNewCompany(companyInfo: ICompany): Promise<any> {
    try {
      const result = await backendApi()?.post(`${routePrefix}/create`, {
        ...companyInfo,
      });
      if (result?.status != 200) {
        showSnackbar(
          "danger",
          "Error",
          result?.data.message || "API error response"
        );
        return null;
      }
      showSnackbar(
        "success",
        "Success",
        result?.data.message || "New company successfully created"
      );
      return result;
    } catch (error: any) {
      if (error?.response?.status) {
        showSnackbar(
          "danger",
          "Error",
          error?.response?.data?.message || "API error response"
        );
        return null;
      }
    }
  },
  async updateCompany(updateInfo: ICompany, companyId?: string): Promise<any> {
    try {
      const result = await backendApi()?.post(`${routePrefix}/update`, {
        updateInfo,
        companyId,
      });
      if (result?.status != 200) {
        showSnackbar(
          "danger",
          "Error",
          result?.data.message || "API error response"
        );
        return null;
      }
      showSnackbar(
        "success",
        "Success",
        result?.data.message || "Company information is successfully updated"
      );
      return result;
    } catch (error: any) {
      if (error?.response?.status) {
        showSnackbar(
          "danger",
          "Error",
          error?.response?.data?.message || "API error response"
        );
        return null;
      }
    }
  },
  async deleteCompanies(ids: string[]): Promise<any> {
    try {
      const result = await backendApi()?.post(`${routePrefix}/delete`, {
        ids,
      });
      return result;
    } catch (error: any) {
      if (error?.response?.status) {
        showSnackbar(
          "danger",
          "Error",
          error?.response?.data?.message || "API error response"
        );
        return null;
      }
    }
  },
};
