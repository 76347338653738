import { Store, NOTIFICATION_TYPE } from "react-notifications-component";

export function showSnackbar(type: NOTIFICATION_TYPE, title: string, message: string) {
  Store.addNotification({
    title,
    message,
    type: type,
    insert: "top",
    container: "top-right",
    dismiss: {
      duration: type == "success" ? 1500 : 3000,
    },
  });
}
