import Box from "@mui/material/Box";
import Button, { ButtonProps } from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import React from "react";

export interface IProgressButtonProps extends ButtonProps {
  title: string;
  inProgress?: boolean;
}

export default function ProgressButton(
  props: IProgressButtonProps
): React.ReactElement {
  const {
    onClick,
    disabled,
    variant,
    size,
    disableElevation,
    inProgress,
    title,
    ...otherProps
  } = props;
  return (
    <Button
      onClick={onClick}
      disabled={disabled || inProgress}
      variant={variant || "contained"}
      size={size}
      disableElevation={disableElevation == undefined ? true : disableElevation}
      {...otherProps}
    >
      <Box sx={{ opacity: inProgress ? 0 : 1 }}>{title}</Box>
      {inProgress && (
        <Box
          sx={{
            position: "absolute",
            left: "50%",
            top: "50%",
            transform: "translate(-50%, -50%)",
            display: "flex",
            alignItems: "center",
          }}
        >
          <CircularProgress
            size={15}
            sx={{
              color: "white",
            }}
          />
        </Box>
      )}
    </Button>
  );
}
