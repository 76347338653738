/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect } from "react";
import { MapContainer, TileLayer, useMap } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import L from "leaflet";
import "leaflet.heat";

// Define the type for the heatmap data point
export interface HeatmapDataPoint {
  lat?: number; // Latitude of the point
  lon?: number; // Longitude of the point
  value?: number; // Intensity/Weight of the point
}

// Props type for HeatmapLayer
interface HeatmapLayerProps {
  data: HeatmapDataPoint[]; // Array of heatmap data points
}

const HeatmapLayer: React.FC<HeatmapLayerProps> = ({ data }) => {
  const map = useMap();

  useEffect(() => {
    if (!data || data.length === 0) return;

    // Convert the data to the format required by leaflet-heat: [lat, lon, intensity]
    const heatmapData = data
      .filter((point) => point.lat && point.lon && point.value)
      .map((point) => [point.lat || 0, point.lon || 0, point.value]);

    // Create a heatmap layer

    const heatLayer = (L as any).heatLayer(heatmapData, {
      radius: 25, // Size of heat points
      blur: 15, // Blur effect
      maxZoom: 11, // Max zoom for the heatmap
    });

    // Add the layer to the map
    heatLayer.addTo(map);

    // Cleanup function to remove the layer when the component unmounts
    return () => {
      map.removeLayer(heatLayer);
    };
  }, [data, map]);

  return null;
};

// Props type for Heatmap
interface HeatmapProps {
  heatmapData: HeatmapDataPoint[]; // Array of heatmap data points
}

const ChemicalHeatmap: React.FC<HeatmapProps> = ({ heatmapData }) => {
  return (
    <MapContainer
      center={[41.9216734, -93.3122705]} // Center the map based on your data
      zoom={3} // Initial zoom level
      style={{ height: "400px", width: "100%" }}
    >
      <TileLayer
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
      />
      <HeatmapLayer data={heatmapData} />
    </MapContainer>
  );
};

export default ChemicalHeatmap;
