import * as React from "react";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { useAppContext } from "../../../contexts/useStorageContext";
import { UserRoles } from "../../../types/auth";
import { ICompany } from "../../../types/company";
import companyService from "../../../service/company.service";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import ProgressButton from "./ProgressButton";

const filter = createFilterOptions<ICompany>();

export default function CompanyAutoComplete({
  value,
  onChange,
  fullWidth = false,
  label,
  py,
  definedVendorId,
  onlyDirectPartners,
  limitedOptions,
  enabled,
}: {
  value?: ICompany;
  definedVendorId?: string;
  onlyDirectPartners?: boolean;
  onChange?: (selected: ICompany | null) => void;
  fullWidth?: boolean;
  label?: string;
  py?: number;
  limitedOptions?: ICompany[];
  enabled?: boolean;
}) {
  const [open, toggleOpen] = React.useState(false);
  const { state } = useAppContext();
  const { loggedInUserInfo } = state;
  const [options, setOptions] = React.useState<ICompany[]>(
    limitedOptions || []
  );
  const fetchCompanyShallowList = async () => {
    if (limitedOptions) return;
    const result = await companyService.fetchCompanyShallowList();
    const filteredResult = definedVendorId
      ? result.data?.filter(
          (c: ICompany) => definedVendorId == c.vendorCompanyId
        ) || []
      : onlyDirectPartners
      ? result.data?.filter((c: ICompany) => !c.vendorCompanyId) || []
      : result.data || [];
    setOptions(filteredResult);
  };
  const [creatingNewCompany, setCreatingNewCompany] = React.useState(false);
  const [dialogValue, setDialogValue] = React.useState<ICompany>({ name: "" });

  const handleClose = () => {
    setDialogValue({ name: "" });
    toggleOpen(false);
  };

  const handleSubmit = async () => {
    setCreatingNewCompany(true);
    const result = await companyService.addNewCompany(dialogValue);
    setOptions([...options, result.data]);
    setCreatingNewCompany(false);
    onChange && onChange(result.data);
    handleClose();
  };

  const isAdmin =
    loggedInUserInfo?.role &&
    [UserRoles.superadmin, UserRoles.adminStaff].includes(
      loggedInUserInfo?.role as UserRoles
    );

  React.useEffect(() => {
    if (isAdmin) fetchCompanyShallowList();
  }, [definedVendorId]);
  return (
    <>
      <Autocomplete
        freeSolo
        disabled={enabled ? false : !isAdmin}
        value={value || null}
        onChange={(event, newValue) => {
          if (typeof newValue === "string") {
            // timeout to avoid instant validation of the dialog's form.
            setTimeout(() => {
              toggleOpen(true);
              setDialogValue({
                name: newValue,
              });
            });
          } else if (newValue && newValue._id == "new") {
            toggleOpen(true);
            setDialogValue({
              name: newValue.name?.replaceAll('"', "").replaceAll("Add ", ""),
            });
          } else {
            onChange && onChange(newValue);
          }
        }}
        filterOptions={(options, params) => {
          const filtered = filter(options, params);

          if (params.inputValue !== "") {
            filtered.push({
              _id: "new",
              name: `Add "${params.inputValue}"`,
            });
          }

          return filtered;
        }}
        getOptionLabel={(option) => {
          // for example value selected with enter, right from the input
          if (typeof option === "string") {
            return option;
          }
          if (option && option._id == "new") {
            return "new";
          }
          return option.name || "";
        }}
        options={options}
        selectOnFocus
        clearOnBlur
        handleHomeEndKeys
        renderOption={(props, option) => <li {...props}>{option.name}</li>}
        renderInput={(params) => (
          <TextField
            {...params}
            label={label}
            fullWidth={fullWidth}
            placeholder="Select Lab"
            size="small"
            sx={{
              minWidth: "200px",
              "& .MuiInputBase-root": {
                py: `${py}px !important`,
              },
            }}
          />
        )}
      />
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Add a new lab</DialogTitle>
        <DialogContent sx={{ minWidth: "300px" }}>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            value={dialogValue.name}
            onChange={(event) =>
              setDialogValue({
                ...dialogValue,
                name: event.target.value,
              })
            }
            label="Farm Name"
            type="text"
            fullWidth
            size="small"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <ProgressButton
            onClick={handleSubmit}
            title="Add"
            inProgress={creatingNewCompany}
          />
        </DialogActions>
      </Dialog>
    </>
  );
}
