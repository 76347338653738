/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as React from "react";
import { Box, CircularProgress, Grid } from "@mui/material";
import { useSearchParams } from "react-router-dom";
import SampleRecordsDataTable from "../../components/SampleRecordsDataTable";
import sampleGroupService from "../../../service/sampleGroup.service";
import { ISampleRecordGroup, SampleGroupType } from "../../../types/sample";
import { showSnackbar } from "../../../service/snackbar.service";
import exportService from "../../../service/export.service";
export default function Index() {
  const [searchParams, setSearchParams] = useSearchParams();
  const [loadingSampleGroup, setLoadingSampleGroup] = React.useState(true);
  const [sampleGroup, setSampleGroup] = React.useState<ISampleRecordGroup>();

  const fetchSampleGroup = async (
    groupId: string,
    sampleGroupType: SampleGroupType
  ) => {
    try {
      setLoadingSampleGroup(true);
      const result =
        sampleGroupType == SampleGroupType.Collection
          ? await sampleGroupService.getSampleGroupById(groupId)
          : sampleGroupType == SampleGroupType.DownloadLog
          ? await exportService.getDownloadLogById(groupId)
          : sampleGroupType == SampleGroupType.UploadLog
          ? await exportService.getUploadLogById(groupId)
          : undefined;

      if (result) {
        if (sampleGroupType == SampleGroupType.Collection) {
          setSampleGroup({ ...result, initial: true });
        } else if (sampleGroupType == SampleGroupType.DownloadLog) {
          setSampleGroup({
            _id: result._id,
            name: result.fileName,
            uids: result.uids || [],
            type: SampleGroupType.DownloadLog,
            initial: true,
          } as any);
        } else if (sampleGroupType == SampleGroupType.UploadLog) {
          setSampleGroup({
            _id: result._id,
            name: result.fileName,
            uids: result.uids || [],
            type: SampleGroupType.UploadLog,
            initial: true,
          } as any);
        }
      }
      setLoadingSampleGroup(false);
    } catch (error: any) {
      setLoadingSampleGroup(false);
      showSnackbar(
        "danger",
        "Error",
        error?.response?.data.message || "API error response"
      );
    }
  };
  React.useEffect(() => {
    const collectionId = searchParams.get("sample-group-id");
    const downloadLogId = searchParams.get("download-log-id");
    const uploadLogId = searchParams.get("upload-log-id");
    if (collectionId) {
      fetchSampleGroup(collectionId, SampleGroupType.Collection);
    } else if (downloadLogId) {
      fetchSampleGroup(downloadLogId, SampleGroupType.DownloadLog);
    } else if (uploadLogId) {
      fetchSampleGroup(uploadLogId, SampleGroupType.UploadLog);
    } else {
      setLoadingSampleGroup(false);
    }
  }, []);
  return (
    <>
      <Grid container>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          {loadingSampleGroup ? (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: "500px",
              }}
            >
              <CircularProgress />
            </Box>
          ) : (
            <SampleRecordsDataTable initialSampleGroup={sampleGroup} />
          )}
        </Grid>
      </Grid>
    </>
  );
}
