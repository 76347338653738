import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import {
  AnalysisStyledTableCell,
  AnalysisStyledTableRow,
  TableTitle,
} from "./AnalysisValuesStyledTable";
import TableBody from "@mui/material/TableBody";
import { ISampleRecordPopulated } from "../../../../../types/sample";
import { IAnalysisValuesDynamicTableRowKeyValueData } from "./TablesData";
import Box from "@mui/material/Box";

export default function AnalysisValuesDynamicTable({
  sampleRecord,
  comparedSampleRecord,
  rowsData,
  headers,
  title,
  disableHeaderBackground,
  values,
  cellPaddingY,
}: {
  sampleRecord: ISampleRecordPopulated;
  comparedSampleRecord?: ISampleRecordPopulated;
  rowsData: IAnalysisValuesDynamicTableRowKeyValueData[];
  headers: string[];
  title?: string;
  disableHeaderBackground?: boolean;
  values?: string[];
  cellPaddingY?: number;
}) {
  return (
    <Box>
      {title ? <TableTitle>{title}</TableTitle> : null}

      <Table aria-label="customized table">
        <TableHead>
          <TableRow>
            {headers.map((headerTitle) => (
              <AnalysisStyledTableCell
                className={disableHeaderBackground ? "transparent" : ""}
                key={headerTitle}
              >
                {headerTitle}
              </AnalysisStyledTableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {rowsData.map((item, i) => (
            <AnalysisStyledTableRow key={item.title}>
              <AnalysisStyledTableCell
                sx={{ py: cellPaddingY ? `${cellPaddingY}px` : undefined }}
              >
                {item.title}
              </AnalysisStyledTableCell>
              <AnalysisStyledTableCell
                sx={{ py: cellPaddingY ? `${cellPaddingY}px` : undefined }}
              >
                <div style={{ display: "flex", alignItems: "center" }}>
                  {values?.[i] ||
                    (sampleRecord.chemicals?.[item.fieldName]
                      ? sampleRecord.chemicals?.[item.fieldName]?.toFixed(2)
                      : "")}
                  {comparedSampleRecord?.chemicals?.[item.fieldName] &&
                  sampleRecord.chemicals?.[item.fieldName] ? (
                    <Box
                      sx={{
                        marginLeft: "20px",
                        display: "flex",
                        alignItems: "center",
                        color:
                          (sampleRecord.chemicals?.[item.fieldName] || 0) -
                            (comparedSampleRecord.chemicals?.[item.fieldName] ||
                              0) >=
                          0
                            ? "success.main"
                            : "error.main",
                      }}
                    >
                      {(sampleRecord.chemicals?.[item.fieldName] || 0) -
                        (comparedSampleRecord.chemicals?.[item.fieldName] ||
                          0) >
                      0
                        ? "+"
                        : ""}
                      {(
                        (sampleRecord.chemicals?.[item.fieldName] || 0) -
                        (comparedSampleRecord.chemicals?.[item.fieldName] || 0)
                      )?.toFixed(2)}
                    </Box>
                  ) : null}
                </div>
              </AnalysisStyledTableCell>
            </AnalysisStyledTableRow>
          ))}
        </TableBody>
      </Table>
    </Box>
  );
}
