/* eslint-disable @typescript-eslint/no-explicit-any */
import { MenuItem, TextField } from "@mui/material";
import * as React from "react";
import {
  DocumentationSubject,
  DocumentationSubjectToColor,
  DocumentationSubjectToString,
} from "../../../types/documentation";

interface IDocumentationSubjectSelectProps {
  label?: string;
  placeholder?: string;
  value?: DocumentationSubject;
  onChange: (newVal?: DocumentationSubject) => void;
  error?: boolean;
  helperText?: string;
  disabled?: boolean;
}
export default function DocumentationSubjectSelect(
  props: IDocumentationSubjectSelectProps
) {
  const { value, onChange, helperText, error, disabled } = props;
  return (
    <TextField
      disabled={disabled}
      label={props.label}
      select
      fullWidth
      placeholder="Select Subject"
      helperText={helperText}
      error={error}
      value={value || ""}
      size="small"
      onChange={(e) => {
        onChange(e.target.value as unknown as DocumentationSubject);
      }}
    >
      {Object.values(DocumentationSubject)
        .filter((subject) => DocumentationSubjectToString[subject as any])
        .map((subject, i) => (
          <MenuItem
            key={`comp-type-${i}`}
            value={subject as DocumentationSubject}
          >
            <div style={{ display: "flex", alignItems: "center" }}>
              <div
                style={{
                  borderRadius: "5px",
                  background: DocumentationSubjectToColor[subject as any],
                  padding: "2px 8px",
                }}
              >
                {DocumentationSubjectToString[subject as any]}
              </div>
            </div>
          </MenuItem>
        ))}
    </TextField>
  );
}
