import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  DialogActions,
  Button,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  Box,
  TextField,
} from "@mui/material";
import WarningIcon from "@mui/icons-material/Warning";
import {
  ISampleRecordPopulated,
  SampleRecordManualUpdateReason,
} from "../../../types/sample";
import { getSampleRecordUpdates } from "../../../helpers/sampleRecord";

interface UpdateSampleRecordConfirmDialogProps {
  open: boolean;
  onClose: () => void;
  onConfirm: (reason: string, reasonText?: string) => void;
  originalSampleRecord?: ISampleRecordPopulated;
  updatedSampleRecord: ISampleRecordPopulated;
}

const UpdateSampleRecordConfirmDialog: React.FC<
  UpdateSampleRecordConfirmDialogProps
> = ({
  open,
  onClose,
  onConfirm,
  originalSampleRecord,
  updatedSampleRecord,
}) => {
  const updates = originalSampleRecord
    ? getSampleRecordUpdates(originalSampleRecord, updatedSampleRecord)
    : [];
  const [reason, setReason] = useState<SampleRecordManualUpdateReason>();
  const [validationError, setValidationError] = useState("");
  const [reasonText, setReasonText] = useState("");

  React.useEffect(() => {
    setValidationError("");
  }, [reason, reasonText]);

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>Confirm Record Update</DialogTitle>
      <DialogContent>
        <Table sx={{ border: "solid 1px #ddd" }}>
          <TableHead>
            <TableRow>
              <TableCell>Field</TableCell>
              <TableCell>Original Value</TableCell>
              <TableCell>Updated Value</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {updates.map((update, index) => (
              <TableRow key={index}>
                <TableCell>{update.fieldName}</TableCell>
                <TableCell>{update.oldValue || ""}</TableCell>
                <TableCell>{update.newValue || ""}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <Box sx={{ mt: 2 }}>
          <FormControl>
            <RadioGroup
              name="reason"
              value={reason}
              onChange={(e, value) =>
                setReason(value as SampleRecordManualUpdateReason)
              }
            >
              {Object.values(SampleRecordManualUpdateReason).map((reason) => (
                <FormControlLabel
                  value={reason}
                  control={<Radio />}
                  label={reason}
                />
              ))}
            </RadioGroup>
          </FormControl>
        </Box>
        {reason == SampleRecordManualUpdateReason.Other ? (
          <TextField
            label="Reason for update"
            value={reasonText}
            onChange={(e) => setReasonText(e.target.value)}
            sx={{ mt: 2 }}
            fullWidth
            multiline
            rows={2}
          />
        ) : null}
      </DialogContent>
      <DialogActions>
        {validationError ? (
          <div style={{ flex: 1 }}>
            <Box
              sx={{
                color: "error.main",
                display: "flex",
                alignItems: "center",
                ml: 2,
              }}
            >
              <WarningIcon sx={{ mr: 1 }} color="error" />
              {validationError}
            </Box>
          </div>
        ) : null}

        <Button onClick={onClose} color="primary">
          Cancel Update
        </Button>
        <Button
          onClick={() => {
            if (reason) {
              if (
                reason == SampleRecordManualUpdateReason.Other &&
                !reasonText
              ) {
                setValidationError("Reason for update is required");
              } else {
                onConfirm(reason, reasonText);
              }
            } else {
              setValidationError("Select reason for update");
            }
          }}
          color="primary"
          variant="contained"
        >
          Confirm Update
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default UpdateSampleRecordConfirmDialog;
