/* eslint-disable @typescript-eslint/no-explicit-any */
import { IOnboardingGuide } from "../types/onboardingGuide";
import backendApi from "./backendApi";
import { showSnackbar } from "./snackbar.service";

const routePrefix = "datalake/onboarding-guide";

export default {
  async getAll() {
    try {
      const result = await backendApi()?.get(`${routePrefix}`);
      return result.data;
    } catch (error: any) {
      if (error?.response?.status) {
        showSnackbar(
          "danger",
          "Error",
          error?.response?.data?.message || "API error response"
        );
        throw error;
      }
    }
  },
  async create(guideData: IOnboardingGuide) {
    try {
      const result = await backendApi()?.post(`${routePrefix}`, guideData);
      return result.data;
    } catch (error: any) {
      if (error?.response?.status) {
        showSnackbar(
          "danger",
          "Error",
          error?.response?.data?.message || "API error response"
        );
        throw error;
      }
    }
  },
  async update(guideId: string, guideData: IOnboardingGuide) {
    try {
      const result = await backendApi()?.put(
        `${routePrefix}/${guideId}`,
        guideData
      );
      return result.data;
    } catch (error: any) {
      if (error?.response?.status) {
        showSnackbar(
          "danger",
          "Error",
          error?.response?.data?.message || "API error response"
        );
        throw error;
      }
    }
  },
  async delete(guideId: string): Promise<any> {
    try {
      const result = await backendApi()?.delete(`${routePrefix}/${guideId}`);
      return result.data;
    } catch (error: any) {
      if (error?.response?.status) {
        showSnackbar(
          "danger",
          "Error",
          error?.response?.data?.message || "API error response"
        );
        throw error;
      }
    }
  },
};
