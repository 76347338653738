import { IChemicalValueFilterItem } from "../views/components/SampleRecordsDataTable/ChemicalValueFilter";

export interface SampleRecordFilterParams {
  companyId?: string;
  sampleSource?: string;
  sampleClient?: string;
  bagWeight?: number;
  searchTerm?: string;
  chemicalFilters?: IChemicalValueFilterItem[];
  rangeFilterFieldName?: string;
  minUid?: string;
  maxUid?: string;
  uids?: number[];
  sortModel?: { [key: string]: number };
}
export interface ITimestamp {
  nanos: number;
  seconds: number;
}

export interface IActionByMetadata {
  displayName: string;
  ssoUserId: string;
  userId: string;
}

export interface IPhoneNumber {
  phoneNumber: string;
  phoneType: string;
}

export enum FilterOperators {
  greaterThan = ">=",
  lessThan = "<=",
  between = "><",
}
