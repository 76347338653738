/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import Box from "@mui/material/Box";
import React from "react";
import { ISampleRecordPopulated } from "../../../../types/sample";
import {
  AnalysisValueColorRanges,
  AnalysisValueRanges,
} from "./AnalysisConstants";

const CompareIncreasedColor = "#00b800";
const CompareDecreasedColor = "#e72525";
const CompareSameColor = "#157ae2";

export default function KeyValueBlock({
  sampleRecord,
  comparedSampleRecord,
}: {
  comparedSampleRecord?: ISampleRecordPopulated;
  sampleRecord: ISampleRecordPopulated;
}) {
  return (
    <Box
      sx={{
        flex: 1,
        border: "solid 3px #bfbfbf",
        background: "white",
        fontSize: "14px",
        borderRadius: "3px",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Box
        sx={{
          background: "#ddd",
          padding: "2px 5px",
          paddingTop: "1px",
          textAlign: "center",
        }}
      >
        MBP
      </Box>
      <Box
        sx={{
          flex: 1,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div
          style={{
            fontSize: "38px",
            fontWeight: 600,
            marginRight: "4px",
            color: sampleRecord.chemicals?.["MBP"]
              ? AnalysisValueColorRanges["MBP"].find((range) => {
                  const fromValue =
                    AnalysisValueRanges["MBP"].min +
                    ((AnalysisValueRanges["MBP"].max -
                      AnalysisValueRanges["MBP"].min) *
                      range.from) /
                      100;
                  const toValue =
                    AnalysisValueRanges["MBP"].min +
                    ((AnalysisValueRanges["MBP"].max -
                      AnalysisValueRanges["MBP"].min) *
                      range.to) /
                      100;

                  return (
                    fromValue < (sampleRecord.chemicals?.["MBP"] as any) &&
                    toValue > (sampleRecord.chemicals?.["MBP"] as any)
                  );
                })?.color || "#333"
              : "#333",
          }}
        >
          {Number(sampleRecord.chemicals?.["MBP"]?.toFixed(1))}
        </div>
        <div>
          <div style={{ fontSize: "17px", marginBottom: "3px" }}>(mg/g)</div>
          <div style={{ fontSize: "11px", opacity: 0.8 }}>Rumen</div>
        </div>
      </Box>
      {comparedSampleRecord &&
      Number(comparedSampleRecord.chemicals?.["MBP"]) ? (
        <div
          style={{
            fontSize: "14px",
            textAlign: "center",
            marginTop: "3px",
            marginBottom: "3px",
            color:
              Number(sampleRecord.chemicals?.["MBP"]?.toFixed(1)) -
                Number(comparedSampleRecord.chemicals?.["MBP"]?.toFixed(1)) >
              0
                ? CompareIncreasedColor
                : Number(sampleRecord.chemicals?.["MBP"]?.toFixed(1)) -
                    Number(
                      comparedSampleRecord.chemicals?.["MBP"]?.toFixed(1)
                    ) <
                  0
                ? CompareDecreasedColor
                : CompareSameColor,
            fontWeight: 600,
          }}
        >
          {`${
            Number(sampleRecord.chemicals?.["MBP"]?.toFixed(1)) -
              Number(comparedSampleRecord.chemicals?.["MBP"]?.toFixed(1)) >
            0
              ? "+"
              : ""
          }${(
            Number(sampleRecord.chemicals?.["MBP"]?.toFixed(1)) -
            Number(comparedSampleRecord.chemicals?.["MBP"]?.toFixed(1))
          ).toFixed(2)}`}
        </div>
      ) : null}
    </Box>
  );
}
