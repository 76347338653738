// r1: Fast_Hr
// r2: Slow_Hr
export function getQuadrant(r1: number, r2: number, mbp: number) {
  const oor = r1 <= 0 || r2 <= 0 || mbp <= 0;
  const fastRange = [0.001, 0.01];
  const slowRange = [0.0001, 0.001];
  const s = 4;

  r1 = Math.max(Math.min(r1, fastRange[1]), fastRange[0]);
  r2 = Math.max(Math.min(r2, slowRange[1]), slowRange[0]);

  let d1 = Math.pow(r1 * 1000 - 10, 2) + Math.pow(r2 * 10000 - 10, 2);
  let d2 = Math.pow(r1 * 1000 - 10, 2) + Math.pow(r2 * 10000 - 1, 2);
  let d3 = Math.pow(r1 * 1000 - 1, 2) + Math.pow(r2 * 10000 - 10, 2);
  let d4 = Math.pow(r1 * 1000 - 1, 2) + Math.pow(r2 * 10000 - 1, 2);

  mbp = Math.max(Math.min(mbp, 200), 100);
  const mbp_s = mbp * 0.09 - 8;

  d1 += Math.pow(mbp_s - 10, 2) / s;
  d2 += Math.pow(mbp_s - 7, 2) / s;
  d3 += Math.pow(mbp_s - 4, 2) / s;
  d4 += Math.pow(mbp_s - 1, 2) / s;

  let e = [d1, d2, d3, d4];
  const minE = Math.min(...e);
  e = e.map((value) => Math.exp(-(value - minE) / 8));

  for (let i = 0; i < e.length; i++) {
    const sortedIndices = e
      .map((value, index) => [value, index])
      .sort(([value1], [value2]) => value1 - value2)
      .slice(0, 2)
      .map(([, index]) => index);
    sortedIndices.forEach((index) => {
      e[index] = 0;
    });
  }

  const sumE = e.reduce((sum, value) => sum + value, 0);
  const Y = e.map((value) => value / sumE);
  if (oor) {
    Y.fill(0);
  }

  return Y;
}

export const GasAnalysisConclusionsByQuadrant = {
  tmrRatesByQuadrant: [
    ["CB1 Kd: High", "CB3 Kd: High"],
    ["CB1 Kd: Medium-High", "CB3 Kd: Medium-Low"],
    ["CB1 Kd: Medium-Low", "CB3 Kd: Medium-High"],
    ["CB1 Kd: Low", "CB3 Kd: Low"],
  ],
  onFarmObservations: [
    [
      "Good Manure Consistency",
      "High Dry Matter Intake (DMI)",
      "Excellent Milk Yield",
      "Exceptional Feed Conversion - 1.7",
    ],
    [
      "Sore feet, Repro problems, Poor DMI",
      "Ketosis in early lactation cows",
      "Fibre in manure, Low rumen pH",
      "Variability in body condition score",
      "Fat/Protein Inversion in >10% of the herd ",
    ],
    [
      "Grain in manure ",
      "Low milk protein",
      "Milk yield lower than expected ",
      "Rough hair coats",
      "Poor reproduction",
    ],
    [
      "Poor milk production, Early peak",
      "Poor peak milk",
      "Cows look OK, Average milk components ",
      "Diet CP is ok- but manure is stiff",
      "Very expensive ration per CWT",
      "Reproduction- delayed, but not bad",
    ],
  ],
  actionsToConsider: [
    ["Ensure enough peptide and NPN protein to feed rumen bacteria"],
    [
      "Increase NDFD by adding non-forage fiber beet pulp, brewers grains, soyhulls",
    ],
    [
      "Increasing supply of rumen available starch and sugar",
      "Grinding grains finer",
      "Ensure adequate supply of peptide & NPN protein",
    ],
    [
      " Increasing rumen available starch and sugar",
      " Grinding grains finer",
      "Increase NDFD by adding more forage, fibre, beet pulp, brewers, soyhulls, and more digestible forages",
    ],
  ],
};

export function getAcetate(orp: number) {
  return Number((55 + (orp + 119) / -7.984).toFixed(1));
}

export function getPropionate(orp: number) {
  return Number((10 + (orp + 240.1) / 4.84).toFixed(1));
}

export function getTotalVFA(orp: number) {
  return Number((3 + ((-1 * orp - 53) / 17) * 0.5).toFixed(2));
}
