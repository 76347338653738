import Box from "@mui/material/Box";
import { ISampleRecordPopulated } from "../../../../../types/sample";
import AnalysisSlider from "./AnalysisSlider";
import InfoIcon from "@mui/icons-material/Info";
import {
  AnalysisValueColorRanges,
  AnalysisValueRanges,
} from "../AnalysisConstants";
import {
  getFastHrMaxRateHours,
  getSlowHrMaxRateHours,
  SliderInfo,
} from "../ReportUtils";

export default function SliderGroup({
  sampleRecord,
  comparedSampleRecord,
}: {
  sampleRecord: ISampleRecordPopulated;
  comparedSampleRecord?: ISampleRecordPopulated;
}) {
  const FastHrMaxRateHours = getFastHrMaxRateHours(sampleRecord);
  const SlowHrMaxRateHours = getSlowHrMaxRateHours(sampleRecord);

  return (
    <Box
      sx={{
        "& .slider-tip": {
          marginLeft: "140px",
          marginTop: "-20px",
          marginBottom: comparedSampleRecord ? "15px" : "25px",
        },
        "& .slider-container": {
          marginBottom: comparedSampleRecord ? "15px" : "25px",
          padding: "0px",
          display: "flex",
          alignItems: "flex-end",
          "& .slider-label": {
            marginBottom: "3px",
            width: "120px",
            textAlign: "right",
            paddingTop: "3px",
            paddingBottom: "3px",
            paddingRight: "15px",
            // background: "#f1f1f1",
            fontWeight: "500",
            fontSize: "14px",
          },
        },
      }}
    >
      {sampleRecord.chemicals?.["MBP"] ? (
        <Box className="slider-container">
          <div className="slider-label">MBP</div>
          <AnalysisSlider
            title=""
            tickLabels={[
              AnalysisValueRanges["MBP"].min.toString(),
              "",
              "",
              "",
              "",
              "",
              AnalysisValueRanges["MBP"].max.toString(),
            ]}
            sliderWidth={280}
            rightSideLabel={`${sampleRecord.chemicals?.["MBP"]?.toFixed(
              2
            )} mg/g`}
            compareLabel={
              comparedSampleRecord?.chemicals?.["MBP"] &&
              sampleRecord?.chemicals?.["MBP"]
                ? Number(
                    (
                      sampleRecord?.chemicals?.["MBP"] -
                      comparedSampleRecord?.chemicals?.["MBP"]
                    ).toFixed(2)
                  ).toString()
                : ""
            }
            compareLabelColor={
              (sampleRecord.chemicals?.["MBP"] || 0) >
              (comparedSampleRecord?.chemicals?.["MBP"] || 0)
                ? "success.main"
                : "error.main"
            }
            sections={AnalysisValueColorRanges["MBP"]}
            value={
              ((sampleRecord.chemicals?.["MBP"] -
                AnalysisValueRanges["MBP"].min) /
                (AnalysisValueRanges["MBP"].max -
                  AnalysisValueRanges["MBP"].min)) *
              100
            }
            oldValue={
              !comparedSampleRecord?.chemicals?.["MBP"]
                ? -1
                : ((comparedSampleRecord?.chemicals?.["MBP"] -
                    AnalysisValueRanges["MBP"].min) /
                    (AnalysisValueRanges["MBP"].max -
                      AnalysisValueRanges["MBP"].min)) *
                  100
            }
          />
        </Box>
      ) : null}
      {sampleRecord.chemicals?.["UNDF"] ? (
        <div>
          <Box className="slider-container">
            <div className="slider-label">uNDF48 % NDF</div>
            <AnalysisSlider
              title=""
              tickLabels={[
                `${AnalysisValueRanges["UNDF"].min}%`,
                "",
                "",
                "",
                "",
                "",
                `${AnalysisValueRanges["UNDF"].max}%`,
              ]}
              sliderWidth={280}
              rightSideLabel={`${sampleRecord.chemicals?.["UNDF"]?.toFixed(
                2
              )} %`}
              compareLabel={
                comparedSampleRecord?.chemicals?.["UNDF"] &&
                sampleRecord?.chemicals?.["UNDF"]
                  ? Number(
                      (
                        sampleRecord?.chemicals?.["UNDF"] -
                        comparedSampleRecord?.chemicals?.["UNDF"]
                      ).toFixed(2)
                    ).toString()
                  : ""
              }
              compareLabelColor={
                (sampleRecord.chemicals?.["UNDF"] || 0) >
                (comparedSampleRecord?.chemicals?.["UNDF"] || 0)
                  ? "success.main"
                  : "error.main"
              }
              sections={AnalysisValueColorRanges["UNDF"]}
              value={
                ((AnalysisValueRanges["UNDF"].min -
                  sampleRecord.chemicals?.["UNDF"]) /
                  (AnalysisValueRanges["UNDF"].min -
                    AnalysisValueRanges["UNDF"].max)) *
                100
              }
              oldValue={
                !comparedSampleRecord?.chemicals?.["UNDF"]
                  ? -1
                  : ((AnalysisValueRanges["UNDF"].min -
                      comparedSampleRecord?.chemicals?.["UNDF"]) /
                      (AnalysisValueRanges["UNDF"].min -
                        AnalysisValueRanges["UNDF"].max)) *
                    100
              }
            />
          </Box>
          {sampleRecord.chemicals?.["UNDF"] > 24 ? (
            <SliderInfo className="slider-tip" sx={{ color: "warning.dark" }}>
              <InfoIcon />
              DMI will suffer
            </SliderInfo>
          ) : null}
        </div>
      ) : null}
      {sampleRecord.chemicals?.["Fast_Pool"] ? (
        <Box className="slider-container">
          <div className="slider-label">Fast Pool</div>
          <AnalysisSlider
            title=""
            tickLabels={[
              `${AnalysisValueRanges["Fast_Pool"].min}`,
              "",
              "",
              "",
              "",
              "",
              `${AnalysisValueRanges["Fast_Pool"].max}`,
            ]}
            sliderWidth={280}
            rightSideLabel={`${sampleRecord.chemicals?.["Fast_Pool"]?.toFixed(
              2
            )} Kd/Hr`}
            compareLabel={
              comparedSampleRecord?.chemicals?.["Fast_Pool"] &&
              sampleRecord?.chemicals?.["Fast_Pool"]
                ? Number(
                    (
                      sampleRecord?.chemicals?.["Fast_Pool"] -
                      comparedSampleRecord?.chemicals?.["Fast_Pool"]
                    ).toFixed(2)
                  ).toString()
                : ""
            }
            compareLabelColor={
              (sampleRecord.chemicals?.["Fast_Pool"] || 0) >
              (comparedSampleRecord?.chemicals?.["Fast_Pool"] || 0)
                ? "success.main"
                : "error.main"
            }
            sections={AnalysisValueColorRanges["Fast_Pool"]}
            value={
              ((sampleRecord.chemicals?.["Fast_Pool"] -
                AnalysisValueRanges["Fast_Pool"].min) /
                (AnalysisValueRanges["Fast_Pool"].max -
                  AnalysisValueRanges["Fast_Pool"].min)) *
              100
            }
            oldValue={
              !comparedSampleRecord?.chemicals?.["Fast_Pool"]
                ? -1
                : ((AnalysisValueRanges["Fast_Pool"].min -
                    comparedSampleRecord?.chemicals?.["Fast_Pool"]) /
                    (AnalysisValueRanges["Fast_Pool"].min -
                      AnalysisValueRanges["Fast_Pool"].max)) *
                  100
            }
          />
        </Box>
      ) : null}
      {sampleRecord.chemicals?.["Slow_Pool"] ? (
        <Box className="slider-container">
          <div className="slider-label">Slow Pool</div>
          <AnalysisSlider
            title=""
            tickLabels={[
              `${AnalysisValueRanges["Slow_Pool"].min}`,
              "",
              "",
              "",
              "",
              "",
              `${AnalysisValueRanges["Slow_Pool"].max}`,
            ]}
            sliderWidth={280}
            rightSideLabel={`${sampleRecord.chemicals?.["Slow_Pool"]?.toFixed(
              2
            )} Kd/Hr`}
            compareLabel={
              comparedSampleRecord?.chemicals?.["Slow_Pool"] &&
              sampleRecord?.chemicals?.["Slow_Pool"]
                ? Number(
                    (
                      sampleRecord?.chemicals?.["Slow_Pool"] -
                      comparedSampleRecord?.chemicals?.["Slow_Pool"]
                    ).toFixed(2)
                  ).toString()
                : ""
            }
            compareLabelColor={
              (sampleRecord.chemicals?.["Slow_Pool"] || 0) >
              (comparedSampleRecord?.chemicals?.["Slow_Pool"] || 0)
                ? "success.main"
                : "error.main"
            }
            sections={AnalysisValueColorRanges["Slow_Pool"]}
            value={
              ((sampleRecord.chemicals?.["Slow_Pool"] -
                AnalysisValueRanges["Slow_Pool"].min) /
                (AnalysisValueRanges["Slow_Pool"].max -
                  AnalysisValueRanges["Slow_Pool"].min)) *
              100
            }
            oldValue={
              !comparedSampleRecord?.chemicals?.["Slow_Pool"]
                ? -1
                : ((AnalysisValueRanges["Slow_Pool"].min -
                    comparedSampleRecord?.chemicals?.["Slow_Pool"]) /
                    (AnalysisValueRanges["Slow_Pool"].min -
                      AnalysisValueRanges["Slow_Pool"].max)) *
                  100
            }
          />
        </Box>
      ) : null}
      {sampleRecord.chemicals?.["C:B1"] ? (
        <Box className="slider-container">
          <div className="slider-label">C:B1</div>
          <AnalysisSlider
            title=""
            tickLabels={[
              `${AnalysisValueRanges["CB1"].min}`,
              "",
              "",
              "",
              "",
              "",
              `${AnalysisValueRanges["CB1"].max}`,
            ]}
            sliderWidth={280}
            rightSideLabel={`${sampleRecord.chemicals?.["C:B1"]?.toFixed(2)}`}
            compareLabel={
              comparedSampleRecord?.chemicals?.["C:B1"] &&
              sampleRecord?.chemicals?.["C:B1"]
                ? Number(
                    (
                      sampleRecord?.chemicals?.["C:B1"] -
                      comparedSampleRecord?.chemicals?.["C:B1"]
                    ).toFixed(2)
                  ).toString()
                : ""
            }
            compareLabelColor={
              (sampleRecord.chemicals?.["C:B1"] || 0) >
              (comparedSampleRecord?.chemicals?.["C:B1"] || 0)
                ? "success.main"
                : "error.main"
            }
            sections={AnalysisValueColorRanges["CB1"]}
            value={
              ((sampleRecord.chemicals?.["C:B1"] -
                AnalysisValueRanges["CB1"].min) /
                (AnalysisValueRanges["CB1"].max -
                  AnalysisValueRanges["CB1"].min)) *
              100
            }
            oldValue={
              !comparedSampleRecord?.chemicals?.["C:B1"]
                ? -1
                : ((AnalysisValueRanges["CB1"].min -
                    comparedSampleRecord?.chemicals?.["C:B1"]) /
                    (AnalysisValueRanges["CB1"].min -
                      AnalysisValueRanges["CB1"].max)) *
                  100
            }
          />
        </Box>
      ) : null}
      {sampleRecord.chemicals?.["Fast_Pool_%"] &&
      sampleRecord.chemicals?.["Fast_Pool_%"] > 0 &&
      sampleRecord.chemicals?.["Fast_Pool_%"] < 19 ? (
        <SliderInfo
          className="slider-tip"
          sx={{ color: "warning.dark", mt: "-8px", mb: 0, ml: 2 }}
        >
          <InfoIcon />
          Consider adding a rapid starch source
        </SliderInfo>
      ) : sampleRecord.chemicals?.["Fast_Pool_%"] &&
        sampleRecord.chemicals?.["Fast_Pool_%"] > 0 &&
        sampleRecord.chemicals?.["Fast_Pool_%"] < 24 ? (
        <SliderInfo
          className="slider-tip"
          sx={{ color: "warning.dark", mt: "-8px", mb: 0, ml: 2 }}
        >
          <InfoIcon />
          Ensure fibre degradation is adequate
        </SliderInfo>
      ) : null}
      {FastHrMaxRateHours ? (
        <Box className="slider-container">
          <div className="slider-label">Fast Pool</div>
          <AnalysisSlider
            title=""
            tickLabels={[
              `${AnalysisValueRanges["FastHrMaxRateHours"].min}`,
              "",
              "",
              "",
              "",
              "",
              `${AnalysisValueRanges["FastHrMaxRateHours"].max}`,
            ]}
            sliderWidth={280}
            rightSideLabel={`${FastHrMaxRateHours?.toFixed(2)} Hr`}
            compareLabel={
              comparedSampleRecord &&
              getFastHrMaxRateHours(comparedSampleRecord) &&
              getFastHrMaxRateHours(sampleRecord)
                ? Number(
                    (
                      getFastHrMaxRateHours(sampleRecord) -
                      getFastHrMaxRateHours(comparedSampleRecord)
                    ).toFixed(2)
                  ).toString()
                : ""
            }
            compareLabelColor={
              comparedSampleRecord &&
              (getFastHrMaxRateHours(sampleRecord) || 0) >
                (getFastHrMaxRateHours(comparedSampleRecord) || 0)
                ? "success.main"
                : "error.main"
            }
            sections={AnalysisValueColorRanges["FastHrMaxRateHours"]}
            value={
              ((FastHrMaxRateHours -
                AnalysisValueRanges["FastHrMaxRateHours"].min) /
                (AnalysisValueRanges["FastHrMaxRateHours"].max -
                  AnalysisValueRanges["FastHrMaxRateHours"].min)) *
              100
            }
            oldValue={
              !comparedSampleRecord ||
              !getFastHrMaxRateHours(comparedSampleRecord)
                ? -1
                : ((AnalysisValueRanges["FastHrMaxRateHours"].min -
                    getFastHrMaxRateHours(comparedSampleRecord)) /
                    (AnalysisValueRanges["FastHrMaxRateHours"].min -
                      AnalysisValueRanges["FastHrMaxRateHours"].max)) *
                  100
            }
          />
        </Box>
      ) : null}
      {SlowHrMaxRateHours ? (
        <Box className="slider-container">
          <div className="slider-label">Slow Pool</div>
          <AnalysisSlider
            title=""
            tickLabels={[
              `${AnalysisValueRanges["SlowHrMaxRateHours"].min}`,
              "",
              "",
              "",
              "",
              "",
              `${AnalysisValueRanges["SlowHrMaxRateHours"].max}`,
            ]}
            sliderWidth={280}
            rightSideLabel={`${SlowHrMaxRateHours?.toFixed(2)} Hr`}
            compareLabel={
              comparedSampleRecord &&
              getSlowHrMaxRateHours(comparedSampleRecord) &&
              getSlowHrMaxRateHours(sampleRecord)
                ? Number(
                    (
                      getSlowHrMaxRateHours(sampleRecord) -
                      getSlowHrMaxRateHours(comparedSampleRecord)
                    ).toFixed(2)
                  ).toString()
                : ""
            }
            compareLabelColor={
              comparedSampleRecord &&
              (getSlowHrMaxRateHours(sampleRecord) || 0) >
                (getSlowHrMaxRateHours(comparedSampleRecord) || 0)
                ? "success.main"
                : "error.main"
            }
            sections={AnalysisValueColorRanges["SlowHrMaxRateHours"]}
            value={
              ((SlowHrMaxRateHours -
                AnalysisValueRanges["SlowHrMaxRateHours"].min) /
                (AnalysisValueRanges["SlowHrMaxRateHours"].max -
                  AnalysisValueRanges["SlowHrMaxRateHours"].min)) *
              100
            }
            oldValue={
              !comparedSampleRecord ||
              !getSlowHrMaxRateHours(comparedSampleRecord)
                ? -1
                : ((AnalysisValueRanges["SlowHrMaxRateHours"].min -
                    getSlowHrMaxRateHours(comparedSampleRecord)) /
                    (AnalysisValueRanges["SlowHrMaxRateHours"].min -
                      AnalysisValueRanges["SlowHrMaxRateHours"].max)) *
                  100
            }
          />
        </Box>
      ) : null}
      {FastHrMaxRateHours && SlowHrMaxRateHours ? (
        <Box className="slider-container">
          <div className="slider-label">Pool Ratio</div>
          <AnalysisSlider
            title=""
            tickLabels={[
              `${AnalysisValueRanges["SlowFastDiff"].min}`,
              "",
              "",
              "",
              "10",
              "",
              `${AnalysisValueRanges["SlowFastDiff"].max}`,
            ]}
            sliderWidth={280}
            rightSideLabel={`${(
              SlowHrMaxRateHours - FastHrMaxRateHours
            )?.toFixed(2)} Hr`}
            compareLabel={
              comparedSampleRecord &&
              getSlowHrMaxRateHours(comparedSampleRecord) &&
              getSlowHrMaxRateHours(sampleRecord)
                ? Number(
                    (
                      getSlowHrMaxRateHours(sampleRecord) -
                      getFastHrMaxRateHours(sampleRecord) -
                      (getSlowHrMaxRateHours(comparedSampleRecord) -
                        getFastHrMaxRateHours(comparedSampleRecord))
                    ).toFixed(2)
                  ).toString()
                : ""
            }
            compareLabelColor={
              comparedSampleRecord &&
              (getSlowHrMaxRateHours(sampleRecord) -
                getFastHrMaxRateHours(sampleRecord) || 0) >
                (getSlowHrMaxRateHours(comparedSampleRecord) -
                  getFastHrMaxRateHours(comparedSampleRecord) || 0)
                ? "success.main"
                : "error.main"
            }
            sections={AnalysisValueColorRanges["SlowFastDiff"]}
            value={
              ((SlowHrMaxRateHours -
                FastHrMaxRateHours -
                AnalysisValueRanges["SlowFastDiff"].min) /
                (AnalysisValueRanges["SlowFastDiff"].max -
                  AnalysisValueRanges["SlowFastDiff"].min)) *
              100
            }
            oldValue={
              !comparedSampleRecord ||
              !getSlowHrMaxRateHours(comparedSampleRecord) ||
              !getFastHrMaxRateHours(comparedSampleRecord)
                ? -1
                : ((getSlowHrMaxRateHours(comparedSampleRecord) -
                    getFastHrMaxRateHours(comparedSampleRecord) -
                    AnalysisValueRanges["SlowFastDiff"].min) /
                    (AnalysisValueRanges["SlowFastDiff"].max -
                      AnalysisValueRanges["SlowFastDiff"].min)) *
                  100
            }
          />
        </Box>
      ) : null}
      {FastHrMaxRateHours &&
      SlowHrMaxRateHours &&
      SlowHrMaxRateHours - FastHrMaxRateHours > 24 ? (
        <SliderInfo className="slider-tip" sx={{ color: "warning.dark" }}>
          <InfoIcon />
          Increased chance of acidosis
        </SliderInfo>
      ) : null}
      {sampleRecord.chemicals?.["APF"] ? (
        <div>
          <Box className="slider-container">
            <div className="slider-label">aPF</div>
            <AnalysisSlider
              title=""
              tickLabels={[
                `${AnalysisValueRanges["APF"].min}`,
                "3",
                "4",
                `${AnalysisValueRanges["APF"].max}`,
              ]}
              sliderWidth={280}
              rightSideLabel={`${sampleRecord.chemicals?.["APF"]?.toFixed(2)}`}
              compareLabel={
                comparedSampleRecord?.chemicals?.["APF"] &&
                sampleRecord?.chemicals?.["APF"]
                  ? Number(
                      (
                        sampleRecord?.chemicals?.["APF"] -
                        comparedSampleRecord?.chemicals?.["APF"]
                      ).toFixed(2)
                    ).toString()
                  : ""
              }
              compareLabelColor={
                (sampleRecord.chemicals?.["APF"] || 0) >
                (comparedSampleRecord?.chemicals?.["APF"] || 0)
                  ? "success.main"
                  : "error.main"
              }
              sections={AnalysisValueColorRanges["APF"]}
              value={
                ((sampleRecord.chemicals?.["APF"] -
                  AnalysisValueRanges["APF"].min) /
                  (AnalysisValueRanges["APF"].max -
                    AnalysisValueRanges["APF"].min)) *
                100
              }
              oldValue={
                !comparedSampleRecord?.chemicals?.["APF"]
                  ? -1
                  : ((AnalysisValueRanges["APF"].min -
                      comparedSampleRecord?.chemicals?.["APF"]) /
                      (AnalysisValueRanges["APF"].min -
                        AnalysisValueRanges["APF"].max)) *
                    100
              }
            />
          </Box>
          {sampleRecord.chemicals?.["APF"] < 3 ? (
            <SliderInfo className="slider-tip" sx={{ color: "warning.dark" }}>
              <InfoIcon />
              Non-Gluconeogenic
            </SliderInfo>
          ) : null}
          {sampleRecord.chemicals?.["APF"] > 4 ? (
            <SliderInfo className="slider-tip" sx={{ color: "warning.dark" }}>
              <InfoIcon />
              Gluconeogenic
            </SliderInfo>
          ) : null}
        </div>
      ) : null}
    </Box>
  );
}
