import * as React from "react";
import { Box, InputAdornment, Stack } from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import PersonIcon from "@mui/icons-material/Person";
import TextField from "@mui/material/TextField";
import { useNavigate } from "react-router-dom";
import AuthContainer from "./AuthContainer";
import authService from "../../../service/auth.service";
import ProgressButton from "../../components/Common/ProgressButton";

export default function Signup() {
  const navigate = useNavigate();
  const [fullName, setFullName] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [showValidation, setShowValidation] = React.useState(false);
  const [successDone, setSuccessDone] = React.useState(false);
  const [processing, setProcessing] = React.useState(false);

  const validateForm = () => {
    return !!(email && fullName);
  };

  const handleCreateAccount = async () => {
    if (validateForm()) {
      setProcessing(true);
      const result = await authService.signUp(fullName, email);
      if (result) {
        setSuccessDone(true);
      }
      setProcessing(false);
    } else {
      setShowValidation(true);
    }
  };

  return (
    <AuthContainer>
      <Stack spacing={2}>
        <TextField
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          error={showValidation && !email}
          type="email"
          placeholder="Email"
          fullWidth
          size="small"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <MailOutlineIcon />
              </InputAdornment>
            ),
          }}
        />
        <TextField
          value={fullName}
          onChange={(e) => setFullName(e.target.value)}
          error={showValidation && !fullName}
          placeholder="Full Name"
          fullWidth
          size="small"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <PersonIcon />
              </InputAdornment>
            ),
          }}
        />
        {successDone ? (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              flexWrap: "wrap",
              padding: "15px 10px",
              background: "#57d15736",
              borderRadius: "6px",
            }}
          >
            <CheckCircleIcon color="success" />
            <Box ml={1} sx={{ display: "flex", alignItems: "center", color: "success.main" }}>
              Please check your email inbox to
              <Box
                onClick={() => {
                  navigate("/");
                }}
                sx={{ textDecoration: "underline", ml: 1, cursor: "pointer", color: "success.main" }}
              >
                Log in
              </Box>
            </Box>
          </Box>
        ) : (
          <>
            <ProgressButton
              inProgress={processing}
              title="Create Account"
              onClick={() => !processing && handleCreateAccount()}
              disableElevation
            />
            <Box sx={{ fontSize: "15px", display: "flex", alignItems: "center", flexWrap: "wrap" }}>
              Already have account?
              <Box
                onClick={() => {
                  navigate("/");
                }}
                sx={{
                  color: "primary.main",
                  ml: 1,
                  fontSize: "15px",
                  textDecoration: "underline",
                  cursor: "pointer",
                  "&:hover": { fontWeight: "500" },
                }}
              >
                Log in
              </Box>
            </Box>
          </>
        )}
      </Stack>
    </AuthContainer>
  );
}
