import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Box,
  Grid,
} from "@mui/material";
import PrintIcon from "@mui/icons-material/Print";
import { ISampleRecordPopulated } from "../../../types/sample";

interface SampleBagCoverPrintDialogProps {
  samples: ISampleRecordPopulated[];
  open: boolean;
  onClose: () => void;
}

const SampleBagCoverPrintDialog: React.FC<SampleBagCoverPrintDialogProps> = ({
  samples,
  open,
  onClose,
}) => {
  const [printing, setPrinting] = React.useState(false);

  React.useEffect(() => {
    const handleBeforePrint = () => {
      console.log("Print dialog opened");
    };

    const handleAfterPrint = () => {
      console.log("Print dialog closed");
      setPrinting(false);
    };

    // Add event listeners
    window.addEventListener("beforeprint", handleBeforePrint);
    window.addEventListener("afterprint", handleAfterPrint);

    // Cleanup the event listeners on component unmount
    return () => {
      window.removeEventListener("beforeprint", handleBeforePrint);
      window.removeEventListener("afterprint", handleAfterPrint);
    };
  }, []);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="sm"
      fullScreen
      scroll="paper"
    >
      <DialogTitle></DialogTitle>
      <DialogContent>
        <Box>
          <Grid container>
            {samples.map((sample, i) => (
              <Grid key={i} xs={12} sm={6}>
                <Box p={2}>
                  <TableContainer
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      "& .MuiTableCell-root": {
                        padding: "8px 8px",
                        border: "solid 2px #ddd",
                        p: 1,
                      },
                    }}
                  >
                    <Table
                      sx={{ width: "400px" }}
                      aria-label="sample details table"
                    >
                      <TableBody>
                        <TableRow>
                          <TableCell component="th" scope="row">
                            <strong>DataLake SSID</strong>
                          </TableCell>
                          <TableCell>{sample.uid || "-"}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell component="th" scope="row">
                            <strong>Desc</strong>
                          </TableCell>
                          <TableCell>{sample.companyUid || "-"}</TableCell>
                        </TableRow>

                        <TableRow>
                          <TableCell component="th" scope="row">
                            <strong>Client</strong>
                          </TableCell>
                          <TableCell>{sample.company?.name || "-"}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell component="th" scope="row">
                            <strong>Farm</strong>
                          </TableCell>
                          <TableCell>{sample.sampleSource || "-"}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell component="th" scope="row">
                            <strong>Description</strong>
                          </TableCell>
                          <TableCell>{sample.description || "-"}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell component="th" scope="row">
                            <strong>Country</strong>
                          </TableCell>
                          <TableCell>{sample.country || "-"}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell component="th" scope="row">
                            <strong>Region</strong>
                          </TableCell>
                          <TableCell>{sample.region || "-"}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell component="th" scope="row">
                            <strong>Year</strong>
                          </TableCell>
                          <TableCell>{sample.year || "-"}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell component="th" scope="row">
                            <strong>Type</strong>
                          </TableCell>
                          <TableCell>{sample.type || "-"}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell component="th" scope="row">
                            <strong>Sample Taken Date</strong>
                          </TableCell>
                          <TableCell>{sample.sampleTakenDate || "-"}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell component="th" scope="row">
                            <strong>Product</strong>
                          </TableCell>
                          <TableCell>{sample.product || "-"}</TableCell>
                        </TableRow>
                        <TableRow
                          sx={{
                            "& .MuiTableCell-root": {
                              // borderBottom: "none !important",
                            },
                          }}
                        >
                          <TableCell component="th" scope="row">
                            <strong>Application</strong>
                          </TableCell>
                          <TableCell>{sample.application || "-"}</TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Box>
              </Grid>
            ))}
          </Grid>
        </Box>
      </DialogContent>
      {printing ? null : (
        <DialogActions sx={{ p: 2, justifyContent: "center" }}>
          <Button onClick={onClose} color="primary">
            Cancel
          </Button>
          <Button
            onClick={() => {
              setPrinting(true);
              setTimeout(() => {
                window.print();
              }, 500);
            }}
            startIcon={<PrintIcon />}
            color="primary"
            variant="contained"
          >
            Print
          </Button>
        </DialogActions>
      )}
    </Dialog>
  );
};

export default SampleBagCoverPrintDialog;
