import axios from "axios";
import { encryptStorageUtils } from "./encryptStorage";

const createAxiosInstance = () => {
  const jwtToken = encryptStorageUtils.getAuthTokenFromStorage() || "";

  return axios.create({
    baseURL: window.env.BACKEND_API_URL,
    headers: {
      Authorization: `Bearer ${jwtToken as string}`,
    },
  });
};

export default createAxiosInstance;
