/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as React from "react";
import { Box, InputAdornment, Stack } from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import PersonIcon from "@mui/icons-material/Person";
import TextField from "@mui/material/TextField";
import VpnKeyIcon from "@mui/icons-material/VpnKey";
import { useNavigate } from "react-router-dom";
import AuthContainer from "./AuthContainer";
import authService from "../../../service/auth.service";
import ProgressButton from "../../components/Common/ProgressButton";

export default function ForgotPassword() {
  const navigate = useNavigate();
  const [email, setEmail] = React.useState("");
  const [submitting, setSubmitting] = React.useState(false);
  const [showInputValidation, setShowInputValidation] = React.useState<any>({});
  const [identityConfirmed, setIdentityConfirmed] = React.useState(false);
  const [newVerificationCode, setNewVerificationCode] = React.useState("");

  const [verificationCode, setVerificationCode] = React.useState("");

  const [newPassword, setNewPassword] = React.useState("");
  const [confirmPassword, setConfirmPassword] = React.useState("");

  const submitEmail = async () => {
    if (!email) return;
    setSubmitting(true);
    const result = await authService.verifyEmail(email);
    if (result?.data?.result == true) {
      setIdentityConfirmed(true);
    } else if (result?.data?.result == false) {
      setIdentityConfirmed(false);
    }
    setSubmitting(false);
  };

  const submitCode = async () => {
    if (!verificationCode) return;
    setSubmitting(true);
    const result = await authService.submitVerifyCode(email, verificationCode);
    if (result?.data?.result?.length || result?.data?.result > 0) {
      setNewVerificationCode(result.data.result);
    }
    setSubmitting(false);
  };

  const updatePassword = async () => {
    if (!newPassword || !confirmPassword || newPassword != confirmPassword) return;
    setSubmitting(true);
    try {
      const result = await authService.updateForgotPassword(email, newVerificationCode, newPassword);
      if (result?.data?.result == "success") {
        setShowInputValidation({});
      }
      setSubmitting(false);
      setTimeout(() => {
        navigate("/login");
      }, 1000);
    } catch (error) {
      setSubmitting(false);
    }
  };

  return (
    <AuthContainer>
      <Box sx={{ textAlign: "center", fontSize: "22px", mb: 5 }}>Forgot Password?</Box>
      <Stack spacing={2}>
        {identityConfirmed != true ? (
          <Box>
            <TextField
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              error={showInputValidation && !email}
              type="email"
              placeholder="Email"
              fullWidth
              size="small"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <MailOutlineIcon />
                  </InputAdornment>
                ),
              }}
            />
            <Box mt={2}>
              <ProgressButton
                onClick={() => submitEmail()}
                fullWidth
                disabled={submitting}
                inProgress={submitting}
                title="Submit"
              />
            </Box>
          </Box>
        ) : !newVerificationCode ? (
          <Box>
            <Box>
              <TextField
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                error={showInputValidation && !email}
                type="email"
                placeholder="Email"
                fullWidth
                size="small"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <MailOutlineIcon />
                    </InputAdornment>
                  ),
                  readOnly: true,
                }}
              />
            </Box>
            <Box mt={4} mb={2} sx={{ color: "success.main", display: "block", alignItems: "center", flexWrap: "wrap" }}>
              We sent verification code to your email. Please check your inbox.
            </Box>
            <TextField
              value={verificationCode}
              placeholder="Enter Verification Code"
              onChange={(e) => setVerificationCode(e.target.value)}
              size="small"
              label=""
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <VpnKeyIcon />
                  </InputAdornment>
                ),
              }}
            />
            <Box mt={3}>
              <ProgressButton
                onClick={() => submitCode()}
                fullWidth
                disabled={submitting}
                inProgress={submitting}
                title="Submit Code"
              />
            </Box>
          </Box>
        ) : (
          <Box>
            <TextField
              value={newPassword}
              type="password"
              placeholder="Enter New Password"
              onChange={(e) => setNewPassword(e.target.value)}
              size="small"
              label=""
              fullWidth
              error={
                (!newPassword ||
                  (confirmPassword && showInputValidation.confirmPassword && newPassword != confirmPassword)) &&
                showInputValidation.newPassword
              }
              helperText={
                showInputValidation.new
                  ? !newPassword
                    ? "Required field"
                    : confirmPassword && showInputValidation.confirmPassword && newPassword != confirmPassword
                    ? "Confirm password doesn't match"
                    : ""
                  : ""
              }
              onBlur={() => setShowInputValidation({ ...showInputValidation, newPassword: true })}
            />

            <TextField
              value={confirmPassword}
              type="password"
              placeholder="Confirm Password"
              onChange={(e) => setConfirmPassword(e.target.value)}
              size="small"
              label=""
              fullWidth
              sx={{ mt: 3 }}
              error={(!confirmPassword || newPassword != confirmPassword) && showInputValidation.confirmPassword}
              helperText={!confirmPassword && showInputValidation.confirmPassword ? "Required field" : ""}
              onBlur={() => setShowInputValidation({ ...showInputValidation, confirmPassword: true })}
            />

            <Box mt={3}>
              <ProgressButton
                onClick={() => updatePassword()}
                fullWidth
                disabled={submitting}
                inProgress={submitting}
                title="Update Password"
              />
            </Box>
          </Box>
        )}
      </Stack>
    </AuthContainer>
  );
}
